import { FunctionComponent, useState } from "react";

import { CircularProgress } from "@mui/material";

import { CustomColumnFrequency, CustomColumnTypes } from "@/enum/list.enum";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { useUpdateColumnValueMutation } from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { TrackerOrganization } from "../../schema/trackerOrganizations";
import { ColumnConfig } from "../../../List/schema/columnConfig";
import { ColumnValue } from "../../../List/schema/columnValue";

export interface CustomBooleanColumnProps {
	columnValue?: ColumnValue;
	columnConfig: ColumnConfig;
	row: TrackerOrganization;
	type: CustomColumnTypes;
}

export const CustomBooleanColumn: FunctionComponent<CustomBooleanColumnProps> = ({
	columnValue,
	columnConfig,
	row,
	type,
}) => {
	const [updatePerson, { isLoading }] = useUpdateColumnValueMutation();
	const [isChecked, setIsChecked] = useState(
		columnValue?.value ? (columnValue.value as boolean) : false
	);

	return isLoading ? (
		<CircularProgress />
	) : (
		<Checkbox
			isChecked={columnValue?.value ? (columnValue.value as boolean) : isChecked || false}
			name={columnConfig.name}
			onChange={(e) => {
				e.preventDefault();
				e.stopPropagation();

				setIsChecked(!isChecked);
				updatePerson({
					id: row.id,
					columnId: columnConfig.id,
					customColumnName: columnConfig.name,
					value: !(columnValue?.value ? (columnValue.value as boolean) : false),
					customColumnType: type,
					frequency: CustomColumnFrequency.ONCE,
				});
			}}
		/>
	);
};
