import { z } from "zod";

export const TableConfigSchema = z.object({
	id: z.number(),
	listId: z.number().optional(),
	trackerId: z.number().optional(),
	config: z.any(),
});

export type TableConfig = z.infer<typeof TableConfigSchema>;
