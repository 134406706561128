import { faCalendarCircleUser, faFile, faHandHoldingBox } from "@fortawesome/pro-regular-svg-icons";

import { t } from "i18next";

import { FunctionComponent } from "react";

import GoogleMapsIcon from "@assets/icons/google-maps.svg";

import LinkedinIcon from "@assets/icons/linkedin.svg";

import { Icon } from "@/components";
import { ListSourceType } from "@/enum/list.enum";
import { SvgIcon } from "@/components/Icon/SvgIcon";
import { ReactComponent as UserCircle } from "@assets/icons/inbox/user-circle.svg";

export interface ListSourceTypeProps {
	sourceType: ListSourceType;
}
export const ListSourceTypeElem: FunctionComponent<ListSourceTypeProps> = ({ sourceType }) => {
	return (
		<>
			{sourceType === ListSourceType.PROSPECT_SEARCH ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<SvgIcon className="w-[18px] h-[18px]" svgIcon={UserCircle} />
					<span className="text-sm">Person</span>
				</div>
			) : (
				<></>
			)}
			{sourceType === ListSourceType.LI_EVENTS ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={LinkedinIcon} />
					<span className="text-sm">Events</span>
				</div>
			) : (
				<></>
			)}
			{sourceType === ListSourceType.LI_PEOPLE ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={LinkedinIcon} />
					<span>People</span>
				</div>
			) : (
				<></>
			)}
			{sourceType === ListSourceType.MAPS ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={GoogleMapsIcon} />
					<span>Google Maps</span>
				</div>
			) : (
				<></>
			)}
			{sourceType === ListSourceType.LI_COMPANY_ACTIVITY ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={LinkedinIcon} />
					<span>Company Activity</span>
				</div>
			) : (
				<></>
			)}
			{sourceType === ListSourceType.LI_PROFILE_VISITS ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={LinkedinIcon} />
					<span>Profile Visits</span>
				</div>
			) : (
				<></>
			)}
			{sourceType === ListSourceType.LI_PROFILE ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={LinkedinIcon} />
					<span>Profile Tracker</span>
				</div>
			) : (
				<></>
			)}
			{sourceType === ListSourceType.LI_POST ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={LinkedinIcon} />
					<span>Engagement</span>
				</div>
			) : (
				<></>
			)}
			{sourceType === ListSourceType.CUSTOM_EVENTS ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<Icon className="w-[18px] h-[18px]  mr-1" icon={faCalendarCircleUser} />
					<span>Custom Event</span>
				</div>
			) : (
				<></>
			)}
			{sourceType === ListSourceType.CSV_ENRICHMENTS ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<Icon className="w-[18px] h-[18px]  mr-1" icon={faFile} />
					<span>CSV File</span>
				</div>
			) : (
				<></>
			)}
			{sourceType === ListSourceType.JOBS ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<Icon className="w-[18px] h-[18px]  mr-1" icon={faHandHoldingBox} />
					<span>{t(`sidebar.jobs`)}</span>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
