import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Link, useNavigate, useParams } from "react-router-dom";

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";

import { faList } from "@fortawesome/pro-regular-svg-icons";

import { Breadcrumb } from "@/components/Breadcrump/Breadcrump";

import { Button } from "@/components/Button/Button";

import { Icon, LoadingOverlay } from "@/components";

import { ButtonColor } from "@/components/Button/types";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { InputField } from "@/components/InputField/InputField";

import {
	useGetTrackerQuery,
	useUpdateTrackerMutation,
} from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { getAvailableSteps, getDoneSteps } from "../../utils/create-tracker-steps";
import { Stepper } from "../TrackerEdit/components/Stepper";
import { Tracker, UpdateTrackerSchema } from "../../schema/profileTracker";

export const TrackerSettings: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();
	const navigate = useNavigate();

	const ts = (key: string) => t(`profileTracker.${key}`);

	const { data, isFetching } = useGetTrackerQuery(+id);

	const [currentFormState, setCurrentFormState] = useState<Partial<Tracker>>();

	const handleSaveFormState = (value: Tracker[keyof Tracker], name: keyof Tracker) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const [update, { isLoading }] = useUpdateTrackerMutation();

	const {
		handleSubmit,
		setValue,
		formState: { errors, isDirty },
		reset,
	} = useForm<Partial<Tracker>>({
		defaultValues: {
			id: +id,
			name: data?.name,
		},
		resolver: zodResolver(UpdateTrackerSchema),
	});

	console.log(errors);

	useEffect(() => {
		if (data) {
			handleSaveFormState(data.id, "id");
			handleSaveFormState(data.name, "name");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const onSubmit = async (values: Partial<Tracker>) => {
		try {
			if (data) {
				await update({ ...values, id: +id }).unwrap();
				reset(values);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const onSubmitAndNavigate = async (values: Partial<Tracker>) => {
		try {
			if (data) {
				await update({ ...values, id: +id }).unwrap();
				navigate("/app/profile-tracker");
			}
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return isFetching || !data || isFetching ? (
		<LoadingOverlay />
	) : (
		<div className="sm:block flex flex-col items-start justify-between mb-32 pb-32">
			<div className="flex flex-row justify-between w-full items-center">
				<Breadcrumb
					path={[
						{ appFunction: "profile-tracker", name: ts("title"), link: "/" },
						{ appFunction: "profile-tracker", name: ts("create.title"), link: "/create" },
						{
							appFunction: "profile-tracker",
							name: data?.name || "",
							link: `/create/${id}/details`,
						},
					]}
				/>

				<Link className="text-sm flex items-center h-full" to={`/app/profile-tracker/detail/${id}`}>
					<span className="whitespace-nowrap">Go to list</span>{" "}
					<Icon className="w-[18px] ml-2 h-[18px]" color="#374151" icon={faList} />
				</Link>
			</div>

			<div className="text-lg leading-9 mb-5">Create {ts(data.sourceType)} List</div>
			<Stepper
				availableSteps={getAvailableSteps(data)}
				currentStep="settings"
				doneSteps={getDoneSteps(data)}
				isDirty={isDirty}
				listId={data.id}
			/>
			<div className="w-full">
				<div className="max-w-[612px]">
					<div className="text-md mb-5">Settings</div>
					<div className="flex flex-row gap-4 w-full mb-1">
						<InputField
							error={errors.name?.message}
							handleChange={(event: ChangeEvent<HTMLInputElement>) => {
								handleSaveFormState(event.target.value, "name");
							}}
							label={ts("name")}
							name="name"
							placeholder={ts("name")}
							showError={!!errors.name?.message}
							value={currentFormState?.name || ""}
						/>
					</div>
				</div>

				<div className="flex flex-col gap-4 w-full mb-4">
					{getAllErrors(undefined, formErrors).length
						? renderErrorMessages(getAllErrors(undefined, formErrors))
						: null}
				</div>
			</div>

			<div className="absolute mt-14 h-[72px] flex flex-row justify-between items-center w-auto border-t border-t-border px-8 py-[14px] rounded-t-16 bottom-0 right-0 z-20 bg-white w-full">
				<div></div>
				<div className="flex flex-row self-left items-center gap-x-4">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						title={t("basics.previous")}
						onClick={() => navigate(`/app/lists/create/score/${id}`)}
					/>
					<Button
						color={ButtonColor.BRAND}
						disabled={!isDirty}
						title={t("basics.save")}
						onClick={handleSubmit(onSubmit)}
					/>
					<Button
						isLoading={isLoading}
						title={t("basics.next")}
						onClick={handleSubmit(onSubmitAndNavigate)}
					/>
				</div>
			</div>
		</div>
	);
};
