/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from "react";

import { SvgIcon } from "@/components/Icon/SvgIcon";
import { TrackerSourceType } from "@/enum/tracker.enum";
import { ReactComponent as CsvEnrichment } from "@assets/icons/create-list/csvEnrichment.svg";
import { ReactComponent as Empty } from "@assets/icons/create-list/empty.svg";
import { ReactComponent as ListIcon } from "@assets/icons/sidebar/screw-driver-wrench.svg";

export const createNewListSettings: {
	title: string;
	items: {
		listType: TrackerSourceType;
		title: string;
		description: string;
		note?: string;
		icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
		iconElement?: ReactElement;
		disabled?: boolean;
	}[];
}[] = [
	{
		title: "General",
		items: [
			{
				listType: TrackerSourceType.LIST,
				title: "Select list(s)",
				description: "Search for titles and company characteristics.",
				note: "You need to have an Apollo API key to use this feature.",
				iconElement: (
					<div className="w-[40px] h-[40px] rounded-[10px] flex items-center justify-center bg-inactive-item  mr-2">
						<SvgIcon className="w-[24px] h-[24px]" svgIcon={ListIcon} />
					</div>
				),
				disabled: true,
			},
			{
				listType: TrackerSourceType.XLSX,
				title: "XLSX Upload",
				description: "Track companies from the uploaded xlsx file.",
				icon: CsvEnrichment,
			},
			{
				listType: TrackerSourceType.EMPTY,
				title: "Empty Tracker",
				description: "Add companies or people manually",
				iconElement: (
					<div className="w-[40px] h-[40px] rounded-[10px] flex items-center justify-center bg-inactive-item  mr-2">
						<SvgIcon className="w-[24px] h-[24px]" svgIcon={Empty} />
					</div>
				),
			},
		],
	},
];
