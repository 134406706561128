import { api } from "@/redux/api";

import { TableConfig, TableConfigSchema } from "../../pages/List/schema/tableConfig";

export const tableConfigApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getTableConfig: builder.query<
			TableConfig,
			{
				id: number;
				source: string;
			}
		>({
			query: ({ id, source }) => ({
				url: `table-config/config/${id}/${source}`,
				responseSchema: TableConfigSchema,
			}),
			providesTags: ["TableConfig"],
		}),
		createTableConfig: builder.mutation<Partial<TableConfig>, Partial<TableConfig>>({
			query: (body) => ({
				url: `table-config/config`,
				method: "POST",
				body,
				responseSchema: TableConfigSchema,
			}),
			invalidatesTags: ["TableConfig"],
		}),
		updateTableConfig: builder.mutation<Partial<TableConfig>, Partial<TableConfig>>({
			query: ({ id, ...body }) => ({
				url: `table-config/config/${id}`,
				method: "PATCH",
				body,
				responseSchema: TableConfigSchema,
			}),
			invalidatesTags: ["TableConfig"],
		}),
	}),
});

export const {
	useGetTableConfigQuery,
	useCreateTableConfigMutation,
	useUpdateTableConfigMutation,
} = tableConfigApi;
export const accountApiReducer = tableConfigApi.reducer;
export const accountApiMiddleware = tableConfigApi.middleware;
