import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLocalStorageState from "use-local-storage-state";

import { faAdd, faColumns3, faSearch, faSliders } from "@fortawesome/pro-regular-svg-icons";

import { useParams } from "react-router-dom";

import { TextField } from "@mui/material";

import { format } from "date-fns";

import { Icon, LoadingOverlay, Modal } from "@/components";
import { useSearch } from "@/utils";

import { Table, TableColumn } from "@/components/Table/Table";
import { useBulkFunctions } from "@/utils/userBulkFunctions";
import { SortParams } from "@/types/types";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	clearRecords,
	finalizeResetColumns,
	profileTrackerSelector,
	setProfileTrackerSorting,
} from "@/pages/Private/redux/profileTracker/profileTracker.slice";

import { Button } from "@/components/Button/Button";

import { ButtonColor, ButtonSize } from "@/components/Button/types";

import { TrackerSourceType } from "@/enum/tracker.enum";

import { useCopyToClipboard } from "@/utils/useCopyToClipboard";

import { InputField } from "@/components/InputField/InputField";

import { classNames } from "@/utils/classNames";

import { TItem } from "@/components/SortableList/SortableList";

import { useSnackBar } from "@/utils/useSnackbar";

import {
	useDeleteColumnMutation,
	useLazyGetTrackerOrganizationsQuery,
	useRemoveHoldingToOrganizationMutation,
} from "@/pages/Private/redux/profileTracker/profileTracker.api";

import {
	useCreateTableConfigMutation,
	useGetTableConfigQuery,
	useUpdateTableConfigMutation,
} from "@/pages/Private/redux/tableConfig/tableConfig.api";

import { CustomColumnTypes } from "@/enum/list.enum";

import { FilterModal, IFilterWithValues } from "./FilterModal";
import { TrackerOrganization } from "../schema/trackerOrganizations";
import { GeneralTableColumns } from "./GeneralTableColumns";
import { CreateCustomColumnModal } from "./CustomColumns/Modals/CreateCustomColumnModal";
import { CustomBooleanColumn } from "./CustomColumns/CustomBooleanColumn";
import { CustomNumberColumn } from "./CustomColumns/CustomNumberColumn";
import { CustomLinkColumn } from "./CustomColumns/CustomLinkColumn";
import { CustomTextColumn } from "./CustomColumns/CustomTextColumn";
import { getOrderStatus } from "../../List/utils/colum-utils";
import { ColumnModal } from "./CustomColumns/ColumnModal";
import { EditCustomColumnModalWrapper } from "./CustomColumns/Modals/EditCustomColumnModalWrapper";
import { DetailView } from "./CustomColumns/DetailView";
import { getSvgIcons } from "../utils/getIconsByType";
import { CustomTextColumnReadOnly } from "./CustomColumns/CustomTextColumnReadOnly";
import { SearchForCompanyModal } from "./CustomColumns/Modals/SearchForCompanyModal";
import { SearchForCompanyHoldingModal } from "./CustomColumns/Modals/SearchForCompanyHoldingModal";

interface TrackerDetailsTableProps {
	trackerType?: TrackerSourceType;
}

export interface FilterTrackerDetailsTable {
	name?: string;
	email?: string;
	linkedinUrl?: string;
	country?: string;
	city?: string;
	state?: string;
	company?: string;
	companyCountry?: string;
	companyState?: string;
	companyCity?: string;
	companyLinkedinUrl?: string;
	title?: string;
	seniority?: string;
	website?: string;
	event?: {
		name: string;
		id: number;
	}[];
}

export const TrackerDetailsTable: FunctionComponent<TrackerDetailsTableProps> = ({
	trackerType,
}) => {
	const { t } = useTranslation();
	const { id } = useParams();
	const ts = useCallback((key: string) => t(`inbox.${key}`), [t]);
	const {
		sortingRecords,
		records: data,
		recordsMeta: meta,
		resetColumns,
	} = useAppSelector(profileTrackerSelector);

	const { renderSnackbar, handleCopyClick } = useCopyToClipboard();
	const { renderMessageSnackbar } = useSnackBar();
	const { page, setPage, limit, query, setLimit, setSearchValue, searchValue } = useSearch();

	const [customColumnTextValue, setCustomColumnTextValue] = useState<string>();
	const [customColumnNumberValue, setCustomColumnNumberValue] = useState<number | null>();
	const [showSelectColumns, setShowSelectColumns] = useState(false);

	const dispatch = useAppDispatch();
	const { selectedItems, selectAll, handleSelectAll, handleSelectItem, toggleSelectAll } =
		useBulkFunctions();

	const [selectedTrackerOrganization, setSelectedTrackerOrganization] = useState<
		TrackerOrganization | undefined
	>();
	const [showNewColumn, setShowNewColumn] = useState<boolean>(false);
	const [showNewCompany, setShowNewCompany] = useState<boolean>(false);
	const [editableColumn, setEditableColumn] = useState<TItem>();
	const [showEditColumn, setShowEditColumn] = useState<boolean>(false);
	const [showDetailView, setShowDetailView] = useState<boolean>(false);
	const [showDeleteColumn, setShowDeleteColumn] = useState<boolean>(false);

	const [filter, setFilter] = useState<IFilterWithValues>();
	const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
	const [showEdit, setShowEdit] = useState<{ id: number; name: string }>();
	const [showEditHolding, setShowEditHolding] = useState<{ id: number; name: string }>();
	const [showDeleteHolding, setShowDeleteHolding] = useState<{ id: number; name: string }>();
	const [tableConfig, setTableConfig] = useLocalStorageState<TItem[]>(
		`tracker-column-settings-${id}`,
		{
			defaultValue: [],
		}
	);

	const [removeHolding, { isLoading: removeHoldingLoading }] =
		useRemoveHoldingToOrganizationMutation();
	const [getTrackerOrganization, { isLoading }] = useLazyGetTrackerOrganizationsQuery();

	const [createConfig, { isLoading: isCreatingLoading }] = useCreateTableConfigMutation();
	const [updateConfig, { isLoading: isUpdatingLoading }] = useUpdateTableConfigMutation();
	const [deleteColumn, { isLoading: deleteLoading }] = useDeleteColumnMutation();

	const handleSorting = (sortParams: SortParams) => {
		setPage(1);
		dispatch(setProfileTrackerSorting(sortParams));
	};

	const { data: tableConfiguration, isLoading: isLoadingConfig } = useGetTableConfigQuery({
		id: id ? +id : 0,
		source: "tracker",
	});

	useEffect(() => {
		if (id && !tableConfiguration && !isLoadingConfig && !isCreatingLoading) {
			const newConfig =
				tableConfig.length > 0
					? tableConfig
					: tableColumns?.map((c, index) => {
							return {
								uid: c.uid ?? "",
								id: +index + 1,
								show: typeof c.show !== "undefined" ? c.show : true,
								content: c.title,
								locked: !!c.locked || false,
								allowExclude: typeof c.allowExclude !== "undefined" ? c.allowExclude : true,
								custom: c.uid?.includes("custom-") || false,
								columnId: c.uid?.includes("custom-") ? +c.uid.split("-")[1] : undefined,
								moveable: c.moveable,
								hideable: c.hideable,
								svgIcon: c.svgIcon?.toString(),
							};
					  });

			createConfig({
				trackerId: +id,
				config: newConfig,
			});
		}

		if (tableConfiguration?.config?.length > 0) {
			setTableConfig(tableConfiguration?.config);
		} else {
			updateTableConfig(tableColumns);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableConfiguration, isLoadingConfig, isCreatingLoading, id]);

	useEffect(() => {
		dispatch(clearRecords());

		getTrackerOrganization({
			page,
			limit,
			searchValue: query,
			sorting: sortingRecords,
			trackerId: id ? +id : undefined,
			filter,
		});
	}, [dispatch, filter, getTrackerOrganization, id, limit, page, query, sortingRecords]);

	const handleChangeRowsPerPage = (newLimit: number) => {
		setLimit(newLimit);
		setPage(1);
	};

	useEffect(() => {
		if (selectAll) {
			handleSelectAll(data?.map((item) => item.id) || []);
		} else {
			handleSelectAll([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAll, data]);

	const [previousColumnCount, setPreviousColumnCount] = useState(0);

	useEffect(() => {
		if (
			(tableConfiguration?.config && !isUpdatingLoading) ||
			tableConfiguration?.config?.length === 0
		) {
			updateTableConfig(tableColumns);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableConfig]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function areArraysEqual(arr1: Record<string, any>[], arr2: Record<string, any>[]): boolean {
		if (arr1?.length !== arr2?.length) {
			return false;
		}

		return arr1.every((obj1, index) => {
			const obj2 = arr2[index];

			if (!obj2) {
				return false;
			}

			const keys = Object.keys(obj1);

			return keys.every((key) => {
				return obj1[key] === obj2[key];
			});
		});
	}

	const updateTableConfig = useCallback(
		(columns: TableColumn[]) => {
			const newConfig = columns.map((c, index) => {
				return {
					uid: c.uid ?? "",
					id: +index + 1,
					show: typeof c.show !== "undefined" ? c.show : true,
					content: c.title,
					locked: !!c.locked || false,
					allowExclude: typeof c.allowExclude !== "undefined" ? c.allowExclude : true,
					custom: c.uid?.includes("custom-") || false,
					columnId: c.uid?.includes("custom-") ? +c.uid.split("-")[1] : undefined,
					moveable: c.moveable,
					hideable: c.hideable,
					svgIcon: c.svgIcon?.toString(),
				};
			});

			if (
				(tableConfiguration?.config && !areArraysEqual(newConfig, tableConfiguration?.config)) ||
				tableConfiguration?.config?.length === 0
			) {
				updateConfig({
					id: tableConfiguration.id,
					trackerId: id ? +id : 0,
					config: newConfig,
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setTableConfig, tableConfig]
	);

	const tableColumns: TableColumn[] = useMemo(() => {
		const getColumnsBasedOnTrackerType = () => {
			const columns: TableColumn[] = GeneralTableColumns({
				selectAll,
				toggleSelectAll,
				selectedItems,
				handleSelectItem,
				handleCopyClick,
				// handleContextMenu,
				ts,
				sortingInbox: sortingRecords,
				setShowEdit: setShowEditHolding,
				showEdit: showEditHolding,
				setShowDelete: setShowDeleteHolding,
			});

			return columns;
		};

		let columns: TableColumn[] = getColumnsBasedOnTrackerType();

		if (data && data.length > 0) {
			for (const rec of data) {
				if (rec.tracker.columns) {
					for (const customColumn of rec.tracker.columns) {
						const hasColumn = columns.find((c) => c.uid === `custom-${customColumn.id}`);

						if (!hasColumn) {
							const type = customColumn.type as CustomColumnTypes;
							const title = customColumn.name;
							const width = "fit-content";
							const minWidth = "100px";
							const uid = `custom-${customColumn.id}`;
							const titleUpdated = `${customColumn.name} last update`;
							const uidUpdated = `updated-${customColumn.id}`;
							const show = true;
							const moveable = true;
							const hideable = true;
							const orderTitle = `custom-${customColumn.id}`;

							const orderStatus = getOrderStatus(sortingRecords, orderTitle);

							const currentSvgIcon = getSvgIcons(type);

							const render = (row: TrackerOrganization) => {
								const currentCustomColumn = row.tracker.columns?.find(
									(c) => c.id === customColumn.id
								);

								const columnValue = row.columns?.find(
									(c) => c.columnConfigId === currentCustomColumn?.id
								);

								return (
									<div className="flex flex-row items-start justify-start max-w-[300px]">
										<div className="flex-col items-start justify-start w-full">
											{(type === CustomColumnTypes.TEXT ||
												type === CustomColumnTypes.AI_PROMPT ||
												type === CustomColumnTypes.JOB_OFFER ||
												type === CustomColumnTypes.TECHNOLOGY ||
												type === CustomColumnTypes.RANDOM_VALUE) &&
												currentCustomColumn && (
													<CustomTextColumn
														columnConfig={currentCustomColumn}
														columnValue={columnValue}
														customColumnTextValue={customColumnTextValue}
														row={row}
														setCustomColumnTextValue={setCustomColumnTextValue}
														setShowEdit={setShowEdit}
														showEdit={showEdit}
														type={type}
													/>
												)}
											{(type === CustomColumnTypes.GOOGLE_NEWS ||
												type === CustomColumnTypes.LINKEDIN_POST) &&
												currentCustomColumn && (
													<CustomTextColumnReadOnly columnValue={columnValue} />
												)}
											{type === CustomColumnTypes.LINK && currentCustomColumn && (
												<CustomLinkColumn
													columnConfig={currentCustomColumn}
													columnValue={columnValue}
													customColumnTextValue={customColumnTextValue}
													row={row}
													setCustomColumnTextValue={setCustomColumnTextValue}
													setShowEdit={setShowEdit}
													showEdit={showEdit}
													type={type}
												/>
											)}
											{(type === CustomColumnTypes.NUMBER ||
												type === CustomColumnTypes.DEPARTMENT_SIZE) &&
												currentCustomColumn && (
													<CustomNumberColumn
														columnConfig={currentCustomColumn}
														columnValue={columnValue}
														customColumnNumberValue={customColumnNumberValue}
														row={row}
														setCustomColumnNumberValue={setCustomColumnNumberValue}
														setShowEdit={setShowEdit}
														showEdit={showEdit}
														type={type}
													/>
												)}

											{type === CustomColumnTypes.BOOLEAN && currentCustomColumn && (
												<CustomBooleanColumn
													columnConfig={currentCustomColumn}
													columnValue={columnValue}
													row={row}
													type={type}
												/>
											)}
										</div>
									</div>
								);
							};

							columns = [
								...columns,
								{
									title,
									uid,
									minWidth,
									width,
									render,
									orderTitle,
									show,
									moveable,
									hideable,
									orderStatus,
									svgIcon: currentSvgIcon,
								},
							];

							const renderUpdatedAt = (row: TrackerOrganization) => {
								const currentCustomColumn = row.tracker.columns?.find(
									(c) => c.id === customColumn.id
								);

								const columnValue = row.columns?.find(
									(c) => c.columnConfigId === currentCustomColumn?.id
								);

								return (
									<div className="flex flex-row items-start justify-start">
										<div className="flex-col items-start justify-start w-full">
											{columnValue?.createdAt
												? format(new Date(columnValue.createdAt), "HH:mm dd.MM.yyyy")
												: ""}
										</div>
									</div>
								);
							};

							columns = [
								...columns,
								{
									title: titleUpdated,
									uid: uidUpdated,
									minWidth,
									width,
									render: renderUpdatedAt,
									orderTitle,
									show,
									moveable,
									hideable,
									orderStatus,
									svgIcon: currentSvgIcon,
								},
							];
						}
					}

					columns = addLastSignalColumn(columns, sortingRecords);
				}
			}
		}

		if (tableConfig?.length) {
			for (const c of columns) {
				const configIndex = tableConfig.findIndex((tc) => tc?.uid === c.uid);
				const currentIndex = columns.findIndex((tc) => tc?.uid === c.uid);

				if (configIndex > -1 && currentIndex > -1 && configIndex !== currentIndex) {
					const newConfigColumn = columns[currentIndex];

					columns.splice(currentIndex, 1);

					columns = [
						...columns.slice(0, configIndex),
						newConfigColumn,
						...columns.slice(configIndex),
					];
				}
			}

			columns = columns.map((c) => {
				const config = tableConfig.find((tc) => {
					return tc?.uid === c?.uid;
				});

				if (config) {
					c.show = config.show;
				}

				return c;
			});
		}

		return columns;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		trackerType,
		data,
		tableConfig,
		selectAll,
		toggleSelectAll,
		selectedItems,
		handleSelectItem,
		handleCopyClick,
		ts,
		sortingRecords,
		showEdit,
		customColumnTextValue,
		customColumnNumberValue,
		resetColumns,
	]);

	useEffect(() => {
		if (data.length !== 0) {
			const currentColumnCount = data[0]?.tracker?.columns?.length || 0;

			if (currentColumnCount !== previousColumnCount || resetColumns) {
				updateTableConfig(tableColumns);
				setPreviousColumnCount(currentColumnCount);

				if (resetColumns) {
					dispatch(finalizeResetColumns());
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, previousColumnCount, updateTableConfig, tableColumns]);

	const handleDeleteColumn = async () => {
		await deleteColumn({ id: editableColumn?.columnId }).unwrap();
		setEditableColumn(undefined);
	};

	const handleRowClick = (id: number) => {
		const selectedRow = data.find((row) => row.id === id);

		setSelectedTrackerOrganization(selectedRow);

		setShowDetailView(true);
	};

	const handleRemoveHoldingFromOrganization = async (id: number) => {
		await removeHolding({
			id: id,
		}).unwrap();

		setShowDeleteHolding(undefined);
	};

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full overflow-auto">
			{/* {isFetching && <LoadingOverlay />} */}
			<div className="flex items-start align-baseline justify-between w-full mb-2">
				<div className="flex-grow mr-4 max-w-[50%] w-[390px] md-w-[240px] ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						icon={faSearch}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
				<div className="w-[fit-content] flex">
					<div className="w-[fit-content] mr-2">
						<Button
							color={ButtonColor.BRAND}
							image={<Icon className="mr-2" icon={faAdd} />}
							size={ButtonSize.L}
							title={ts("addCompany")}
							onClick={() => setShowNewCompany(true)}
						/>
					</div>
					<div className="w-[fit-content] mr-2">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							image={<Icon className="mr-2" icon={faColumns3} />}
							size={ButtonSize.L}
							title={ts("columns")}
							onClick={() => setShowSelectColumns(true)}
						/>
					</div>
					<div className="w-[fit-content] mr-2">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							image={<Icon className="mr-2" icon={faSliders} />}
							size={ButtonSize.L}
							title={`${ts("filter")}${
								filter && Object.keys(filter).length > 0 ? ` (${Object.keys(filter).length})` : ""
							}`}
							onClick={() => setShowFilterModal(true)}
						/>
					</div>

					<div className="w-[fit-content] mr-2">
						<Button
							color={ButtonColor.BRAND}
							image={<Icon className="mr-2" icon={faAdd} />}
							size={ButtonSize.L}
							title={ts("addColumn")}
							onClick={() => setShowNewColumn(true)}
						/>
					</div>
				</div>
			</div>
			<div
				className={classNames(
					"items-end align-baseline justify-start fixed bottom-12 z-50 bg-white w-full py-4",
					selectedItems.length > 0 ? "flex" : "hidden"
				)}
			></div>

			{showFilterModal && id && (
				<FilterModal
					filter={filter}
					inboxData={data}
					setFilter={(e) => {
						setPage(1);
						setFilter(e);
					}}
					setShowFilterModal={setShowFilterModal}
					showFilterModal={showFilterModal}
					trackerId={+id}
				/>
			)}

			{showDetailView && selectedTrackerOrganization && (
				<DetailView
					setShowDetailView={setShowDetailView}
					trackerOrganization={data.find((row) => row.id === selectedTrackerOrganization.id)}
				/>
			)}

			{showEditColumn && id && editableColumn?.columnId && (
				<EditCustomColumnModalWrapper
					columnId={editableColumn?.columnId}
					open={showEditColumn}
					setOpen={setShowEditColumn}
					trackerId={+id}
				/>
			)}

			{showSelectColumns && tableConfig && setShowSelectColumns && (
				<ColumnModal
					setEditableColumn={setEditableColumn}
					setShowDeleteColumn={setShowDeleteColumn}
					setShowEditColumn={setShowEditColumn}
					setShowSelectColumns={setShowSelectColumns}
					setTableConfig={setTableConfig}
					showSelectColumns={showSelectColumns}
					tableConfig={tableConfig}
				/>
			)}

			{showNewColumn && id && (
				<CreateCustomColumnModal open={showNewColumn} setOpen={setShowNewColumn} trackerId={+id} />
			)}

			{showNewCompany && id && (
				<SearchForCompanyModal open={showNewCompany} setOpen={setShowNewCompany} trackerId={+id} />
			)}

			{showEditHolding && id && (
				<SearchForCompanyHoldingModal
					open={!!showEditHolding}
					setOpen={() => setShowEditHolding(undefined)}
					trackerOrganizationId={showEditHolding.id}
				/>
			)}

			{showDeleteColumn && id && editableColumn?.columnId && (
				<Modal
					handleClose={() => setShowDeleteColumn(false)}
					handleSave={handleDeleteColumn}
					isLoading={deleteLoading}
					isOpened={showDeleteColumn}
					submitButtonColor={ButtonColor.DANGER}
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<div className="text-sm text-gray-700 mb-4">{ts("delete.confirmation")}</div>
					<TextField
						fullWidth
						defaultValue={editableColumn.content}
						inputProps={{ readOnly: true }}
						label={ts("name")}
					/>
				</Modal>
			)}

			{showDeleteHolding && id && showDeleteHolding?.id && (
				<Modal
					handleClose={() => setShowDeleteHolding(undefined)}
					handleSave={() => handleRemoveHoldingFromOrganization(showDeleteHolding.id)}
					isLoading={removeHoldingLoading}
					isOpened={!!showDeleteHolding}
					submitButtonColor={ButtonColor.DANGER}
					submitButtonText={"Remove"}
					title={"Remove holding company"}
				>
					<div className="text-sm text-gray-700 mb-4">
						Are you sure that you want to remove this holding?
					</div>
					<TextField
						fullWidth
						defaultValue={showDeleteHolding.name}
						inputProps={{ readOnly: true }}
						label={ts("name")}
					/>
				</Modal>
			)}

			<div className="relative w-full pr-0">
				<div className="pr-4 pb-[20px]">
					<Table
						columns={tableColumns}
						data={data || []}
						filter={filter}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						handleFetchPage={(page) => {
							if (selectAll) {
								toggleSelectAll();
							}

							setPage(page);
						}}
						handleRowClick={handleRowClick}
						handleSorting={(sortParams: SortParams) => {
							setPage(1);
							handleSorting(sortParams);
						}}
						hidePagination={false}
						isLoading={isLoading}
						limit={limit}
						overallCount={meta?.itemCount}
						page={page}
						totalPages={meta?.pageCount}
					/>
				</div>
			</div>
			{renderSnackbar()}
			{renderMessageSnackbar()}
		</div>
	);
};

function addLastSignalColumn(columns: TableColumn[], sortingRecords: SortParams) {
	const hasColumn = columns.find((c) => c.uid === `last-signal`);

	if (!hasColumn) {
		const title = "Last signal";
		const width = "fit-content";
		const minWidth = "100px";
		const uid = `last-signal`;
		const show = true;
		const moveable = true;
		const hideable = true;
		const orderTitle = `last-signal`;

		const orderStatus = getOrderStatus(sortingRecords, orderTitle);

		const render = (row: TrackerOrganization) => {
			let columnValue = row.columns?.[0];

			for (const col of row.columns || []) {
				if (columnValue.createdAt < col.createdAt) {
					columnValue = col;
				}
			}

			return (
				<div className="flex flex-row items-start justify-start">
					<div className="flex-col items-start justify-start w-full">
						{columnValue?.createdAt
							? format(new Date(columnValue.createdAt), "HH:mm dd.MM.yyyy")
							: ""}
					</div>
				</div>
			);
		};

		columns = [
			...columns,
			{
				title,
				uid,
				minWidth,
				width,
				render,
				orderTitle,
				show,
				moveable,
				hideable,
				orderStatus,
			},
		];
	}

	return columns;
}
