/* eslint-disable @typescript-eslint/no-explicit-any */
import { faCoin, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";

import trashIcon from "@/assets/icons/trashIcon.svg";

import { Icon, Modal } from "@/components";
import { InputField } from "@/components/InputField/InputField";
import { ButtonSize } from "@/components/Button/types";
import { useAddColumnToTrackerMutation } from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { Button } from "@/components/Button/Button";

import { CustomColumnFrequency, CustomColumnTypes } from "@/enum/list.enum";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import { Radio } from "@/components/Radio/Radio";
import { PermissionRoles } from "@/enum";
import { Dropdown, AutoCompleteItem } from "@/components/Dropdown/Dropdown";
import { useHasPermissions } from "@/utils/useHasPermissions";
import { ColumnConfig } from "@/pages/Private/pages/List/schema/columnConfig";

import { CustomColumn, CustomColumnSchema } from "../../../schema/trackerColumn";

export interface TechnologyModalProps {
	setShowConfirm: (value: boolean) => void;
	open: boolean;
	trackerId: number;
	setOpen: (value: boolean) => void;
	columnId?: number;
	column?: ColumnConfig;
	customColumnName?: string;
}

export const TechnologyModal: FunctionComponent<TechnologyModalProps> = ({
	setShowConfirm,
	trackerId,
	open,
	setOpen,
	columnId,
	column,
	customColumnName,
}) => {
	const [randomValues, setRandomValues] = useState<string[]>(
		column?.config ? (column.config as string[]) : []
	);
	const [randomValue, setRandomValue] = useState<string>();
	const { hasPermissions } = useHasPermissions();
	const [updateOrOnce, setUpdateOrOnce] = useState<boolean>(false);
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`aiColumn.${key}`), [t]);
	const [sumUpScoreOverTime, setSumUpScoreOverTime] = useState<boolean>(false);

	const [update, { isLoading, error }] = useAddColumnToTrackerMutation();

	const {
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CustomColumn>({
		defaultValues: {
			...column,
			customColumnType: CustomColumnTypes.TECHNOLOGY,
			customColumnName: customColumnName || column?.name,
			value: column?.config ? (column?.config as string[]) : [],
		},
		resolver: zodResolver(CustomColumnSchema),
	});

	useEffect(() => {
		if (column) {
			setRandomValues(column.config as string[]);
			if (column.updateOrOnce) {
				setUpdateOrOnce(column.updateOrOnce);
			}

			if (column.sumUpScoreOverTime) {
				setSumUpScoreOverTime(column.sumUpScoreOverTime);
			}
		}
	}, [column]);

	const onSubmit = async (values: CustomColumn) => {
		try {
			values.value = randomValues;

			await update({
				...values,
				id: trackerId,
				updateOrOnce,
				sumUpScoreOverTime,
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<Modal
			disableSave={!randomValues.length}
			handleClose={() => {
				setShowConfirm(true);
			}}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={open}
			size="md"
			submitButtonText={t("basics.confirm")}
			title="Create Technology Column"
		>
			<div className="text-ssm font-medium mb-4">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setValue("customColumnName", event.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					label="Column Name"
					name={"name"}
					placeholder="Enter column name"
					value={getValues("customColumnName")}
				/>
			</div>

			<div className="flex flex-row items-start space-y-4">
				<InputField
					className="!mb-0 grow mr-4"
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setRandomValue(event.target.value);
					}}
					name={"randomValue"}
					placeholder="Enter value"
					value={randomValue ?? ""}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							if (randomValue) {
								setRandomValues([randomValue, ...(randomValues as string[])]);
							}

							setRandomValue("");
						}
					}}
				/>
				<div className="w-[140px] !mt-0 ml-4 flex items-start">
					<Button
						className="!mt-0"
						disabled={!randomValue}
						image={<Icon className="mr-2" icon={faPlus} />}
						size={ButtonSize.L}
						title="Add value"
						onClick={() => {
							setRandomValues([...(randomValues as string[]), randomValue ?? ""]);
							setRandomValue("");
						}}
					/>
				</div>
			</div>
			<div>
				{randomValues.length > 0 && (
					<div className="text-ssm font-medium mt-4 mb-2">List of technologies to look for:</div>
				)}
				{(randomValues as string[]).map((value, index) => (
					<div
						key={index}
						className="flex items-center justify-between h-[54px] px-2.5 py-4.5 rounded-xl border border-gray-200 mb-4"
					>
						<div>{value}</div>
						<button
							className="cursor-pointer"
							onClick={() => {
								const values = [...(randomValues as string[])];

								values.splice(index, 1);
								setRandomValues(values);
							}}
						>
							<img alt="trash" src={trashIcon} />
						</button>
					</div>
				))}
			</div>
			<div className="flex flex-col mt-5 border-t pt-5 border-gray-200">
				<div className="flex  gap-4 flex-col text-gray-700 items-center">
					<Dropdown
						data={[
							{
								title: ts(CustomColumnFrequency.ONCE),
								id: CustomColumnFrequency.ONCE,
							},
							{
								title: ts(CustomColumnFrequency.DAILY),
								id: CustomColumnFrequency.DAILY,
							},
							{
								title: ts(CustomColumnFrequency.WEEKLY),
								id: CustomColumnFrequency.WEEKLY,
							},
							{
								title: ts(CustomColumnFrequency.MONTHLY),
								id: CustomColumnFrequency.MONTHLY,
							},
							{
								title: ts(CustomColumnFrequency.QUATERLY),
								id: CustomColumnFrequency.QUATERLY,
							},
							{
								title: ts(CustomColumnFrequency.HALFYEARLY),
								id: CustomColumnFrequency.HALFYEARLY,
							},
							{
								title: ts(CustomColumnFrequency.YEARLY),
								id: CustomColumnFrequency.YEARLY,
							},
						]}
						defaultValue={
							getValues("frequency") && {
								title: ts(getValues("frequency") as CustomColumnFrequency),
								id: getValues("frequency") as CustomColumnFrequency,
							}
						}
						error={!!errors.frequency?.message}
						floating={true}
						handleSelect={function (value?: AutoCompleteItem) {
							if (value?.id) {
								setValue("frequency", value.id as CustomColumnFrequency);
							}
						}}
						label="Execution frequency"
					/>
				</div>
				<div className="flex flex-col grow mr-2">
					<div className="text-ssm font-medium mt-4 mb-2">Update frequency</div>
					<div className="flex flex-col gap-4">
						<Radio
							className="whitespace-nowrap"
							isChecked={updateOrOnce}
							onChange={() => {
								setUpdateOrOnce(!updateOrOnce);
							}}
						>
							{ts("updateOnEachExecution")}
						</Radio>

						<Radio
							className="whitespace-nowrap"
							isChecked={!updateOrOnce}
							onChange={() => {
								setUpdateOrOnce(!updateOrOnce);
							}}
						>
							{ts("onceAfterFindingAValue")}
						</Radio>
					</div>
				</div>
			</div>
			<div className="flex flex-col mt-5 border-t pt-5 border-gray-200">
				<div className="flex flex-col grow mr-2">
					<div className="text-ssm font-medium mb-2">Scoring</div>
					<div className="flex flex-col gap-4">
						<InputField
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								setValue("score", +event.target.value, {
									shouldValidate: true,
									shouldDirty: true,
								});
							}}
							name={"score"}
							placeholder="Enter score"
							type="number"
							value={getValues("score")?.toString() || ""}
						/>
					</div>
				</div>
				<div className="flex flex-col grow mr-2">
					<div className="text-ssm font-medium mt-4 mb-2">Sum up score over time</div>
					<div className="flex flex-col gap-4">
						<Radio
							className="whitespace-nowrap"
							isChecked={sumUpScoreOverTime}
							onChange={() => {
								setSumUpScoreOverTime(!sumUpScoreOverTime);
							}}
						>
							{ts("sumUpScoreOverTime")}
						</Radio>

						<Radio
							className="whitespace-nowrap"
							isChecked={!sumUpScoreOverTime}
							onChange={() => {
								setSumUpScoreOverTime(!sumUpScoreOverTime);
							}}
						>
							{ts("doNotSumUpScoreOverTime")}
						</Radio>
					</div>
				</div>
			</div>
			{hasPermissions([PermissionRoles.GLOBAL_ADMIN]) && (
				<div className="flex flex-row mt-5 border-t pt-5 border-gray-200 text-gray-700 items-center text-sm">
					<span className="mr-1">2x</span>
					<Icon className="w-[16px] h-[16px] text-gray-700 mr-1" icon={faCoin} />
					<span className="">per row</span>
				</div>
			)}
			{getAllErrors(error, formErrors).length ? (
				<div className="mt-4 mb-0">{renderErrorMessages(getAllErrors(error, formErrors))}</div>
			) : null}
		</Modal>
	);
};
