import {
	faCakeCandles,
	faClose,
	faSquare,
	faSquareCheck,
	faUserGroup,
} from "@fortawesome/pro-regular-svg-icons";

import { Link } from "@mui/material";

import { FunctionComponent } from "react";

import { Tooltip } from "@/components/Tooltip/Tooltip";

import { LinkedinIcon } from "@/components/LinkedinIcon/LinkedinIcon";

import { ExternalLink } from "@/components/ExternalLink/ExternalLink";

import { Icon } from "@/components";
import { TableColumn } from "@/components/Table/Table";

import { SortParams } from "@/types/types";

import { classNames } from "@/utils/classNames";
import { Checkbox } from "@/components/Checkbox/Checkbox";

import { getOrderStatus } from "../../List/utils/colum-utils";
import { ListPerson } from "../../List/schema/list";
import { TrackerOrganization } from "../schema/trackerOrganizations";

export const renderEstimatedNumEmployeesColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "organization.estimatedNumEmployees";

	return {
		title: ts("companyEmployees"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "companyEmployees",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer mr-2"
					onClick={() =>
						handleCopyClick(row.prospect?.organization?.estimatedNumEmployees?.toString() || "")
					}
				>
					{row.prospect?.organization?.estimatedNumEmployees}{" "}
					<Icon color="##374151" icon={faUserGroup} />
				</button>
			</div>
		),
	};
};

export const renderFoundedYearColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "organization.foundedYear";

	return {
		title: ts("foundedYear"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "foundedYear",
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer mr-2"
					onClick={() =>
						row.prospect?.organization?.foundedYear &&
						handleCopyClick(row.prospect?.organization?.foundedYear?.toString() || "")
					}
				>
					{row.prospect?.organization?.foundedYear}{" "}
					{row.prospect?.organization?.foundedYear && (
						<Icon color="##374151" icon={faCakeCandles} />
					)}
				</button>
			</div>
		),
	};
};

export const renderIndustryColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "organization.industry";

	return {
		title: ts("industry"),
		width: "fit-content",
		orderTitle: "organization.industry",
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "industry",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div>
				<button
					className="cursor-pointer"
					onClick={() =>
						handleCopyClick(
							row.prospect?.organization?.industry?.capitalizeString() ??
								row.mapBusiness?.type ??
								""
						)
					}
				>
					{row.prospect?.organization?.industry?.capitalizeString() ?? row.mapBusiness?.type}
				</button>
			</div>
		),
	};
};

export const renderCompanyHoldingColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams,
	setShowEdit: ({ id, name }: { id: number; name: string }) => void,
	setShowDelete: ({ id, name }: { id: number; name: string }) => void,
	showEdit: { id: number; name: string } | undefined
): TableColumn => {
	const orderTitle = "holdingOrganization.name";

	return {
		title: ts("holdingCompany"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		minWidth: "220px",
		uid: "holdingCompany",
		hideable: true,
		locked: "leftThird",
		moveable: false,
		show: true,
		render: (row: TrackerOrganization) => (
			<EditableHoldingCompanyColumn
				{...{
					row,
					handleCopyClick,
					orderTitle,
					setShowEdit,
					showEdit,
					sortingInbox,
					setShowDelete,
				}}
			/>
		),
	};
};

export const renderCompanyColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams,
	setShowEdit: ({ id, name }: { id: number; name: string }) => void,
	showEdit: { id: number; name: string } | undefined
): TableColumn => {
	const orderTitle = "organization.name";

	return {
		title: ts("company"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		minWidth: "220px",
		uid: "company",
		hideable: true,
		locked: "leftSecond",
		moveable: false,
		show: true,
		render: (row: TrackerOrganization) => (
			<EditableCompanyColumn
				{...{
					row,
					handleCopyClick,
					orderTitle,
					setShowEdit,
					showEdit,
					sortingInbox,
				}}
			/>
		),
	};
};

export const EditableCompanyColumn: FunctionComponent<{
	row: TrackerOrganization;
	handleCopyClick: (value: string) => void;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
	orderTitle: string;
}> = ({ row, handleCopyClick, orderTitle, setShowEdit }) => {
	function truncate(str: string, n: number) {
		return str.length > n ? str.slice(0, n - 1) + "..." : str;
	}

	return (
		<div className="flex w-full max-w-[220px] items-center">
			{row.organization?.logoUrl && (
				<img
					alt="logo"
					className="h-full w-[24px] rounded mr-1"
					referrerPolicy="no-referrer"
					src={row.organization?.logoUrl}
					onError={(obj) => obj?.currentTarget?.parentNode?.removeChild(obj?.currentTarget)}
				/>
			)}
			<div className="ml-2 w-full flex flex-row justify-between">
				<Tooltip
					content={
						<div className="flex flex-col min-w-[300px] text-sm">
							<div>
								<span className="font-bold">Name:</span>
								{row.name || row.organization.name || ""}
							</div>
							<div>
								<span className="font-bold">Followers:</span>
								{row.organization?.followerCount || 0}
							</div>
							<div>
								<span className="font-bold">Short Description:</span>
								<span
									className="leading-6 truncate ..."
									style={{
										textWrap: "wrap",
									}}
								>
									{truncate(row.organization?.shortDescription ?? "", 180)}
								</span>
							</div>
						</div>
					}
				>
					<button
						className="cursor-pointer mr-1  max-w-[100px] text-ellipsis overflow-hidden"
						onClick={() => handleCopyClick(row.name || "")}
					>
						<span
							onContextMenu={(e) => {
								e.preventDefault(); // prevent the default behaviour when right clicked
								console.log("Right Click");
								setShowEdit({ id: row.id, name: orderTitle });
							}}
						>
							{row.name || row.organization.name || ""}
						</span>
					</button>
				</Tooltip>

				<div className="flex items-center">
					{row.organization?.linkedinUrl && (
						<Link href={row.organization?.linkedinUrl} target="_blank">
							<LinkedinIcon status="checked" />
						</Link>
					)}
					{row.organization?.websiteUrl && (
						<Link href={row.organization?.websiteUrl} target="_blank">
							<ExternalLink />
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export const EditableHoldingCompanyColumn: FunctionComponent<{
	row: TrackerOrganization;
	handleCopyClick: (value: string) => void;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	setShowDelete: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
	orderTitle: string;
}> = ({ row, handleCopyClick, orderTitle, setShowEdit, setShowDelete }) => {
	function truncate(str: string, n: number) {
		return str.length > n ? str.slice(0, n - 1) + "..." : str;
	}

	return (
		<div className="flex w-full max-w-[220px] items-center">
			{row.holdingOrganization?.logoUrl && (
				<img
					alt="logo"
					className="h-full w-[24px] rounded mr-1"
					referrerPolicy="no-referrer"
					src={row.holdingOrganization?.logoUrl}
					onError={(obj) => obj?.currentTarget?.parentNode?.removeChild(obj?.currentTarget)}
				/>
			)}
			<div className="ml-2 w-full flex flex-row justify-between group">
				<Tooltip
					content={
						<div className="flex flex-col min-w-[300px] text-sm">
							<div>
								<span className="font-bold">Name:</span>
								{row.holdingOrganization?.name || ""}
							</div>
							<div>
								<span className="font-bold">Followers:</span>
								{row.holdingOrganization?.followerCount || 0}
							</div>
							<div>
								<span className="font-bold">Short Description:</span>
								<span
									className="leading-6 truncate ..."
									style={{
										textWrap: "wrap",
									}}
								>
									{truncate(row.holdingOrganization?.shortDescription ?? "", 180)}
								</span>
							</div>
						</div>
					}
				>
					<button
						className="cursor-pointer mr-1  max-w-[100px] text-ellipsis overflow-hidden"
						onClick={() => handleCopyClick(row.name || "")}
					>
						<span
							onContextMenu={(e) => {
								e.preventDefault(); // prevent the default behaviour when right clicked
								console.log("Right Click");
								setShowEdit({ id: row.id, name: orderTitle });
							}}
						>
							{row.holdingOrganization?.name || "none"}
						</span>
					</button>
				</Tooltip>

				<div className="flex items-center">
					{row.holdingOrganization?.linkedinUrl && (
						<Link href={row.holdingOrganization?.linkedinUrl} target="_blank">
							<LinkedinIcon status="checked" />
						</Link>
					)}
					{row.holdingOrganization?.websiteUrl && (
						<Link href={row.holdingOrganization?.websiteUrl} target="_blank">
							<ExternalLink />
						</Link>
					)}
					<div className="hidden group-hover:flex">
						<Icon
							className="cursor-pointer mr-2 w-[20px] h-[20px]"
							icon={faClose}
							onClick={(e: MouseEvent) => {
								e.preventDefault();
								e.stopPropagation();

								setShowDelete({ id: row.id, name: row.holdingOrganization?.name || "" });
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export const renderSelectColumn = (
	toggleSelectAll: () => void,
	selectAll: boolean,
	selectedItems: number[],
	handleSelectItem: (id: number) => void
): TableColumn => {
	return {
		title: "",
		uid: "select",
		icon: selectAll ? faSquareCheck : faSquare,
		allowExclude: false,
		locked: "leftFirst",
		hideable: false,
		moveable: false,
		show: true,
		onHeaderClick: () => {
			toggleSelectAll();
		},
		render: (row: TrackerOrganization, index?: number) => (
			<div className="group flex items-center">
				<div
					className={classNames(
						"w-[16px] text-center font-medium text-table-header",
						selectedItems?.find((lf) => lf === row.id) ? "hidden" : "group-hover:hidden"
					)}
				>
					{typeof index !== "undefined" ? index + 1 : ""}
				</div>
				<Checkbox
					className={classNames(
						selectedItems?.find((lf) => lf === row.id) ? "block" : "hidden group-hover:block"
					)}
					isChecked={!!selectedItems?.find((lf) => lf === row.id)}
					name={"selectedProspects"}
					onChange={() => {
						handleSelectItem(row.id);
					}}
				/>
			</div>
		),
	};
};

export const renderCompanyCityColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "organization.city";

	return {
		title: ts("companyCity"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "city",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: TrackerOrganization) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer"
					type="button"
					onClick={() => row.organization?.city && handleCopyClick(row.organization?.city)}
				>
					{row.organization?.city}
				</button>
			</div>
		),
	};
};

export const renderCompanyStateColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "organization.state";

	return {
		title: ts("companyState"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "state",
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer"
					type="button"
					onClick={() =>
						row.prospect?.organization?.state && handleCopyClick(row.prospect?.organization?.state)
					}
				>
					{row.prospect?.organization?.state}
				</button>
			</div>
		),
	};
};

export const renderCompanyCountryColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "organization.country";

	return {
		title: ts("companyCountry"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "country",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: TrackerOrganization) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer"
					type="button"
					onClick={() => row.organization?.country && handleCopyClick(row.organization?.country)}
				>
					{row.organization?.country}
				</button>
			</div>
		),
	};
};

export const renderScoreColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "score";

	return {
		title: ts("score"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "score",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: TrackerOrganization) => {
			const score = row.columns.reduce((acc, column) => {
				const stringValue =
					typeof column.value?.answer === "string" ? (column.value?.answer as string) : "";
				const numberValue =
					typeof column.value?.answer === "number" ? (column.value?.answer as number) : 0;

				const sumUpScoreOverTime = column?.columnConfig.sumUpScoreOverTime;

				if (sumUpScoreOverTime) {
					const currentHistoricalColumns = row.columnsHistory?.filter(
						(historyColumn) => historyColumn.columnConfigId === column.columnConfigId
					);

					const currentHistoricalColumnsScore = currentHistoricalColumns.reduce(
						(acc, historyColumn) => {
							const stringValue =
								typeof historyColumn.value?.answer === "string"
									? (historyColumn.value?.answer as string)
									: "";
							const numberValue =
								typeof historyColumn.value?.answer === "number"
									? (historyColumn.value?.answer as number)
									: 0;

							if (
								historyColumn.value?.answer &&
								((stringValue && stringValue.toLowerCase() !== "no") ||
									(numberValue && numberValue > 0))
							) {
								acc += historyColumn?.columnConfig?.score || 0;
							}

							return acc;
						},
						0
					);

					acc += currentHistoricalColumnsScore;
				} else if (
					column.value?.answer &&
					((stringValue && stringValue.toLowerCase() !== "no") || (numberValue && numberValue > 0))
				) {
					acc += column?.columnConfig?.score || 0;
				}

				return acc;
			}, 0);

			return (
				<div className="flex flex-row">
					<button
						className="cursor-pointer"
						type="button"
						onClick={() => score && handleCopyClick(score.toString())}
					>
						{score}
					</button>
				</div>
			);
		},
	};
};
