import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import {
	AiApplyType,
	AssignStatus,
	CustomColumnTypes,
	InclusionFilterStatus,
	InclusionStatus,
	ListAction,
	ListProcessingStatus,
	ListSourceType,
	ListStatus,
	ListType,
	ResponseStatus,
	SearchBase,
	UpdateType,
} from "@/enum/list.enum";

import { AiEnrichmentTarget } from "@/enum/ai-enrichment.enum";

import { SortParams } from "@/types/types";

import { ApolloType } from "@/enum/apollo.enum";

import { LiProfileType } from "@/enum/liprofile.enum";

import { LiCompanyActivityType } from "@/enum/licompanyactivity.enum";

import { MapScrapeType } from "@/enum/map.enum";

import { JobScrapeType } from "@/enum/job.enum";

import {
	ApolloCreatedSchema,
	ApolloSearchResultPersonSchema,
} from "../../Apollo/schema/get-apollos";
import { PeopleSchema } from "../../Apollo/schema/get-people";
import { CsvEnrichmentSchema } from "../../CsvEnrichment/schema/csvEnrichment";
import { CustomEventSchema } from "../../CustomEvent/schema/customEvent";

import { JobSchema } from "../../Job/schema/job";
import { OfferSchema } from "../../Job/schema/offer";
import { LiCompanyActivitySchema } from "../../LiCompanyActivity/schema/liCompanyActivity";
import { LiEventSchema } from "../../LiEvent/schema/liEvent";
import { LiEventEntrySchema } from "../../LiEvent/schema/liEventEntry";
import { LiPeopleSchema } from "../../LiPeople/schema/liPeople";
import { LiPostSchema } from "../../LiPost/schema/liPost";
import { LiProfilePostsSchema } from "../../LiProfile/schema/liProfile";
import { LiProfileVisitsSchema } from "../../LiProfileVisits/schema/liProfileVisits";
import { MapSchema } from "../../Map/schema/map";
import { MapBusinessSchema } from "../../Map/schema/mapBusiness";
import { AiEnrichmentResultSchema, OpenAiSchema } from "../../OpenAi/schema/openAi";
import { ScoreSchema } from "../../Score/schema/score.schema";
import { UserSchema } from "../../Users/schema/invite-user";
import { FilterUnassignedTable } from "../components/UnassignedTable";
import { CampaignRuleSchema } from "./campaign-rule";
import { ColumnConfigSchema } from "./columnConfig";
import { ColumnValueSchema } from "./columnValue";

const { REQUIRED } = ERROR_TYPE;

export const NorthdataSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
});
export const LemlistSchema = z.object({
	id: z.number(),
	status: z.string(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
});

export const LemlistAllSchema = z.array(LemlistSchema);

export const CustomColumnSchema = z.object({
	id: z.number().optional(),
	columnId: z.number().optional(),
	customColumnName: z.string(),
	customColumnType: z.nativeEnum(CustomColumnTypes),
	prompt: z.string().optional(),
	value: z
		.string()
		.or(z.number().or(z.boolean().or(z.array(z.string()).or(z.any()))))
		.nullable()
		.optional(),
	sourceConfig: z
		.string()
		.or(z.number().or(z.boolean().or(z.array(z.string()).or(z.any()))))
		.nullable()
		.optional(),
	saveAsTemplate: z.boolean().optional(),
	online: z.boolean().optional(),
	single: z.boolean().optional(),
});

export const BulkAssignResponseSchema = z.object({
	status: z.string(),
});

export const ArchiveResponseSchema = z.object({
	status: z.string(),
});

export const ListSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	refreshEveryXDays: z.number().nullable(),
	maxProspects: z.number().nullable(),
	withEmail: z.boolean(),
	tryToFindEmail: z.boolean(),
	onlyNewLeads: z.boolean(),
	apollos: z.array(ApolloCreatedSchema).optional(),
	liEvents: z.array(LiEventSchema).optional(),
	liPeople: z.array(LiPeopleSchema).optional(),
	liCompanyActivity: z.array(LiCompanyActivitySchema).optional(),
	liProfileVisits: z.array(LiProfileVisitsSchema).optional(),
	liProfile: z.array(LiProfilePostsSchema).optional(),
	liPosts: z.array(LiPostSchema).optional(),
	processingStatus: z.nativeEnum(ListProcessingStatus),
	aiEnrichments: z.array(OpenAiSchema).optional(),
	csvEnrichments: z.array(CsvEnrichmentSchema).optional(),
	customEvents: z.array(CustomEventSchema).optional(),
	maps: z.array(MapSchema).optional(),
	prospects: z.array(ApolloSearchResultPersonSchema).optional(),
	searchBase: z.nativeEnum(SearchBase),
	jobs: z.array(JobSchema).optional(),
	scores: z.array(ScoreSchema).optional(),
	lemlists: z.array(LemlistSchema).optional(),
	status: z.nativeEnum(ListStatus),
	found: z.number().optional().nullable(),
	processed: z.number().optional().nullable(),
	savedForLater: z.number().optional().nullable(),
	assignedLeads: z.number().optional().nullable(),
	sourceType: z.nativeEnum(ListSourceType),
	customColumns: z
		.record(
			z.string(),
			z.object({ type: z.nativeEnum(CustomColumnTypes), value: z.string().optional() })
		)
		.nullable(),
	columns: z.array(ColumnConfigSchema).optional(),
	type: z.nativeEnum(ListType),
	iteration: z.number(),
	updatedAt: z.string().transform((arg) => new Date(arg)),
	updatedBy: UserSchema.optional().nullable(),
	scrapeLevel: z.nativeEnum(MapScrapeType).or(z.nativeEnum(JobScrapeType)).nullable().optional(),
	isNewCreationProcess: z.boolean().optional(),
	campaignRules: z.array(CampaignRuleSchema).optional(),
	draftCount: z.number().optional(),
	assignedCount: z.number().optional(),
	savedForLaterCount: z.number().optional(),
	blacklistCount: z.number().optional(),
	archived: z.boolean().optional(),
});

export const ListLogSchema = z.object({
	id: z.number(),
	list: ListSchema,
	action: z.nativeEnum(ListAction),
	description: z.string().nullable(),
	iteration: z.number(),
	data: z.any().nullable(),
	updatedAt: z.string(),
	createdAt: z.string(),
});

export const ListSourceSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	sourceType: z.nativeEnum(ListSourceType),
	type: z.nativeEnum(ApolloType).optional(),
});

export const ListPersonSchema = z.object({
	id: z.number(),
	name: z.string().trim().optional(),
	assignStatus: z.nativeEnum(AssignStatus),
	plannedAssignStatus: z.nativeEnum(AssignStatus).nullable(),
	responseStatus: z.nativeEnum(ResponseStatus),
	inclusionStatus: z.nativeEnum(InclusionStatus),
	companyPeopleCheck: z
		.object({
			assignedAllTime: z.number(),
			assignedOnThisList: z.number(),
			notAssignedOnThisList: z.number(),
		})
		.nullable()
		.optional(),
	activities: z
		.array(
			z.object({
				activity: z.string(),
				executedAt: z.string().transform((arg) => new Date(arg)),
				status: z.string().optional(),
				reply: z.string().nullable(),
				message: z.string().nullable(),
			})
		)
		.optional(),
	list: ListSchema,
	prospect: PeopleSchema.optional(),
	score: z
		.array(
			z.object({
				id: z.number(),
				name: z.string(),
				scoreValue: z.number(),
			})
		)
		.optional(),
	lemlist: LemlistSchema.optional(),
	userAssigned: UserSchema.optional(),
	aiEnrichmentResultPerson: z.array(AiEnrichmentResultSchema).optional(),
	mapBusiness: MapBusinessSchema.optional(),
	offer: OfferSchema.optional(),
	aiEnrichmentResultOrg: z.array(AiEnrichmentResultSchema).optional(),
	additionalInformation: z.any().nullable(),
	blacklistType: z.string().nullable().optional(),
	otherLists: z.array(ListSchema).optional(),
	otherEvents: z.array(LiEventEntrySchema).nullable().optional(),
	liEventEntry: LiEventEntrySchema.optional(),
	jobs: z.array(JobSchema).optional(),
	customColumns: z.record(z.string(), z.string().or(z.number().or(z.boolean()))).nullable(),
	connections: z.array(z.string()).optional(),
	columns: z.array(ColumnValueSchema),
	liPostResult: z
		.object({
			contentDate: z.date().nullable(),
			content: z.string().nullable(),
			contentUrl: z.string().nullable(),
			comments: z.number().nullable(),
			likes: z.number().nullable(),
			shares: z.number().nullable(),
			type: z.nativeEnum(LiProfileType).nullable(),
		})
		.optional(),
	liCompanyActivityEntryResult: z
		.object({
			contentDate: z.string().nullable(),
			content: z.string().nullable(),
			contentUrl: z.string().nullable(),
			contentResponse: z.string().nullable(),
			contentResponseUrl: z.string().nullable(),
			type: z.nativeEnum(LiCompanyActivityType).nullable(),
		})
		.optional(),
	liProfileEntryResult: z
		.object({
			contentDate: z.date().nullable(),
			content: z.string().nullable(),
			contentUrl: z.string().nullable(),
			contentResponse: z.string().nullable(),
			contentResponseUrl: z.string().nullable(),
			type: z.nativeEnum(LiProfileType).nullable(),
		})
		.optional(),
	sourceName: z.string().nullable(),
});

export const ListAiEnrichmentSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	aiEnrichmentTarget: z.nativeEnum(AiEnrichmentTarget),
});

export const CreateListSchema = z
	.object({
		name: z.string().min(4, { message: REQUIRED }).trim(),
		sourceType: z.nativeEnum(ListSourceType),
		searchBase: z.nativeEnum(SearchBase),
	})
	.refine((data) => {
		if (
			![ListSourceType.JOBS, ListSourceType.MAPS].includes(data.sourceType) &&
			data.searchBase === SearchBase.SOURCE
		) {
			return false;
		}

		return true;
	}, "REQUIRED");

export const SaveListSettingsSchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	searchBase: z.nativeEnum(SearchBase),
	type: z.nativeEnum(ListType),
	maxProspects: z.string(),
	maxPerCompany: z.string(),
	withEmail: z.boolean(),
	tryToFindEmail: z.boolean(),
	campaigns: z.array(z.number()),
	onlyNewLeads: z.boolean(),
});

export const SaveListSettingsValidationSchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	searchBase: z.nativeEnum(SearchBase),
	type: z.nativeEnum(ListType),
	maxProspects: z.string(),
	maxPerCompany: z.string(),
});

export const UpdateListDetailsSchema = z.object({
	id: z.number(),
	scrapeLevel: z.nativeEnum(MapScrapeType).or(z.nativeEnum(JobScrapeType)).optional(),
	updateType: z.nativeEnum(UpdateType),
});

export const UpdateListSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim().optional(),
	maxProspects: z.number().nullable().optional(),
	apollos: z.array(z.number()),
	liEvents: z.array(z.number()),
	csvEnrichments: z.array(z.number()),
	northdatas: z.array(z.number()),
	customEvents: z.array(z.number()),
	liPeople: z.array(z.number()),
	liPosts: z.array(z.number()),
	liCompanyActivity: z.array(z.number()),
	jobs: z.array(z.number()),
	liProfileVisits: z.array(z.number()),
	liProfile: z.array(z.number()),
	maps: z.array(z.number()),
	aiEnrichments: z.array(z.number()),
	campaigns: z.array(z.number()),
	scores: z.array(z.number()),
	updateType: z.nativeEnum(UpdateType),
	status: z.nativeEnum(ListStatus).optional(),
	columnId: z.number().optional(),
	aiApplyType: z.nativeEnum(AiApplyType).optional(),
	prospectIds: z.array(z.number()).optional(),
	saveAsTemplate: z.boolean().optional(),
	withEmail: z.boolean().optional(),
	tryToFindEmail: z.boolean().optional(),
	archived: z.boolean().optional(),
	campaignRules: z.array(CampaignRuleSchema).optional(),
});

export const AssignSchema = z.object({
	listPersonId: z.number(),
	prospectId: z.number().optional(),
	listId: z.number().optional(),
	lemlistId: z.number().optional(),
	assignedToUserId: z.number().optional(),
});

export const BulkAssignSchema = z.object({
	listId: z.number().optional(),
	assign: z.array(AssignSchema),
	assignStatus: z.nativeEnum(AssignStatus),
});

export const ArchiveSchema = z.object({
	ids: z.array(z.number()),
	archived: z.boolean(),
});

export const AssignListViewSchema = z.object({
	listPersonId: z.number(),
	assignStatus: z.nativeEnum(AssignStatus),
});

export const BulkAssignListViewSchema = z.object({
	assign: z.array(AssignListViewSchema),
});

export const ChangeStatusSchema = z.object({
	listPersonId: z.number(),
});

export const BulkChangeStatusSchema = z.object({
	listId: z.number(),
	assign: z.array(ChangeStatusSchema),
	assignStatus: z.nativeEnum(AssignStatus).optional(),
	inclusionStatus: z.nativeEnum(InclusionStatus).optional(),
});

export const UpdateSettingsListSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	maxProspects: z.number().nullable(),
	type: z.nativeEnum(ListType),
	withEmail: z.boolean(),
	tryToFindEmail: z.boolean(),
	onlyNewLeads: z.boolean(),
	searchBase: z.nativeEnum(SearchBase),
	sourceType: z.nativeEnum(ListSourceType),
});

export const FilterByValueSchema = z.object({
	data: z.array(
		z.object({
			value: z.string(),
			id: z.number().optional(),
		})
	),
});

export const FilterSchema = z.object({
	events: z.array(
		z.object({
			liEventId: z.number(),
			liEventEntryId: z.number(),
			liEventEntryName: z.string(),
			attendees: z.number(),
			eventAt: z.string().transform((arg) => new Date(arg)),
		})
	),
	lists: z.array(
		z.object({
			listId: z.number(),
			listName: z.string(),
		})
	),
});

export const ListReportSchema = z.object({
	recordId: z.number().optional(),
	leadName: z.string().min(4, { message: REQUIRED }).trim().optional(),
	companyName: z.string().min(4, { message: REQUIRED }).trim().optional(),
	listName: z.string().min(4, { message: REQUIRED }).trim().optional(),
	listId: z.number().optional(),
	leadId: z.number().optional(),
	reportReason: z.string().min(4, { message: REQUIRED }).trim().optional(),
});

export const ListResponseSchema = z.object({
	data: z.array(ListSchema),
	meta: MetaDataSchema,
});

export const ListLogResponseSchema = z.object({
	data: z.array(ListLogSchema),
	meta: MetaDataSchema,
});

export const ListPersonResponseSchema = z.object({
	data: z.array(ListPersonSchema),
	meta: MetaDataSchema,
});

export const ListAiEnrichmentResponseSchema = z.object({
	data: z.array(ListAiEnrichmentSchema),
	meta: MetaDataSchema,
});

export const LemlistResponseSchema = z.object({
	data: z.array(LemlistSchema),
	meta: MetaDataSchema,
});

export const ListSourceResponseSchema = z.object({
	data: z.array(ListSourceSchema),
	meta: MetaDataSchema,
});

export const ListReportResponseSchema = z.object({
	data: z.array(ListSchema),
	meta: MetaDataSchema,
});

export type SaveListSettings = z.infer<typeof SaveListSettingsSchema>;

export type List = z.infer<typeof ListSchema>;
export type ListLog = z.infer<typeof ListLogSchema>;
export type ListSource = z.infer<typeof ListSourceSchema>;
export type ListAiEnrichment = z.infer<typeof ListAiEnrichmentSchema>;
export type Lemlist = z.infer<typeof LemlistSchema>;
export type LemlistAll = z.infer<typeof LemlistAllSchema>;
export type ListReport = z.infer<typeof ListReportSchema>;

export type CreateList = z.infer<typeof CreateListSchema>;
export type UpdateList = z.infer<typeof UpdateListSchema>;
export type UpdateSettingsList = z.infer<typeof UpdateSettingsListSchema>;

export type ListResponse = z.infer<typeof ListResponseSchema>;
export type ListReportResponse = z.infer<typeof ListReportResponseSchema>;
export type CustomColumn = z.infer<typeof CustomColumnSchema>;
export type ListLogResponse = z.infer<typeof ListLogResponseSchema>;
export type ListSourceResponse = z.infer<typeof ListSourceResponseSchema>;
export type ListAiEnrichmentResponse = z.infer<typeof ListAiEnrichmentResponseSchema>;
export type LemlistResponse = z.infer<typeof LemlistResponseSchema>;

export type ListPersonResponse = z.infer<typeof ListPersonResponseSchema>;
export type ListPerson = z.infer<typeof ListPersonSchema>;

export type BulkAssign = z.infer<typeof BulkAssignSchema>;
export type Archive = z.infer<typeof ArchiveSchema>;
export type BulkAssignListView = z.infer<typeof BulkAssignListViewSchema>;

export type BulkChangeStatus = z.infer<typeof BulkChangeStatusSchema>;

export type Filter = z.infer<typeof FilterSchema>;

export type FilterByValue = z.infer<typeof FilterByValueSchema>;

export type UpdateListDetails = z.infer<typeof UpdateListDetailsSchema>;

export type BulkAssignResponse = z.infer<typeof BulkAssignResponseSchema>;

export type ArchiveResponse = z.infer<typeof ArchiveResponseSchema>;

export interface GetUnassignedListRequestPaginationParams {
	page: number;
	limit: number;
	fieldName?: string;
	searchValue?: string;
	campaignFilter?: string;
	companySearch?: string;
	campaignId?: number;
	sorting?: SortParams;
	assignStatus?: AssignStatus[];
	listId?: number;
	inclusionFilterStatus?: InclusionFilterStatus[];
	filter?: FilterUnassignedTable;
}

export interface ListRequestPaginationParams {
	page: number;
	limit: number;
	fieldName?: string;
	searchValue?: string;
	campaignFilter?: string;
	companySearch?: string;
	campaignId?: number;
	sorting?: SortParams;
	assignStatus?: AssignStatus;
	listId?: number;
	inclusionFilterStatus?: InclusionFilterStatus[];
	filter?: FilterUnassignedTable;
}

export interface ListViewReqiestPaginationParams {
	page: number;
	limit: number;
	fieldName?: string;
	searchValue?: string;
	campaignFilter?: string;
	companySearch?: string;
	campaignId?: number;
	sorting?: SortParams;
	assignStatus?: AssignStatus;
	listId?: number;
	inclusionFilterStatus?: InclusionFilterStatus;
	filter?: FilterUnassignedTable;
	type?: string;
}

export interface DownloadFile {
	id: number;
	name: string;
}
