import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";

import { Modal } from "@/components";
import { Checkbox } from "@/components/Checkbox/Checkbox";
import { SearchField } from "@/components/SearchField/SearchField";
import { Suggestion, Suggestions } from "@/pages/Private/pages/LiPeople/schema/liPeople";
import { useLazyGetCompanySuggestionQuery } from "@/pages/Private/redux/liPeople/liPeople.api";
import { useSearch } from "@/utils";
import { useAddHoldingToOrganizationMutation } from "@/pages/Private/redux/profileTracker/profileTracker.api";

export interface SearchForCompanyHoldingModalProps {
	open: boolean;
	setOpen: (value: boolean) => void;
	trackerOrganizationId: number;
}

export const SearchForCompanyHoldingModal: FunctionComponent<SearchForCompanyHoldingModalProps> = ({
	open,
	setOpen,
	trackerOrganizationId,
}) => {
	const { searchValue, query, setSearchValue } = useSearch();

	const [companies, setCompanies] = useState<Suggestions>();

	const [selectedCompany, setSelectedCompany] = useState<Suggestion>();

	const [fetchCompany, { isFetching }] = useLazyGetCompanySuggestionQuery();

	const [addCompaniesToTracker, { isLoading: addCompaniesIsLoading }] =
		useAddHoldingToOrganizationMutation();

	useEffect(() => {
		const fetchCompaniesFromAPI = async (keyword: string) => {
			const people = await fetchCompany({
				keyword,
			}).unwrap();

			setCompanies(people);
		};

		if (query) {
			fetchCompaniesFromAPI(query);
		}
	}, [fetchCompany, query, setCompanies]);

	const savePeople = async () => {
		if (selectedCompany) {
			await addCompaniesToTracker({
				id: trackerOrganizationId,
				company: selectedCompany,
			}).unwrap();
		}

		setOpen(false);
	};

	return (
		<Modal
			handleClose={() => setOpen(false)}
			handleSave={() => savePeople()}
			isLoading={addCompaniesIsLoading}
			isOpened={open}
			title={"Search for holding company"}
		>
			<SearchField
				className="mb-4"
				handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
					setSearchValue(event.target.value);
				}}
				name={"searchPerson"}
				placeholder="Search for a person"
				value={searchValue}
			/>

			{isFetching && (
				<div className="w-full flex h-[50px] mb-4 mt-4 justify-center items-center">
					<CircularProgress />
				</div>
			)}

			{companies?.suggestions
				?.filter((person) => selectedCompany?.urn !== person.urn)
				?.map((person, index) => {
					return (
						<div
							key={`person-search-${index}`}
							className="w-full border border-gray-200 rounded-xl mb-4 mt-2 flex flex-row items-center"
						>
							<div className="flex items-center w-full">
								<img className="w-[48px] h-[48px] flex mr-3 rounded-l-xl" src={person.image} />
								<div className="grow flex flex-col">
									<span className="text-sm font-semibold">{person.title}</span>
									<span className="text-xs">{person.subtitle}</span>
								</div>
								<Checkbox
									className="!w-[20px] ml-4 mr-4"
									isChecked={selectedCompany === person}
									onChange={() => {
										if (selectedCompany === person) {
											setSelectedCompany(undefined);
										} else {
											setSelectedCompany(person);
										}
									}}
								/>
							</div>
						</div>
					);
				})}
			{selectedCompany && (
				<div className="w-full border border-gray-200 rounded-xl mb-4 mt-2 flex flex-row items-center">
					<div className="flex items-center w-full">
						<img className="w-[48px] h-[48px] flex mr-3 rounded-l-xl" src={selectedCompany.image} />
						<div className="grow flex flex-col">
							<span className="text-sm font-semibold">{selectedCompany.title}</span>
							<span className="text-xs">{selectedCompany.subtitle}</span>
						</div>
						<Checkbox
							className="!w-[20px] ml-4 mr-4"
							isChecked={true}
							onChange={() => {
								setSelectedCompany(undefined);
							}}
						/>
					</div>
				</div>
			)}
		</Modal>
	);
};
