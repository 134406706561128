import { FunctionComponent, useMemo } from "react";

import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "@/components";

import { TableColumn } from "@/components/Table/Table";

import { useGetListSourcesQuery, useUpdateListMutation } from "@/pages/Private/redux/list/list.api";

import { ListSourceType, UpdateType } from "@/enum/list.enum";

import { useNonStickySearch } from "@/utils/useNonStickySearch";

import { TablePagination } from "@/components/TablePagination/TablePagination";

import { List, UpdateList } from "../schema/list";

export interface SelectableSourceListProps {
	list: List;
}

export const SelectableSourceList: FunctionComponent<SelectableSourceListProps> = ({ list }) => {
	const [update] = useUpdateListMutation();

	const { page, limit, query, setPage } = useNonStickySearch();

	const { data } = useGetListSourcesQuery({
		id: list.id,
		page,
		limit,
		searchValue: query,
	});

	const filteredSources = useMemo(() => {
		if (!data) {
			return [];
		}

		const sourceIds = [
			...(list.maps || []),
			...(list.jobs || []),
			...(list.liEvents || []),
			...(list.liProfileVisits || []),
			...(list.liCompanyActivity || []),
			...(list.csvEnrichments || []),
			...(list.liProfile || []),
			...(list.liPeople || []),
		].map((source) => source.id);

		return data.data.filter((source) => {
			return !sourceIds.includes(source.id);
		});
	}, [data, list]);

	console.log("---page source list---", data);

	const handleAddToList = async (row: { id: number }) => {
		let updater: Partial<UpdateList> = {
			id: +list.id,
			updateType: UpdateType.SOURCE,
		};

		if (list.sourceType === ListSourceType.MAPS) {
			updater = { ...updater, maps: [...(list.maps?.map((m) => m.id) || []), row.id] };
		}

		if (list.sourceType === ListSourceType.JOBS) {
			updater = { ...updater, jobs: [...(list.jobs?.map((m) => m.id) || []), row.id] };
		}

		if (list.sourceType === ListSourceType.LI_EVENTS) {
			updater = { ...updater, liEvents: [...(list.liEvents?.map((m) => m.id) || []), row.id] };
		}

		if (list.sourceType === ListSourceType.CSV_ENRICHMENTS) {
			updater = {
				...updater,
				csvEnrichments: [...(list.csvEnrichments?.map((m) => m.id) || []), row.id],
			};
		}

		if (list.sourceType === ListSourceType.LI_POST) {
			updater = { ...updater, liPosts: [...(list.liPosts?.map((m) => m.id) || []), row.id] };
		}

		if (list.sourceType === ListSourceType.LI_PROFILE) {
			updater = { ...updater, liProfile: [...(list.liProfile?.map((m) => m.id) || []), row.id] };
		}

		if (list.sourceType === ListSourceType.LI_PROFILE_VISITS) {
			updater = {
				...updater,
				liProfileVisits: [...(list.liProfileVisits?.map((m) => m.id) || []), row.id],
			};
		}

		if (list.sourceType === ListSourceType.LI_PEOPLE) {
			updater = { ...updater, liPeople: [...(list.liPeople?.map((m) => m.id) || []), row.id] };
		}

		if (list.sourceType === ListSourceType.LI_COMPANY_ACTIVITY) {
			updater = {
				...updater,
				liCompanyActivity: [...(list.liCompanyActivity?.map((m) => m.id) || []), row.id],
			};
		}

		await update({ ...updater }).unwrap();
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const renderResults = (row: any) => {
		if (list.sourceType === ListSourceType.MAPS) {
			return <div className="flex items-center">{row.mapBusiness?.length}</div>;
		}

		if (list.sourceType === ListSourceType.JOBS) {
			return <div className="flex items-center">{row.offers?.length}</div>;
		}

		if (list.sourceType === ListSourceType.CSV_ENRICHMENTS) {
			return <div className="flex items-center">{row.amountInCsv}</div>;
		}

		if (list.sourceType === ListSourceType.LI_POST) {
			return <div className="flex items-center">{row.liPostResults?.length}</div>;
		}

		if (list.sourceType === ListSourceType.LI_PROFILE) {
			return (
				<div className="flex items-center">
					{row.liProfileEntries?.[0]?.liProfileEntryResults?.length}
				</div>
			);
		}

		if (list.sourceType === ListSourceType.LI_PROFILE_VISITS) {
			return (
				<div className="flex items-center">
					{row.liProfileVisitsEntries?.[0]?.liProfileVisitsEntryResults?.length}
				</div>
			);
		}

		if (list.sourceType === ListSourceType.LI_COMPANY_ACTIVITY) {
			return (
				<div className="flex items-center">
					{row.liCompanyActivityEntries?.[0]?.liCompanyActivityEntryResults?.length}
				</div>
			);
		}

		return <></>;
	};

	const columns: TableColumn[] = [
		{
			title: "name",
			uid: "name",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			render: (row: any) => <div className="flex items-center">{row.name}</div>,
		},
		...(list.sourceType !== ListSourceType.LI_EVENTS && list.sourceType !== ListSourceType.LI_PEOPLE
			? [
					{
						title: "results",
						uid: "results",
						allowExclude: false,
						hideable: false,
						moveable: false,
						show: true,
						render: renderResults,
					},
			  ]
			: []),
		{
			title: "",
			uid: "action",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			render: (row: any) => (
				<div className="flex items-center justify-end grow">
					<Icon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						icon={faArrowRight}
						onClick={() => {
							handleAddToList(row);
						}}
					/>
				</div>
			),
		},
	];

	return (
		<div className="w-full mt-8 bg-brand-white p-4 rounded-xl border border-gray-200">
			<div className="text-md mb-8">Select saved searches</div>
			<div className="w-full bg-white border border-divider rounded-xl">
				<table className="w-full">
					<thead className="border-b border-b-divider">
						{filteredSources &&
							filteredSources?.length > 0 &&
							columns.map((column) => {
								return (
									<th
										key={column.uid}
										className="text-left text-table-header text-xs px-4 items-center h-[44px]"
									>
										{column.title}
									</th>
								);
							})}
					</thead>
					<tbody>
						{filteredSources?.map((map, index) => {
							return (
								<tr key={index} className="border-b border-b-divider">
									{columns.map((column) => {
										return (
											<td
												key={column.uid}
												className="px-4 items-center h-[44px] text-sm font-medium"
											>
												{column.render(map)}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
				<div className="flex ">
					<TablePagination
						className="!pb-4 mt-0 pr-4  justify-end"
						currentPage={page}
						handleNavigateToPage={setPage}
						limit={limit}
						maxPage={data?.meta.pageCount || 1}
					/>
				</div>
			</div>
		</div>
	);
};
