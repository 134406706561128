import { FunctionComponent } from "react";

import { classNames } from "@/utils/classNames";

import { ColumnValue } from "../../../List/schema/columnValue";

export interface CustomTextColumnReadOnlyProps {
	columnValue?: ColumnValue;
}

export const CustomTextColumnReadOnly: FunctionComponent<CustomTextColumnReadOnlyProps> = ({
	columnValue,
}) => {
	const cVal = columnValue?.value;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getValue = (cVal: any) => {
		try {
			return cVal?.answer !== undefined && cVal?.answer !== null
				? cVal?.answer?.toString()
				: cVal?.title || cVal.commentary || cVal || "";
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div
			className={classNames(
				"h-[26px] min-w-[150px] cursor-pointer leading-tight rounded p-1",
				"text-left  w-full",
				"overflow-hidden text-ellipsis"
			)}
		>
			{getValue(cVal)}
		</div>
	);
};
