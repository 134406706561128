import { FunctionComponent, ReactNode } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

import { useTranslation } from "react-i18next";
import { Stack } from "@mui/system";

import { classNames } from "@/utils/classNames";

import { Button } from "../Button/Button";
import { ButtonColor } from "../Button/types";
import { Checkbox } from "../Checkbox/Checkbox";

interface ModalProps {
	children: ReactNode;
	title: string;
	subtitle?: string;
	isOpened: boolean;
	handleClose?: () => void;
	handleClear?: () => void;
	handleSave?: () => void;
	handleThirdSave?: () => void;
	handleSecondSave?: () => void;
	secondSubmitButtonText?: string;
	thirdSubmitButtonText?: string;
	isLoading?: boolean;
	submitButtonText?: string;
	submitButtonColor?: string;
	clearButtonText?: string;
	clearButtonColor?: string;
	size?: "xs" | "sm" | "md" | "lg" | "xl";
	overflow?: boolean;
	checkbox?: boolean;
	handleCheckboxClick?: () => void;
	checkboxLabel?: string;
	disableSave?: boolean;
	disableThirdSave?: boolean;
}

export const Modal: FunctionComponent<ModalProps> = ({
	title,
	subtitle,
	children,
	isOpened,
	handleClose,
	handleSave,
	handleSecondSave,
	handleThirdSave,
	checkbox,
	handleCheckboxClick,
	secondSubmitButtonText,
	thirdSubmitButtonText,
	submitButtonText,
	submitButtonColor,
	isLoading = false,
	size = "xs",
	handleClear,
	clearButtonText,
	clearButtonColor,
	overflow,
	checkboxLabel,
	disableSave,
	disableThirdSave,
}) => {
	const { t } = useTranslation();

	const cssForOverflow = overflow
		? {
				"& .MuiDialogContent-root": {
					overflow: "visible",
				},
		  }
		: {};

	return (
		<Dialog
			fullWidth
			maxWidth={size}
			open={isOpened}
			sx={{
				overflow: "visible!important",
				"& .MuiDialog-paper": {
					borderRadius: "2xl",
					overflow: "visible",
				},
				"& .MuiDialogContent-root": {
					overflowY: "auto",
				},
				...cssForOverflow,
			}}
			onAbort={handleClose}
			onClose={handleClose ? handleClose : handleSave}
		>
			<div className="text-md mx-6 mt-6 mb-1">{title}</div>
			<div className="text-sm mx-6 mt-1 mb-4">{subtitle ?? ""}</div>

			<DialogContent sx={{ pt: 2, pb: 4, height: "fit-content", overflow: "auto" }}>
				{children}
			</DialogContent>

			<DialogActions sx={{ px: 3, py: 2, overflow: "visible" }}>
				{handleCheckboxClick && typeof checkbox === "boolean" && (
					<div className="w-[150px] flex items-center">
						<Checkbox
							className="whitespace-nowrap"
							isChecked={checkbox}
							onChange={handleCheckboxClick}
						>
							{checkboxLabel}
						</Checkbox>
					</div>
				)}
				<div
					className={classNames(
						"w-full flex",
						handleClear && handleSave ? "justify-between" : "justify-end"
					)}
				>
					{handleClear && (
						<div className="w-[150px]">
							<Button
								color={
									clearButtonColor
										? (clearButtonColor as ButtonColor)
										: ButtonColor.ACTION_SECONDARY
								}
								title={clearButtonText ?? t("basics.clear")}
								onClick={handleClear}
							/>
						</div>
					)}
					<Stack alignItems="center" direction="row" flexWrap="wrap" spacing={2}>
						{handleClose && (
							<div className="w-[120px]">
								<Button
									color={ButtonColor.ACTION_SECONDARY}
									title={t("basics.cancel")}
									onClick={handleClose}
								></Button>
							</div>
						)}

						{handleSecondSave && (
							<div className="w-[220px]">
								<Button
									color={ButtonColor.ACTION_SECONDARY}
									isLoading={isLoading}
									title={secondSubmitButtonText ?? t("basics.saveChanges")}
									onClick={handleSecondSave}
								></Button>
							</div>
						)}

						{handleThirdSave && (
							<div className="w-[220px]">
								<Button
									color={ButtonColor.ACTION_SECONDARY}
									disabled={disableThirdSave}
									isLoading={isLoading}
									title={thirdSubmitButtonText ?? t("basics.saveChanges")}
									onClick={handleThirdSave}
								></Button>
							</div>
						)}

						{handleSave && (
							<div className="w-[120px]">
								<Button
									color={submitButtonColor ? (submitButtonColor as ButtonColor) : ButtonColor.BRAND}
									disabled={isLoading || disableSave}
									isLoading={isLoading}
									title={submitButtonText ?? t("basics.saveChanges")}
									onClick={handleSave}
								></Button>
							</div>
						)}
					</Stack>
				</div>
			</DialogActions>
		</Dialog>
	);
};
