import { FunctionComponent, useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import { faGear } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "@/components";
import {
	useGetProfileTrackersQuery,
	useGetTrackerQuery,
} from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { useSearch } from "@/utils";

import { Order, SortParams } from "@/types/types";

import { AutoCompleteItem } from "@/components/Dropdown/Dropdown";

import { TrackerSourceTypeElem } from "../../components/TrackerSourceTypeElem";
import { CustomTrackerAutocomplete } from "../../components/CustomTrackerAutocomplete";
import { Tracker } from "../../schema/profileTracker";
import { TrackerDetailsTable } from "../../components/TrackerDetailsTable";

export const TrackerDetails: FunctionComponent = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { setSearchValue, query } = useSearch();
	const [sortState, setSortState] = useState<SortParams | undefined>();
	const [page, setPage] = useState(1);
	const [trackerData, setTrackerData] = useState<Tracker[]>([]);
	const [trackerLoading, setTrackerLoading] = useState(false);

	const { data } = useGetTrackerQuery(id ? +id : 0);
	const {
		data: trackers,
		isLoading,
		isFetching,
	} = useGetProfileTrackersQuery({
		page,
		limit: 15,
		searchValue: query,
		sorting: sortState,
	});

	useEffect(() => {
		if (trackers) {
			setTrackerData((prevData) => [...prevData, ...trackers.data]);
			setTrackerLoading(false);
		}
	}, [trackers]);

	const handleLoadMore = () => {
		if (!isFetching && page < (trackers?.meta?.pageCount || 0)) {
			setTimeout(() => {
				setPage((prevPage) => prevPage + 1);
			}, 500);
		}
	};

	const handleSortTracker = (value: AutoCompleteItem) => {
		setTrackerLoading(true);
		setTrackerData([]);
		setPage(1);
		switch (value.id) {
			case "nameASC":
				setSortState({ sortBy: "name", sortOrder: Order.ASC });
				break;
			case "nameDESC":
				setSortState({ sortBy: "name", sortOrder: Order.DESC });
				break;
			case "leadsASC":
				setSortState({ sortBy: "newLeads", sortOrder: Order.ASC });
				break;
			case "leadsDESC":
				setSortState({ sortBy: "newLeads", sortOrder: Order.DESC });
				break;
			default:
				break;
		}
	};

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<div className="flex items-end justify-between w-full mb-7">
				<div className="flex-grow inline-flex items-center justify-between">
					<div className="flex-grow inline-flex items-center">
						<div className="text-gray-700 lg:max-w-[650px] font-medium text-sm leading-4 px-2 py-1.5 mr-3">
							{data?.sourceType && <TrackerSourceTypeElem sourceType={data?.sourceType} />}
						</div>

						<CustomTrackerAutocomplete
							currentPage={page}
							data={trackerData || []}
							floating={true}
							handleFetchMore={() => {
								handleLoadMore();
							}}
							handleSearch={(value: string) => {
								setTrackerLoading(true);
								setTrackerData([]);
								setPage(1);
								setSearchValue(value);
							}}
							handleSelect={(value?: Partial<Tracker>) => {
								if (value && value.id !== undefined) {
									navigate(`/app/trackers/detail/${value.id}`);
									setSortState(undefined);
									setSearchValue("");
									setPage(1);
									console.log("selected");
								} else {
									console.log("No value selected");
								}
							}}
							handleSortTracker={(value: AutoCompleteItem) => {
								handleSortTracker(value);
							}}
							isFetching={isFetching}
							isLoading={isLoading}
							isTrackerLoading={trackerLoading}
							label={data?.name}
							selectedItem={data?.name || ""}
							sortState={sortState}
							totalPages={trackers?.meta.pageCount || 0}
						/>
					</div>
					<Link
						className="text-sm flex items-center h-full"
						to={`/app/profile-tracker/settings/${id}`}
					>
						Settings <Icon className="w-[18px] ml-2 h-[18px]" color="#374151" icon={faGear} />
					</Link>
				</div>
			</div>
			<TrackerDetailsTable />
		</div>
	);
};
