import { ChangeEvent, FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";

import { Breadcrumb } from "@/components/Breadcrump/Breadcrump";
import { ListSourceType } from "@/enum/list.enum";

import { Button } from "@/components/Button/Button";
import { useCreateListMutation } from "@/pages/Private/redux/list/list.api";

import { InputField } from "@/components/InputField/InputField";
import { useSearch } from "@/utils";

import { Icon } from "@/components";

import { authSelector } from "@/pages/Public/redux/auth.slice";

import { useAppSelector } from "@/redux/hooks";

import { JobScrapeType } from "@/enum/job.enum";

import { Tile } from "./components/Tile";
import { createNewListSettings } from "./config/tileConfig";

export const ListCreate: FunctionComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const ts = (key: string) => t(`list.${key}`);

	const { user } = useAppSelector(authSelector);

	const createNewListSettingsWithCheck = useMemo(() => {
		if (user?.company?.hasApolloApiKey) {
			createNewListSettings[2].items[0].disabled = false;
		}

		return createNewListSettings;
	}, [user?.company?.hasApolloApiKey]);

	const [listType, setListType] = useState<ListSourceType | null>(null);

	const [name, setName] = useState<string>();

	const [createList, { isLoading }] = useCreateListMutation();

	const { query, searchValue, setSearchValue } = useSearch();

	const items = useMemo(() => {
		if (!query) {
			return createNewListSettingsWithCheck;
		}

		return createNewListSettingsWithCheck
			.map((setting) => ({
				...setting,
				items: setting.items.filter((item) =>
					item.title.toLowerCase().includes(query.toLowerCase())
				),
			}))
			.filter((setting) => setting.items.length > 0);
	}, [createNewListSettingsWithCheck, query]);

	const submit = async () => {
		if (!listType) {
			return;
		}

		const createdList = await createList({
			name,
			sourceType: listType,
			isNewCreationProcess: true,
			scrapeLevel: listType === ListSourceType.JOBS ? JobScrapeType.GET_ENRICHMENT : null,
		}).unwrap();

		if ([ListSourceType.MAPS].includes(listType)) {
			navigate(`/app/lists/create/details/${createdList.id}`);
		} else if (listType === ListSourceType.PROSPECT_SEARCH) {
			navigate(`/app/lists/create/filter/${createdList.id}`);
		} else {
			navigate(`/app/lists/create/source/${createdList.id}`);
		}
	};

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6 pb-8">
			<Breadcrumb
				path={[
					{ name: ts("title"), link: "/" },
					{ name: ts("create.title"), link: "/create" },
				]}
			/>
			<div className="text-lg mb-7">Create New List</div>
			<hr className="w-full border-divider mb-5" />
			<div className="flex flex-col gap-4 w-full mb-4">
				<div className="text-md mb-0">{ts("name")}</div>
				<div className="max-w-[400px] w-full flex">
					<InputField
						handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
							setName(event.target.value);
						}}
						name={"name"}
						placeholder={ts("namePlaceholder")}
						value={name || ""}
					/>
				</div>
			</div>
			<div className="flex justify-between w-full">
				<div className="text-md mb-8">Select lists type</div>
				<div className="max-w-[400px] w-full flex">
					<InputField
						className="w-full"
						handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
							setSearchValue(event.target.value);
						}}
						iconInside={<Icon icon={faMagnifyingGlass} />}
						name={"search"}
						placeholder="Search for a type..."
						value={searchValue}
					/>
				</div>
			</div>
			{items.map((setting, index) => (
				<div key={index} className="w-full">
					<div className="text-ssmd mb-4">{setting.title}</div>
					<div className="w-full grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
						{setting.items.map((item, index) => (
							<Tile
								key={index}
								description={item.description}
								disabled={item.disabled || false}
								icon={item.icon}
								isSelected={item.listType === listType}
								listType={item.listType}
								note={item.note || undefined}
								setListType={setListType}
								title={item.title}
							/>
						))}
					</div>
				</div>
			))}
			<div className="absolute mt-14 h-[72px] flex flex-row justify-between items-center w-auto border-t border-t-border px-8 py-[14px] rounded-t-16 bottom-0 right-0 z-20 bg-white w-full">
				<div></div>

				<div className="flex flex-row self-left items-center">
					<Button
						disabled={listType === null || !name || name?.length < 3 || isLoading}
						isLoading={isLoading}
						title={ts("create.button")}
						onClick={submit}
					/>
				</div>
			</div>
		</div>
	);
};
