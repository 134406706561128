/* eslint-disable @typescript-eslint/no-explicit-any */
import { faFolder, faCoin } from "@fortawesome/pro-regular-svg-icons";
import { Autocomplete, Tab, TextField } from "@mui/material";

import { format } from "date-fns";

import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Transforms, Editor, BaseEditor, Descendant } from "slate";

import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";

import { Link } from "react-router-dom";

import { TabContext, TabList } from "@mui/lab";

import suggestionsData from "@/pages/Private/pages/ProfileTracker/utils/suggestions";

import { Icon, Modal } from "@/components";
import { useHasPermissions } from "@/utils/useHasPermissions";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import PromptEditor from "@/pages/Private/pages/List/components/PromptEditor";
import { AiApplyType, CustomColumnFrequency, CustomColumnTypes } from "@/enum/list.enum";
import { PermissionRoles } from "@/enum";
import { TextArea } from "@/components/TextArea/TextArea";
import { InputField } from "@/components/InputField/InputField";
import { Dropdown, AutoCompleteItem } from "@/components/Dropdown/Dropdown";
import { ButtonSize, ButtonColor } from "@/components/Button/types";
import { profileTrackerSelector } from "@/pages/Private/redux/profileTracker/profileTracker.slice";
import { useAppSelector } from "@/redux/hooks";
import {
	useAddColumnToTrackerMutation,
	useRunAiPromptMutation,
	useRunLinkedinPostSearchMutation,
} from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { Button } from "@/components/Button/Button";

import { Radio } from "@/components/Radio/Radio";

import { ColumnConfig } from "@/pages/Private/pages/List/schema/columnConfig";

import { Toggle } from "@/components/Toggle/Toggle";

import { classNames } from "@/utils/classNames";

import { CustomColumn, CustomColumnSchema } from "../../../schema/trackerColumn";

import { getSuggestions, replaceWithRealValues } from "../../../utils/colum-utils";
import { LiCompanyActivitiesInterface } from "../../../schema/li-company-activity-interface";
import { useOrganizationSlider } from "../../../utils/useOrganizationSlider";

export interface LinkedinPostModalProps {
	setShowConfirm: (value: boolean) => void;
	open: boolean;
	trackerId: number;
	setOpen: (value: boolean) => void;
	columnId?: number;
	column?: ColumnConfig;
	customColumnName?: string;
}

enum Tabs {
	POSTS = "POSTS",
	AI_PROCESSING = "AI_PROCESSING",
	SETTINGS = "SETTINGS",
}

export const LinkedinPostModal: FunctionComponent<LinkedinPostModalProps> = ({
	setShowConfirm,
	trackerId,
	open,
	setOpen,
	columnId,
	column,
	customColumnName,
}) => {
	const { hasPermissions } = useHasPermissions();

	const [searchEditorValue, setSearchEditorValue] = useState<Descendant[]>();
	const [searchEditor, setSearchEditor] = useState<BaseEditor>();
	const [editorValue, setEditorValue] = useState<Descendant[]>();
	const [editor, setEditor] = useState<BaseEditor>();
	const [aiResult, setAiResult] = useState<string>();
	const [searchResult, setSearchResult] = useState<LiCompanyActivitiesInterface>();
	const [frequency, setFrequency] = useState<CustomColumnFrequency>();
	const [sumUpScoreOverTime, setSumUpScoreOverTime] = useState<boolean>(false);

	const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.POSTS);

	const {
		currentOrganization,
		setCurrentOrganization,
		renderOrganizationSlider,
		currentPerson,
		renderPersonSlider,
	} = useOrganizationSlider();

	const [selectedSearchVariable, setSelectedSearchVariable] = useState<
		{ id: string; name: string } | undefined
	>();
	const [selectedVariable, setSelectedVariable] = useState<
		{ id: string; name: string } | undefined
	>();

	const [updateOrOnce, setUpdateOrOnce] = useState<boolean>(false);

	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`aiColumn.${key}`), [t]);

	const [update, { isLoading, error }] = useAddColumnToTrackerMutation();
	const [runPrompt, { isLoading: promptIsLoading }] = useRunAiPromptMutation();
	const [runSearch, { isLoading: searchIsLoading }] = useRunLinkedinPostSearchMutation();

	const { records } = useAppSelector(profileTrackerSelector);

	const tabs = [
		{ label: ts("tabs.posts"), value: Tabs.POSTS },
		{ label: `${ts("tabs.aiProcessing")} (optional)`, value: Tabs.AI_PROCESSING },
		{ label: ts("tabs.settings"), value: Tabs.SETTINGS },
	];

	const suggestions = useMemo(() => {
		return !!records[currentOrganization]
			? getSuggestions(
					records[currentOrganization],
					records[currentOrganization]?.trackerPersons?.[currentPerson]
			  )
			: [];
	}, [currentOrganization, currentPerson, records]);

	const getSuggestionLabel = (key: string) => {
		return suggestionsData.suggestions[key as keyof typeof suggestionsData.suggestions] as string;
	};

	const handleRunSearch = async () => {
		const result = await runSearch({
			id: records[currentOrganization].id,
			prompt: replaceWithRealValues({
				value: searchEditorValue,
				inboxItem: records[currentOrganization],
				suggestions,
				person: records[currentOrganization]?.trackerPersons?.[currentPerson],
			}),
			online: true,
			prospectId: records[currentOrganization]?.trackerPersons?.[currentPerson]?.prospectId,
		}).unwrap();

		if (result?.data) {
			setSearchResult(result?.data);
		}
	};

	const {
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CustomColumn>({
		defaultValues: {
			customColumnType: CustomColumnTypes.LINKEDIN_POST,
			online: false,
			...column,
			customColumnName: customColumnName || column?.name,
			value: column?.config,
		},
		resolver: zodResolver(CustomColumnSchema),
	});

	const handleRunPromptWithSearchResult = async () => {
		const result = await runPrompt({
			id: records[currentOrganization].id,
			prompt: `Here ${getValues("single") ? "is" : "are"} the linkedin post(s): ${JSON.stringify(
				getValues("single")
					? searchResult?.posts?.[0].commentary
					: searchResult?.posts.map((post) => post.commentary)
			)}
			
			${replaceWithRealValues({
				value: editorValue,
				inboxItem: records[currentOrganization],
				suggestions,
				person: records[currentOrganization]?.trackerPersons?.[currentPerson],
			})} `,
			online: true,
		}).unwrap();

		setAiResult(result.data);
	};

	useEffect(() => {
		if (column) {
			if (column.frequency) {
				setFrequency(column.frequency);
			}

			if (column.sumUpScoreOverTime) {
				setSumUpScoreOverTime(column.sumUpScoreOverTime);
			}

			if (column.updateOrOnce) {
				setUpdateOrOnce(column.updateOrOnce);
			}

			setValue("customColumnName", column.name);
			setValue("value", column.config);
			setValue("sourceConfig", column.sourceConfig);
			setValue("score", column.score);
			setValue("frequency", column.frequency);
			setValue("sumUpScoreOverTime", column.sumUpScoreOverTime);

			setCurrentOrganization(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [column]);

	const serialize = (nodes: any) => {
		return JSON.stringify(nodes);
	};

	const onSubmitSaveOnly = async (values: CustomColumn) => {
		try {
			values.value = editorValue ? serialize(editorValue) : "";
			values.sourceConfig = editorValue ? serialize(searchEditorValue) : "";

			await update({
				...values,
				aiApplyType: AiApplyType.NONE,
				id: trackerId,
				trackerOrganizationIds: records.slice(0, 10).map((p) => p.id),
				updateOrOnce,
				frequency,
				sumUpScoreOverTime,
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const onSubmit = async (values: CustomColumn) => {
		try {
			values.value = editorValue ? serialize(editorValue) : "";
			values.sourceConfig = editorValue ? serialize(searchEditorValue) : "";

			await update({
				...values,
				id: trackerId,
				updateOrOnce,
				frequency,
				sumUpScoreOverTime,
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const onSubmitFirst10 = async (values: CustomColumn) => {
		try {
			values.value = editorValue ? serialize(editorValue) : "";
			values.sourceConfig = editorValue ? serialize(searchEditorValue) : "";

			await update({
				...values,
				aiApplyType: AiApplyType.FIRST_10,
				id: trackerId,
				trackerOrganizationIds: records.slice(0, 10).map((p) => p.id),
				updateOrOnce,
				frequency,
				sumUpScoreOverTime,
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const handleTabsChange = useCallback((_: ChangeEvent<{}>, value: Tabs): void => {
		setCurrentTab(value);
	}, []);

	return (
		<Modal
			handleClose={() => {
				setShowConfirm(true);
			}}
			handleSave={
				currentTab === Tabs.SETTINGS
					? handleSubmit(onSubmit)
					: () => setCurrentTab(currentTab === Tabs.POSTS ? Tabs.AI_PROCESSING : Tabs.SETTINGS)
			}
			handleSecondSave={handleSubmit(onSubmitSaveOnly)}
			handleThirdSave={currentTab === Tabs.SETTINGS ? handleSubmit(onSubmitFirst10) : undefined}
			isLoading={isLoading}
			isOpened={open}
			secondSubmitButtonText={t("basics.save")}
			size="lg"
			submitButtonText={currentTab === Tabs.SETTINGS ? ts("applyToAll") : "Next"}
			thirdSubmitButtonText={ts("applyTo10")}
			title="Create Linkedin Post Column"
		>
			<TabContext value={currentTab}>
				<TabList aria-label="Account tabs" sx={{ mb: 2.5 }} onChange={handleTabsChange}>
					{tabs.map((tab, index) => (
						<Tab
							key={index * 2}
							label={
								<div
									className={classNames(
										"flex leading-[28px]",
										currentTab === tab.value ? " px-7" : ""
									)}
								>
									{tab.label}
								</div>
							}
							sx={{ py: 1.25 }}
							value={tab.value}
						/>
					))}
				</TabList>

				<div className={classNames(Tabs.POSTS === currentTab ? "block" : "hidden")}>
					<div className="flex flex-col items-start">
						<div className="flex relative flex-row w-full items-start gap-4">
							<div className="w-2/3">
								<div className="text-ssm font-medium mt-2 mb-2">
									Searchterm for linkedin post search
								</div>
								<div className="flex relative flex-col border border-gray-300 rounded-xl">
									<div className="w-full min-h-[120px] ">
										<PromptEditor
											getSuggestionLabel={getSuggestionLabel}
											handleChange={(value) => {
												setSearchEditor(value);
											}}
											handleValueChange={(value) => {
												setSearchEditorValue(value);
											}}
											suggestions={suggestions}
											value={column?.sourceConfig ? JSON.parse(column?.sourceConfig) : undefined}
										/>
									</div>
									<div className="p-2 w-full flex border-t border-gray-300 justify-between items-center">
										<div className="flex items-center">
											<Autocomplete
												getOptionLabel={(option: { name: string; id: string }) => option.name}
												id="event"
												options={
													suggestions
														?.filter((key) => {
															const label = getSuggestionLabel(key);

															return label && label !== key;
														})
														?.map((key) => ({
															name: getSuggestionLabel(key) as string,
															id: key,
														})) || []
												}
												renderInput={(params) => {
													return <TextField {...params} placeholder="Variable" />;
												}}
												size="small"
												sx={{
													width: "220px",
													"& .MuiFilledInput-root": {
														paddingTop: "4px!important",
														paddingBottom: "4px!important",
														marginBottom: "0px!important",
													},
													"&.MuiFormControl-root": {
														height: "40px",
													},
												}}
												value={selectedSearchVariable}
												onChange={(event, value) => {
													if (value && searchEditor) {
														// eslint-disable-next-line @typescript-eslint/no-explicit-any
														const [firstChild, ...otherChildren] = searchEditor.children as any;

														const newChildren = [
															...(firstChild.children || []),
															{
																type: "mention",
																character: `${value.id}`,
																children: [
																	{
																		text: ``,
																	},
																],
															},
															{
																text: ``,
															},
														];

														const newVal = [{ children: newChildren }, ...otherChildren];

														Transforms.delete(searchEditor as any, {
															at: {
																anchor: Editor.start(searchEditor as any, []),
																focus: Editor.end(searchEditor as any, []),
															},
														});

														Transforms.removeNodes(searchEditor as any, {
															at: [0],
														});

														// Insert array of children nodes
														Transforms.insertNodes(searchEditor as any, newVal);

														setSelectedSearchVariable(undefined);
													}
												}}
											/>
											<div className="ml-2 text-sm text-gray-700">
												or enter{" "}
												<span className="bg-inactive-item px-1 border border-gray-200 rounded-md">
													{"{"}
												</span>{" "}
												to insert variable
											</div>
										</div>
										<div className="flex justify-end">
											<div className="max-w-[120px]">
												<Button
													isLoading={searchIsLoading}
													size={ButtonSize.S}
													title="Search for posts"
													onClick={handleRunSearch}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="flex flex-col grow mr-2">
								<div className="text-ssm font-medium mb-2 mt-2">Search term preview</div>
								<TextArea
									className="!mb-0 grow mr-4 !min-h-[175px]"
									name={"randomValue"}
									placeholder="Enter search term on the left"
									showError={false}
									value={
										replaceWithRealValues({
											value: searchEditorValue,
											inboxItem: records[currentOrganization],
											suggestions,
											person: records[currentOrganization]?.trackerPersons?.[currentPerson],
										}) || ""
									}
								/>
							</div>
						</div>
					</div>
					{searchResult?.posts && searchResult.posts.length > 0 && (
						<div className="flex flex-row">
							<div className="flex flex-col grow">
								<div className="text-ssm font-medium mt-4 mb-2">Prompt result preview</div>
								<div className="flex flex-col grow mr-2 max-h-[400px] overflow-y-auto  w-full">
									{searchResult?.posts.map((item, index) => (
										<div
											key={`items-linkedin-posts-${index * 2}`}
											className="flex flex-row items-start gap-4 px-4 py-4 rounded-xl border border-gray-200 mb-4 w-full"
										>
											<div className="flex flex-col items-start w-full">
												<div className="flex flex-row justify-between w-full">
													<div className="flex flex-row items-center mb-2">
														{item.actor?.actor_image && (
															<img
																alt="news"
																className="h-[40px] mr-2 rounded-full"
																referrerPolicy="no-referrer"
																src={
																	item.actor?.actor_image?.includes("http")
																		? item.actor.actor_image
																		: records[currentOrganization].organization.logoUrl || ""
																}
															/>
														)}
														<div className="text-ssm">
															{item.actor.actor_navigationContext && (
																<Link to={item.actor.actor_navigationContext}>
																	{item.actor?.actor_name}
																</Link>
															)}
														</div>
													</div>
													<div className="text-ssm">
														{format(new Date(item.postedAt), "dd.MM.yyyy")}
													</div>
												</div>
												<div className="text-sm">{item.commentary}</div>
												<a
													className="text-sm cursor-pointer underline"
													href={item.share_url}
													rel="noreferrer"
													target="_blank"
												>
													Read more...
												</a>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					)}
					{renderPersonSlider(setAiResult, records)}
					{renderOrganizationSlider(setAiResult, records)}
				</div>

				<div className={classNames(Tabs.AI_PROCESSING === currentTab ? "block" : "hidden")}>
					<div className="flex flex-col items-start">
						<div className="flex flex-row justify-between w-full">
							<div className="text-ssm font-medium mt-2 mb-1">Prompt editor</div>
							<div className="flex flex-row gap-2">
								<div className="text-ssm font-medium mt-2 mb-1 flex items-center">
									<Toggle
										isChecked={getValues("single") || false}
										onChange={() => {
											setValue("single", !getValues("single"), {
												shouldValidate: true,
												shouldDirty: true,
											});
										}}
									/>{" "}
									<span className="ml-2">Process each article separately</span>
								</div>
							</div>
						</div>
						<div className="flex relative flex-col w-full border border-gray-300 rounded-xl">
							<div className="w-full min-h-[120px] ">
								<PromptEditor
									getSuggestionLabel={getSuggestionLabel}
									handleChange={(value) => {
										setEditor(value);
									}}
									handleValueChange={(value) => {
										setEditorValue(value);
									}}
									suggestions={suggestions}
									value={column?.config ? JSON.parse(column?.config) : undefined}
								/>
							</div>
							<div className="p-2 w-full flex border-t border-gray-300 justify-between items-center">
								<div className="flex items-center">
									<div className="mr-2 text-sm text-gray-700">Select</div>
									<Autocomplete
										getOptionLabel={(option: { name: string; id: string }) => option.name}
										id="event"
										options={
											suggestions
												?.filter((key) => {
													const label = getSuggestionLabel(key);

													return label && label !== key;
												})
												?.map((key) => ({
													name: getSuggestionLabel(key) as string,
													id: key,
												})) || []
										}
										renderInput={(params) => {
											return (
												<TextField
													// sx={{
													// 	height: "40px",
													// }}
													{...params}
													placeholder="Variable"
												/>
											);
										}}
										size="small"
										sx={{
											width: "220px",
											"& .MuiFilledInput-root": {
												paddingTop: "4px!important",
												paddingBottom: "4px!important",
												marginBottom: "0px!important",
											},
											"&.MuiFormControl-root": {
												height: "40px",
											},
										}}
										value={selectedVariable}
										onChange={(event, value) => {
											if (value && editor) {
												// eslint-disable-next-line @typescript-eslint/no-explicit-any
												const [firstChild, ...otherChildren] = editor.children as any;

												const newChildren = [
													...(firstChild.children || []),
													{
														type: "mention",
														character: `${value.id}`,
														children: [
															{
																text: ``,
															},
														],
													},
													{
														text: ``,
													},
												];

												const newVal = [{ children: newChildren }, ...otherChildren];

												Transforms.delete(editor as any, {
													at: {
														anchor: Editor.start(editor as any, []),
														focus: Editor.end(editor as any, []),
													},
												});

												Transforms.removeNodes(editor as any, {
													at: [0],
												});

												// Insert array of children nodes
												Transforms.insertNodes(editor as any, newVal);

												setSelectedVariable(undefined);
											}
										}}
									/>
									<div className="ml-2 text-sm text-gray-700">
										or enter{" "}
										<span className="bg-inactive-item px-1 border border-gray-200 rounded-md">
											{"{"}
										</span>{" "}
										to insert variable
									</div>
								</div>
								<div className="w-1/2 flex justify-end">
									<div className="w-1/2 mr-2 max-w-[150px]">
										<Button
											color={ButtonColor.DISABLED}
											disabled={true}
											image={<Icon className="mr-2" icon={faFolder} />}
											size={ButtonSize.S}
											title="Prompt library"
										/>
									</div>
									<div className="w-1/2 max-w-[110px]">
										<Button
											disabled={
												!replaceWithRealValues({
													value: editorValue,
													inboxItem: records[currentOrganization],
													suggestions,
													person: records[currentOrganization]?.trackerPersons?.[currentPerson],
												})
											}
											isLoading={promptIsLoading}
											size={ButtonSize.S}
											title="Run prompt"
											onClick={handleRunPromptWithSearchResult}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="flex flex-row">
						<div className="flex flex-col grow mr-2">
							<div className="text-ssm font-medium mt-4 mb-2">Prompt preview</div>
							<TextArea
								className="!mb-0 grow mr-4 max-h-[192px]"
								name={"randomValue"}
								placeholder="Enter value"
								showError={false}
								value={
									replaceWithRealValues({
										value: editorValue,
										inboxItem: records[currentOrganization],
										suggestions,
										person: records[currentOrganization]?.trackerPersons?.[currentPerson],
									}) || ""
								}
							/>
						</div>
						<div className="flex flex-col grow ml-2">
							<div className="text-ssm font-medium mt-4 mb-2">Prompt result preview</div>
							<TextArea
								className="!mb-0 grow mr-4 max-h-[192px]"
								name={"randomValue"}
								showError={false}
								value={aiResult || ""}
							/>
						</div>
					</div>
					{renderPersonSlider(setAiResult, records)}
					{renderOrganizationSlider(setAiResult, records)}
				</div>

				<div className={classNames(Tabs.SETTINGS === currentTab ? "block" : "hidden")}>
					<div className="text-ssm font-medium mb-2">
						<InputField
							error={!!errors.customColumnName}
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								setValue("customColumnName", event.target.value, {
									shouldValidate: true,
									shouldDirty: true,
								});
							}}
							label="Column Name"
							name={"name"}
							placeholder="Enter column name"
							value={getValues("customColumnName")}
						/>
					</div>
					<div className="flex flex-col mt-5 border-t pt-5 border-gray-200">
						<div className="flex  gap-4 flex-col text-gray-700 items-center">
							<Dropdown
								data={[
									{
										title: ts(CustomColumnFrequency.ONCE),
										id: CustomColumnFrequency.ONCE,
									},
									{
										title: ts(CustomColumnFrequency.DAILY),
										id: CustomColumnFrequency.DAILY,
									},
									{
										title: ts(CustomColumnFrequency.WEEKLY),
										id: CustomColumnFrequency.WEEKLY,
									},
									{
										title: ts(CustomColumnFrequency.MONTHLY),
										id: CustomColumnFrequency.MONTHLY,
									},
									{
										title: ts(CustomColumnFrequency.QUATERLY),
										id: CustomColumnFrequency.QUATERLY,
									},
									{
										title: ts(CustomColumnFrequency.HALFYEARLY),
										id: CustomColumnFrequency.HALFYEARLY,
									},
									{
										title: ts(CustomColumnFrequency.YEARLY),
										id: CustomColumnFrequency.YEARLY,
									},
								]}
								defaultValue={
									getValues("frequency") && {
										title: ts(getValues("frequency") as CustomColumnFrequency),
										id: getValues("frequency") as CustomColumnFrequency,
									}
								}
								error={!!errors.frequency?.message}
								floating={true}
								handleSelect={function (value?: AutoCompleteItem) {
									if (value?.id) {
										setValue("frequency", value.id as CustomColumnFrequency);
										setFrequency(value.id as CustomColumnFrequency);
									}
								}}
								label="Execution frequency"
							/>
						</div>
						<div className="flex flex-col grow mr-2">
							<div className="text-ssm font-medium mt-4 mb-2">Update frequency</div>
							<div className="flex flex-col gap-4">
								<Radio
									className="whitespace-nowrap"
									isChecked={updateOrOnce}
									onChange={() => {
										setUpdateOrOnce(!updateOrOnce);
									}}
								>
									{ts("updateOnEachExecution")}
								</Radio>

								<Radio
									className="whitespace-nowrap"
									isChecked={!updateOrOnce}
									onChange={() => {
										setUpdateOrOnce(!updateOrOnce);
									}}
								>
									{ts("onceAfterFindingAValue")}
								</Radio>
							</div>
						</div>
					</div>
					<div className="flex flex-col mt-5 border-t pt-5 border-gray-200">
						<div className="flex flex-col grow mr-2">
							<div className="text-ssm font-medium mb-2">Scoring</div>
							<div className="flex flex-col gap-4">
								<InputField
									error={errors.score?.message}
									handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
										setValue("score", +event.target.value, {
											shouldValidate: true,
											shouldDirty: true,
										});
									}}
									name={"score"}
									placeholder="Enter score"
									type="number"
									value={getValues("score")?.toString() || ""}
								/>
							</div>
						</div>
						<div className="flex flex-col grow mr-2 mb-4">
							<div className="text-ssm font-medium mt-4 mb-2">Sum up score over time</div>
							<div className="flex flex-col gap-4">
								<Radio
									className="whitespace-nowrap"
									isChecked={sumUpScoreOverTime}
									onChange={() => {
										setSumUpScoreOverTime(!sumUpScoreOverTime);
									}}
								>
									{ts("sumUpScoreOverTime")}
								</Radio>

								<Radio
									className="whitespace-nowrap"
									isChecked={!sumUpScoreOverTime}
									onChange={() => {
										setSumUpScoreOverTime(!sumUpScoreOverTime);
									}}
								>
									{ts("doNotSumUpScoreOverTime")}
								</Radio>
							</div>
						</div>
					</div>
				</div>
			</TabContext>

			{hasPermissions([PermissionRoles.GLOBAL_ADMIN]) && (
				<div className="flex flex-row border-t pt-5 border-gray-200 text-gray-700 items-center text-sm">
					<span className="mr-1">2x</span>
					<Icon className="w-[16px] h-[16px] text-gray-700 mr-1" icon={faCoin} />
					<span className="">per result</span>
				</div>
			)}
			{getAllErrors(error, formErrors).length ? (
				<div className="mt-4 mb-0">{renderErrorMessages(getAllErrors(error, formErrors))}</div>
			) : null}
		</Modal>
	);
};
