import { CSSProperties, forwardRef, HTMLAttributes } from "react";

import { faGripDotsVertical } from "@fortawesome/pro-regular-svg-icons";

import { classNames } from "@/utils/classNames";

import eye from "@assets/icons/eye.svg";
import lock from "@assets/icons/lock.svg";
import unlocked from "@assets/icons/unlocked.svg";
import settingsWheel from "@assets/icons/settingsWheel.svg";
import trashIcon from "@assets/icons/trashIcon.svg";

import eyeSlash from "@assets/icons/eye-slash.svg";

import { TItem } from "./SortableList";
import { Icon } from "../Icon/Icon";

type Props = {
	item: TItem;
	isOpacityEnabled?: boolean;
	isDragging?: boolean;
	handleShowToggle?: (uid: string) => void;
	handleEdit?: (uid: string) => void;
	handleDelete?: (uid: string) => void;
} & HTMLAttributes<HTMLDivElement>;

// eslint-disable-next-line react/display-name
const Item = forwardRef<HTMLDivElement, Props>(
	(
		{
			item,
			handleShowToggle,
			handleEdit,
			handleDelete,
			isOpacityEnabled,
			isDragging,
			style,
			...props
		},
		ref
	) => {
		const styles: CSSProperties = {
			...style,
		};

		return (
			<div
				ref={ref}
				className={classNames(
					"flex flex-row items-center p-2 space-x-2 bg-white border border-gray-200 rounded-xl mb-4 px-4 py-3",
					isOpacityEnabled ? "opacity-40" : "",
					isDragging ? "cursor-grabbing" : "",
					isDragging ? "transform scale-105" : "transform scale-100"
				)}
				style={{ ...styles }}
			>
				<div {...props}>
					<Icon
						className={classNames("mr-3", item.locked ? "" : "cursor-grab")}
						color={item.locked ? "gray" : "primary"}
						icon={faGripDotsVertical}
					/>
				</div>
				<div className="flex flex-row">
					<button
						className={classNames(
							"mr-1  p-1 rounded-lg",
							item.show && item.hideable ? "bg-active-item" : "bg-inactive-item"
						)}
						onClick={() => item.hideable && handleShowToggle?.(item.uid)}
					>
						{item.show ? <img alt="eye" src={eye} /> : <img alt="eye" src={eyeSlash} />}
					</button>
					<div
						className={classNames(
							"mr-1  p-1 rounded-lg",
							item.locked && item.moveable ? "bg-active-item" : "bg-inactive-item"
						)}
					>
						{item.locked ? <img alt="lock" src={lock} /> : <img alt="unlocked" src={unlocked} />}
					</div>
				</div>
				<div className="flex grow items-center">
					{item.content}
					{item.svgIcon && <img className="text-gray-500 ml-2 h-[18px]" src={item.svgIcon} />}
				</div>

				{item.custom && (
					<div className="flex flex-row items-center ml-auto">
						{handleEdit && (
							<button className="mr-2" onClick={() => handleEdit(item.uid)}>
								<img alt="settingsWheel" className="cursor-pointer" src={settingsWheel} />
							</button>
						)}
						{handleDelete && (
							<button onClick={() => handleDelete(item.uid)}>
								<img alt="delete" className="cursor-pointer" src={trashIcon} />
							</button>
						)}
					</div>
				)}
			</div>
		);
	}
);

export default Item;
