import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";

import { useSearch } from "@/utils";
import { useLazyGeSuggestionQuery } from "@/pages/Private/redux/liPeople/liPeople.api";
import { classNames } from "@/utils/classNames";

export interface LinkedinParams {
	title: string;
	urn: string;
}

interface LinkedinTagAutocompleteProps {
	value?: LinkedinParams | null;
	type: string;
	handleValueChange: (value: LinkedinParams | null) => void;
	isDisabled?: boolean;
}

export const LinkedinTagAutocompleteSingle: FunctionComponent<LinkedinTagAutocompleteProps> = ({
	value,
	type,
	handleValueChange,
	isDisabled,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liPeople.${key}`);
	const { query, setSearchValue } = useSearch();
	const [inputValue, setInputValue] = useState<string>();
	const [options, setOptions] = useState<LinkedinParams[]>([]);
	const [defaults, setDefaults] = useState<LinkedinParams>();
	const [fetchTags] = useLazyGeSuggestionQuery();

	useEffect(() => {
		setInputValue(query);
	}, [query, type]);

	useEffect(() => {
		if (value) {
			setDefaults(value);
		}
	}, [value]);

	useEffect(() => {
		if (inputValue) {
			fetchTags({ type, keyword: inputValue }).then((results) => {
				const optionValues = results?.data?.suggestions;

				if (optionValues) {
					setOptions([...optionValues]);
				}
			});
		}
	}, [inputValue, fetchTags, type]);

	return (value && defaults) || !value ? (
		<div className={classNames(isDisabled ? "cursor-not-allowed opacity-[0.5]" : "cursor-pointer")}>
			<Autocomplete
				blurOnSelect={true}
				disabled={isDisabled}
				getOptionLabel={(option) => option.title}
				id={type}
				options={options}
				renderInput={(params) => <TextField {...params} label={ts(`${type}.label`)} />}
				size="medium"
				sx={{
					width: "100%",
				}}
				value={value}
				onChange={(event, value, reason) => {
					if (reason === "clear") {
						handleValueChange(null);
						setSearchValue("");
						setInputValue("");

						return;
					}

					if (value) {
						handleValueChange(value);
					} else {
						handleValueChange(null);
						setInputValue("");
						setSearchValue("");
					}
				}}
				onInputChange={(event, newInputValue) => {
					setSearchValue(newInputValue);
				}}
			/>
		</div>
	) : (
		<></>
	);
};
