import { FunctionComponent } from "react";

import { CircularProgress } from "@mui/material";

import { CustomColumnFrequency, CustomColumnTypes } from "@/enum/list.enum";
import { classNames } from "@/utils/classNames";

import { useUpdateColumnValueMutation } from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { TrackerOrganization } from "../../schema/trackerOrganizations";
import { ColumnConfig } from "../../../List/schema/columnConfig";
import { ColumnValue } from "../../../List/schema/columnValue";

export interface CustomTextColumnProps {
	showEdit?: { id: number; name: string };
	columnValue?: ColumnValue;
	columnConfig: ColumnConfig;
	row: TrackerOrganization;
	customColumnTextValue?: string;
	setCustomColumnTextValue: (value: string | undefined) => void;
	setShowEdit: (value: { id: number; name: string }) => void;
	type: CustomColumnTypes;
}

export const CustomTextColumn: FunctionComponent<CustomTextColumnProps> = ({
	showEdit,
	columnValue,
	columnConfig,
	row,
	customColumnTextValue,
	setCustomColumnTextValue,
	setShowEdit,
	type,
}) => {
	const [updatePerson, { isLoading }] = useUpdateColumnValueMutation();

	try {
		if (isLoading) {
			return <CircularProgress />;
		}

		if (showEdit?.id === row.id && showEdit?.name === columnConfig.name) {
			return (
				<textarea
					autoFocus
					className={classNames(
						"sm:shadow-mb-card sm:border-none",
						"input-styles",
						"placeholder-placeholder",
						"text-ssm font-normal",
						"py-3 px-4 w-full"
					)}
					name={columnConfig.name}
					rows={1}
					style={{
						resize: "none",
						overflow: "hidden",
						minHeight: "auto",
						minWidth: "150px",
					}}
					value={customColumnTextValue || ""}
					onBlur={() => setShowEdit({ id: 0, name: "" })}
					onChange={(event) => setCustomColumnTextValue(event.target.value)}
					onFocus={(e: React.FormEvent<HTMLTextAreaElement>) => {
						const target = e.target as HTMLTextAreaElement;

						target.style.height = "auto";
						target.style.height = `${target.scrollHeight}px`;

						const length = target.value.length;

						target.setSelectionRange(length, length);
					}}
					onInput={(e: React.FormEvent<HTMLTextAreaElement>) => {
						const target = e.target as HTMLTextAreaElement;

						target.style.height = "auto";
						target.style.height = `${target.scrollHeight}px`;
					}}
					onKeyDown={async (e) => {
						if (e.key === "Enter" && !e.shiftKey && customColumnTextValue) {
							e.preventDefault();
							await updatePerson({
								id: row.id,
								columnId: columnConfig.id,
								customColumnName: columnConfig.name,
								value: customColumnTextValue,
								customColumnType: type,
								frequency: CustomColumnFrequency.ONCE,
							}).unwrap();
							setCustomColumnTextValue(undefined);
							setShowEdit({ id: 0, name: "" });
						}
					}}
				/>
			);
		} else {
			let cVal = columnValue?.value;

			if (isLoading) {
				cVal = customColumnTextValue || cVal;
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const getValue = (cVal: any) => {
				try {
					return cVal?.answer !== undefined && cVal?.answer !== null
						? cVal?.answer?.toString()
						: cVal?.title || cVal?.commentary || cVal || "";
				} catch (e) {
					console.log(e);
				}
			};

			return (
				<button
					className="w-full"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();

						setCustomColumnTextValue(
							cVal?.answer !== undefined ? cVal?.answer?.toString() : cVal?.title || cVal || ""
						);

						setShowEdit({ id: row.id, name: columnConfig.name });
					}}
				>
					{
						<div
							className={classNames(
								"h-[26px] min-w-[150px] cursor-pointer hover:bg-brand-light hover:opacity-[0.7] leading-tight hover:text-white rounded p-1",
								"text-left  w-full",
								"overflow-hidden text-ellipsis"
							)}
						>
							{getValue(cVal)}
						</div>
					}
				</button>
			);
		}
	} catch (e) {
		console.log(e);

		return <></>;
	}
};
