import { TrackerSourceType } from "@/enum/tracker.enum";

import { Tracker } from "../schema/profileTracker";

export type Step = "edit" | "settings";

export const reducedSteps = ["settings"];

export const normalSteps = ["edit", "settings"];

export const getAvailableSteps = (tracker?: Tracker) => {
	if (tracker?.sourceType === TrackerSourceType.EMPTY) {
		return reducedSteps;
	}

	return normalSteps;
};

export const getDoneSteps = (data: Tracker) => {
	const doneSteps: Step[] = [];

	if (data.sourceType === TrackerSourceType.XLSX && data.trackerFiles.length > 0) {
		doneSteps.push("edit");
	}

	if (doneSteps.includes("edit")) {
		doneSteps.push("settings");
	}

	return doneSteps;
};
