import { ChangeEvent, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { Breadcrumb } from "@/components/Breadcrump/Breadcrump";

import { Button } from "@/components/Button/Button";

import { InputField } from "@/components/InputField/InputField";

import { TrackerSourceType } from "@/enum/tracker.enum";

import { useCreateTrackerMutation } from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { Tile } from "./components/Tile";
import { createNewListSettings } from "./config/tileConfig";

export const TrackerCreate: FunctionComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const ts = (key: string) => t(`profileTracker.${key}`);

	const [listType, setListType] = useState<TrackerSourceType | null>(null);

	const [name, setName] = useState<string>();

	const [createTracker, { isLoading }] = useCreateTrackerMutation();

	const submit = async () => {
		if (!listType) {
			return;
		}

		const createdTracker = await createTracker({
			name: name || "",
			sourceType: listType,
		}).unwrap();

		if (createdTracker.sourceType === TrackerSourceType.EMPTY) {
			navigate(`/app/profile-tracker/settings/${createdTracker.id}`);
		} else {
			navigate(`/app/profile-tracker/edit/${createdTracker.id}`);
		}
	};

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6 pb-8">
			<Breadcrumb
				path={[
					{ name: ts("title"), link: "/" },
					{ name: ts("create.title"), link: "/create" },
				]}
			/>
			<div className="text-lg mb-7">Create New Tracker</div>
			<hr className="w-full border-divider mb-5" />
			<div className="flex flex-col gap-4 w-full mb-4">
				<div className="text-md mb-0">{ts("name")}</div>
				<div className="max-w-[400px] w-full flex">
					<InputField
						handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
							setName(event.target.value);
						}}
						name={"name"}
						placeholder={ts("namePlaceholder")}
						value={name || ""}
					/>
				</div>
			</div>
			<div className="flex justify-between w-full">
				<div className="text-md mb-8">Select lists type</div>
			</div>
			{createNewListSettings.map((setting, index) => (
				<div key={index} className="w-full">
					<div className="w-full grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
						{setting.items.map((item, index) => (
							<Tile
								key={index}
								description={item.description}
								disabled={item.disabled || false}
								icon={item.icon}
								iconElement={item.iconElement}
								isSelected={item.listType === listType}
								listType={item.listType}
								note={item.note || undefined}
								setListType={setListType}
								title={item.title}
							/>
						))}
					</div>
				</div>
			))}
			<div className="absolute mt-14 h-[72px] flex flex-row justify-between items-center w-auto border-t border-t-border px-8 py-[14px] rounded-t-16 bottom-0 right-0 z-20 bg-white w-full">
				<div></div>

				<div className="flex flex-row self-left items-center">
					<Button
						disabled={listType === null || !name || name?.length < 3 || isLoading}
						isLoading={isLoading}
						title={ts("create.button")}
						onClick={submit}
					/>
				</div>
			</div>
		</div>
	);
};
