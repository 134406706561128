import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { TrackerOverview } from "./pages/TrackerOverview/TrackerOverview";
import { TrackerCreate } from "./pages/TrackerCreate/TrackerCreate";
import { TrackerEdit } from "./pages/TrackerEdit/TrackerEdit";
import { TrackerSettings } from "./pages/TrackerSettings/TrackerSettings";
import { TrackerDetails } from "./pages/TrackerDetails/TrackerDetails";

export const ProfileTracker: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<TrackerOverview />} path="/" />
			<Route element={<TrackerCreate />} path="/create" />
			<Route element={<TrackerEdit />} path="/edit/:id" />
			<Route element={<TrackerSettings />} path="/settings/:id" />
			<Route element={<TrackerDetails />} path="/detail/:id" />
		</Routes>
	);
};
