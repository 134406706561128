import { z } from "zod";

import { LiEventsScrapeStatus } from "@/enum/scrape-enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { ApolloType } from "@/enum/apollo.enum";
import { LiEventType } from "@/enum/lievent.enum";

import { ListStatus } from "@/enum/list.enum";

import { SuggestionSchema } from "../../LiPeople/schema/liPeople";
import { LiEventEntrySchema } from "./liEventEntry";

const { REQUIRED } = ERROR_TYPE;

export const LiEventSchema = z.object({
	id: z.number(),
	name: z.string().min(3, { message: REQUIRED }).trim(),
	searchTerm: z.string().trim().nullable(),
	type: z.nativeEnum(LiEventType),
	eventUrls: z.array(z.string()),
	status: z.nativeEnum(LiEventsScrapeStatus),
	activeStatus: z.nativeEnum(ListStatus),
	liEventEntries: z.array(LiEventEntrySchema),
	location: z.array(z.string()),
	industry: z.array(z.string()),
	profileLanguageEn: z.boolean(),
	profileLanguageDe: z.boolean(),
	profileLanguageFr: z.boolean(),
	profileLanguageEs: z.boolean(),
	profileLanguagePt: z.boolean(),
	minDaysBeforeScrape: z.number(),
	minAttendeesBeforeScrape: z.number().nullable(),
	ListLiEvent: z
		.object({
			listItemType: z.nativeEnum(ApolloType),
		})
		.optional()
		.nullable(),
	lastRunAt: z.string().nullable(),
	searchTermInTitle: z.boolean(),
	listId: z.number().optional(),
	locationList: z.array(SuggestionSchema).optional().nullable(),
	industryList: z.array(SuggestionSchema).optional().nullable(),
});

export const SubLiEventSchema = z.object({
	id: z.number(),
	name: z.string().min(3, { message: REQUIRED }).trim(),
	searchTerm: z.string().trim().nullable(),
	type: z.nativeEnum(LiEventType),
	eventUrls: z.array(z.string()),
	status: z.nativeEnum(LiEventsScrapeStatus),
	activeStatus: z.nativeEnum(ListStatus),
	liEventEntries: z.array(LiEventEntrySchema),
	location: z.array(z.string()),
	industry: z.array(z.string()),
	profileLanguageEn: z.boolean(),
	profileLanguageDe: z.boolean(),
	profileLanguageFr: z.boolean(),
	profileLanguageEs: z.boolean(),
	profileLanguagePt: z.boolean(),
	minDaysBeforeScrape: z.number(),
	minAttendeesBeforeScrape: z.number().nullable(),
	ListLiEvent: z
		.object({
			listItemType: z.nativeEnum(ApolloType),
		})
		.optional()
		.nullable(),
	lastRunAt: z.string().nullable(),
});

export const CreateLiEventSchema = z.object({
	id: z.number().optional(),
	name: z.string().optional(),
	searchTerm: z.string().trim().optional(),
	location: z.array(z.string()),
	industry: z.array(z.string()),
	type: z.nativeEnum(LiEventType),
	profileLanguageEn: z.boolean(),
	profileLanguageDe: z.boolean(),
	profileLanguageFr: z.boolean(),
	profileLanguageEs: z.boolean(),
	profileLanguagePt: z.boolean(),
	eventUrls: z.array(z.string()).optional(),
	minDaysBeforeScrape: z.number(),
	minAttendeesBeforeScrape: z.number().optional(),
	listId: z.number().optional(),
	searchTermInTitle: z.boolean(),
	locationList: z.array(SuggestionSchema).optional().nullable(),
	industryList: z.array(SuggestionSchema).optional().nullable(),
});

export const LiEventResponseSchema = z.object({
	data: z.array(LiEventSchema),
	meta: MetaDataSchema,
});

export type LiEvent = z.infer<typeof LiEventSchema>;

export type CreateLiEvent = z.infer<typeof CreateLiEventSchema>;

export type LiEventResponse = z.infer<typeof LiEventResponseSchema>;
