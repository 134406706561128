import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as XLSX from "xlsx";
import { FileUploader } from "react-drag-drop-files";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import { Button } from "@/components/Button/Button";
import { ButtonColor } from "@/components/Button/types";

import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";

import { classNames } from "@/utils/classNames";

import {
	useCreateTrackerFileMutation,
	useUpdateTrackerFileMutation,
} from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { InputField } from "@/components/InputField/InputField";

import {
	CreateTrackerFile,
	CreateTrackerFileSchema,
	Tracker,
	TrackerFile,
} from "../schema/profileTracker";

interface CreateXlsxFormProps {
	trackerFile?: TrackerFile | null;
	tracker: Tracker;
	handleSelect?: (trackerFIle: TrackerFile | null) => void;
}

const minimumFields = [
	// ["email"],
	// ["linkedinUrl"],
	// ["name", "companyName"],
	// ["title", "companyName"],
	// ["firstName", "lastName", "companyName"],
	// ["companyName"],
	["companyLinkedinUrl"],
	["name", "holdingLinkedinUrl"],
	// ["companyDomain"],
];

const defaultOptions = [
	{ title: "Do Not import", id: "" },
	// { title: "Email Adress", id: "email" },
	// { title: "Job Title", id: "title" },
	// { title: "Name", id: "name" },
	// { title: "Firstname", id: "firstName" },
	// { title: "Lastname", id: "lastName" },
	{ title: "Company Name", id: "name" },
	// { title: "Company Website", id: "companyDomain" },
	// { title: "Personal Linkedin", id: "linkedinUrl" },
	{ title: "Company Linkedin", id: "companyLinkedinUrl" },
	{ title: "Holding Linkedin", id: "holdingLinkedinUrl" },
	{ title: "Save as Custom Field", id: "customField" },
];

export const CreateXlsxForm: FunctionComponent<CreateXlsxFormProps> = ({
	trackerFile,
	tracker,
	handleSelect,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`csvEnrichment.${key}`);
	const [createTrackerFile, { isLoading, error }] = useCreateTrackerFileMutation();
	const [updateTrackerFile, { isLoading: updateIsLoading, error: updateError }] =
		useUpdateTrackerFileMutation();
	const [fileContent, setFileContent] = useState<string>();
	const [foundFields, setFoundFields] = useState<{ foundField: string; assignedField: string }[]>();

	const [allowUpload, setAllowUpload] = useState<boolean>(false);

	const [currentFormState, setCurrentFormState] = useState<CreateTrackerFile>({
		name: "",
		file: "",
		configuration: [],
	});

	const {
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<CreateTrackerFile>({
		defaultValues: {},
		resolver: zodResolver(CreateTrackerFileSchema),
	});

	const handleSaveFormState = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(value: any, name: keyof CreateTrackerFile) => {
			setCurrentFormState({
				...currentFormState,
				[name]: value,
			});

			setValue(name, value, {
				shouldDirty: true,
				shouldValidate: true,
			});
		},
		[currentFormState, setValue]
	);

	useEffect(() => {
		if (trackerFile) {
			reset({
				id: trackerFile.id,
				name: trackerFile.name,
				configuration: trackerFile.configuration,
			});

			handleSaveFormState(trackerFile.name, "name");

			setFoundFields(trackerFile.configuration);
		} else {
			reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackerFile, reset]);

	useEffect(() => {
		if (foundFields) {
			const isMinimumFields = minimumFields.some((field) => {
				return field.every((f) => foundFields.map((m) => m.assignedField).includes(f));
			});

			setAllowUpload(isMinimumFields);
		}
	}, [foundFields]);

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: CreateTrackerFile) => {
		try {
			if (trackerFile) {
				await updateTrackerFile({
					id: trackerFile.id,
					trackerId: tracker.id,
					...values,
					configuration: JSON.stringify(foundFields),
				}).unwrap();
			} else {
				const { file: fileRaw, name } = values;

				if (fileRaw) {
					const formData = new FormData();
					const res: Response = await fetch(fileRaw);
					const blob: Blob = await res.blob();
					const file = new File([blob], "file.csv");

					formData.append("file", file);
					formData.append("name", name);
					formData.append("trackerId", tracker.id.toString());
					formData.append("type", "csv");
					formData.append("configuration", JSON.stringify(foundFields));

					await createTrackerFile(formData).unwrap();
				}
			}

			handleSelect?.(null);
			reset();
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		const processFoundFields = async (fileContent?: string) => {
			if (!fileContent) {
				setFoundFields(undefined);

				return;
			}

			const res = await fetch(fileContent);

			const buffer = await res.arrayBuffer();

			const workbook = XLSX.read(buffer);
			const content = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);

			const firstLine = content.split("\r\n")[0].split("\n")[0].split("\r")[0];
			const fields = firstLine.includes(";") ? firstLine.split(";") : firstLine.split(",");

			setFoundFields(
				fields.map((field) => ({
					foundField: field,
					assignedField: defaultOptions.map((o) => o.id).includes(field) ? field : "",
				}))
			);
		};

		processFoundFields(fileContent);
	}, [fileContent]);

	const options = useMemo(() => {
		return defaultOptions.filter((a) => {
			if (a.id === "customField" || a.id === "") {
				return true;
			}

			if (foundFields?.map((m) => m.assignedField).includes(a.id)) {
				return false;
			}

			return true;
		});
	}, [foundFields]);

	console.log(errors);

	return (
		<div className="">
			<div className="text-md mb-5">Sources</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.name?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						if (event.target.value !== currentFormState?.name) {
							handleSaveFormState(event.target.value, "name");
						}
					}}
					label={ts("name")}
					name={"name"}
					placeholder={ts("namePlaceholder")}
					value={currentFormState?.name || ""}
				/>
			</div>
			<div className="flex flex-col gap-4 w-full mb-4">
				{fileContent || foundFields ? (
					<>
						{foundFields ? (
							<div className="text-text text-left text-ssm font-semibold mb-2">
								{ts("foundFields")}
							</div>
						) : (
							""
						)}
						<div className="w-full">
							<ul>
								{foundFields
									?.filter((a) => a.foundField)
									?.map((item, index) => {
										const selectedField = defaultOptions.find((o) => {
											return o.id === item.assignedField;
										});

										return (
											<li
												key={`list-index-${index}`}
												className="text-xs flex flex-row mb-2 justify-between items-center"
											>
												<div className="text-sm mr-2">{item.foundField}</div>
												<div className="w-[350px]">
													<Dropdown
														classNameButton={classNames(
															selectedField?.id === "" ? "text-gray-200" : ""
														)}
														data={options}
														defaultValue={selectedField || { title: "Please select", id: "" }}
														floating={true}
														handleSelect={(value?: AutoCompleteItem) => {
															if (value) {
																setFoundFields([
																	...foundFields.slice(0, index),
																	{
																		foundField: item.foundField?.trim(),
																		assignedField: value?.id?.toString()?.trim() || "",
																	},
																	...foundFields.slice(index + 1),
																]);
															}
														}}
													/>
												</div>
											</li>
										);
									})}
							</ul>
						</div>
					</>
				) : (
					<FileUploader
						handleChange={(file: File) => {
							setValue("file", URL.createObjectURL(file));
							setFileContent(URL.createObjectURL(file));
						}}
						name="file"
						types={["xlsx"]}
					/>
				)}
			</div>
			<div className="flex flex-col w-full mb-4"></div>

			<div className="mb-2">
				{getAllErrors(error || updateError, formErrors).length
					? renderErrorMessages(getAllErrors(error || updateError, formErrors))
					: null}
			</div>
			<div className="flex gap-4 w-full justify-end">
				<div className="flex max-w-[100px] w-full">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						title="cancel"
						onClick={() => {
							handleSelect?.(null);
							setFileContent(undefined);
							reset();
						}}
					/>
				</div>
				<div className="flex max-w-[150px] w-full">
					<Button
						disabled={!allowUpload}
						isLoading={isLoading || updateIsLoading}
						title={trackerFile?.id ? "Save" : "Upload"}
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</div>
		</div>
	);
};
