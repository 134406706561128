import { FunctionComponent, useEffect, useMemo, useRef } from "react";

import ReactDOM from "react-dom";

import { ReactComponent as ChevronDoubleRight } from "@assets/icons/chevronDoubleRight.svg";
import { ReactComponent as PhoneIcon } from "@assets/icons/phone.svg";
import { ReactComponent as ExternalLinkIcon } from "@assets/icons/launch.svg";

import { SvgIcon } from "@/components/Icon/SvgIcon";

import { CustomColumnTypes } from "@/enum/list.enum";

import { getSvgIcons } from "@/pages/Private/pages/ProfileTracker/utils/getIconsByType";

import { EmailIcon } from "@/components/EmailIcon/EmailIcon";

import { Chip } from "@/components";

import { ListPerson } from "../../../schema/list";

interface InfoAtGoogleMapsDetailViewProps {
	listPerson?: ListPerson;
	setShowDetailView: (value: boolean) => void;
}

export const InfoAtGoogleMapsDetailView: FunctionComponent<InfoAtGoogleMapsDetailViewProps> = ({
	listPerson,
	setShowDetailView,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(e: MouseEvent) {
			e.stopPropagation();
			e.preventDefault();

			if (e.target && ref.current && !ref.current.contains(e.target as Node)) {
				setTimeout(() => {
					setShowDetailView(false);
				}, 100);
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, setShowDetailView]);

	const mapBusiness = useMemo(() => {
		return listPerson?.mapBusiness;
	}, [listPerson]);

	return ReactDOM.createPortal(
		<div
			ref={ref}
			className="absolute overflow-y-auto right-0 top-0 w-1/3 w-[800px] max-w-[80vw] h-full bg-white rounded-tl-[32px] shadow-lg z-[150] transition-opacity text-sm"
		>
			<div
				className="flex justify-between items-center h-[48px] px-8 border-b border-divider mb-8"
				onClick={(e: React.MouseEvent) => {
					e.stopPropagation();

					setShowDetailView(false);
				}}
			>
				<SvgIcon className="w-[24px] h-[24px]" svgIcon={ChevronDoubleRight} />
			</div>
			<div className="mb-4 pb-4 flex flex-row border-b border-divider">
				<div className="ml-8 mr-8 flex-row flex ">
					<div>
						<div className="text-md font-semibold">{mapBusiness?.companyName}</div>
						<div className="text-ssm font-medium mb-3">
							<span className="mr-2">{mapBusiness?.type}</span>
							{mapBusiness?.subtypes?.split(",")?.map((subtype, index) => {
								return (
									<div key={`key-subtype-${index}`} className="mr-2 mb-2 inline-flex">
										<Chip color={"primary"} label={subtype} />
									</div>
								);
							})}
						</div>
						{(mapBusiness?.companyCity || mapBusiness?.companyCountry) && (
							<div className="text-ssm font-medium mb-3">
								{mapBusiness?.companyCity && mapBusiness?.companyCountry
									? `${mapBusiness?.companyCity}, `
									: mapBusiness?.companyCity || ""}
								{mapBusiness?.companyCountry}
							</div>
						)}
						{mapBusiness?.companyLinkedinUrl && (
							<a
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2"
								href={mapBusiness.companyLinkedinUrl}
								referrerPolicy="no-referrer"
								rel="noreferrer"
								target="_blank"
							>
								<img
									className="w-auto h-[20px] m-2"
									src={getSvgIcons(CustomColumnTypes.LINKEDIN_POST)}
								/>
							</a>
						)}
						{mapBusiness?.email && mapBusiness?.emailStatus === "VALID" && (
							<button
								aria-label="copy email"
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2 cursor-pointer p-2"
								type="button"
								onClick={(e: React.MouseEvent) => {
									e.preventDefault();
									e.stopPropagation();
								}}
							>
								<EmailIcon status="checked" />
							</button>
						)}
						{mapBusiness?.emailStatus === "INVALID" && (
							<button
								aria-label="copy email"
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2 cursor-pointer p-2"
								type="button"
								onClick={(e: React.MouseEvent) => {
									e.preventDefault();
									e.stopPropagation();
								}}
							>
								<EmailIcon status="failed" />
							</button>
						)}
						{mapBusiness?.emailStatus === "SEARCHING" && (
							<button
								aria-label="copy email"
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2 cursor-not-allowed p-2	"
								type="button"
							>
								<EmailIcon status="searching" />
							</button>
						)}
						{((mapBusiness?.email && mapBusiness?.emailStatus === "RETRY") ||
							!mapBusiness?.emailStatus) && (
							<button
								aria-label="copy email"
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2 cursor-pointer p-2"
								type="button"
								onClick={(e: React.MouseEvent) => {
									e.preventDefault();
									e.stopPropagation();
									// handleSearchForEmail(row.id);
								}}
							>
								<EmailIcon status="refresh" />
							</button>
						)}
						{mapBusiness?.phoneNumber && (
							<a
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2"
								href={`tel:${mapBusiness.phoneNumber}` || ""}
								referrerPolicy="no-referrer"
								rel="noreferrer"
								target="_blank"
							>
								<SvgIcon className="w-auto h-[20px] m-2" svgIcon={PhoneIcon} />
							</a>
						)}
						{mapBusiness?.website && (
							<a
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2"
								href={mapBusiness.website || ""}
								referrerPolicy="no-referrer"
								rel="noreferrer"
								target="_blank"
							>
								<SvgIcon className="w-auto h-[20px] m-2" svgIcon={ExternalLinkIcon} />
							</a>
						)}
					</div>
				</div>
			</div>
		</div>,
		document.body
	);
};
