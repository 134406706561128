import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/redux";
import { TableConfigState } from "@/types/tableConfig";

const initialState: TableConfigState = {
	data: [],
};

export const tableConfigSlice = createSlice({
	name: "tableConfig",
	initialState,
	reducers: {},
});

// export const {  } =
// 	tableConfigSlice.actions;
export const tableConfigReducer = tableConfigSlice.reducer;
export const tableConfigSelector = (state: RootState) => state[tableConfigSlice.name];
