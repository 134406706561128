import {
	faCakeCandles,
	faLock,
	faMapLocation,
	faSquare,
	faSquareCheck,
	faUserGroup,
	faWarning,
} from "@fortawesome/pro-regular-svg-icons";

import { format } from "date-fns";

import { CircularProgress, Link } from "@mui/material";

import {
	FacebookLogo,
	TiktokLogo,
	InstagramLogo,
	SnapchatLogo,
	TwitterLogo,
	YoutubeLogo,
	GithubLogo,
	PinterestLogo,
} from "phosphor-react";

import { ChangeEvent, FunctionComponent, MouseEvent, useState } from "react";

import { faCircle } from "@fortawesome/pro-solid-svg-icons";

import { Tooltip } from "@/components/Tooltip/Tooltip";
import { EmailIcon } from "@/components/EmailIcon/EmailIcon";

import { LinkedinIcon } from "@/components/LinkedinIcon/LinkedinIcon";

import { ExternalLink } from "@/components/ExternalLink/ExternalLink";

import { PhoneIcon } from "@/components/PhoneIcon/PhoneIcon";
import yelp from "@/assets/icons/yelp.png";

import { Icon } from "@/components";
import { TableColumn } from "@/components/Table/Table";

import { SortParams } from "@/types/types";

import { InclusionStatus } from "@/enum/list.enum";
import { classNames } from "@/utils/classNames";
import { Checkbox } from "@/components/Checkbox/Checkbox";

import { InputField } from "@/components/InputField/InputField";

import {
	useSearchForEmailsMutation,
	useUpdateMapBusinessMutation,
	useUpdateOrganizationMutation,
	useUpdateProspectMutation,
} from "@/pages/Private/redux/list/list.api";

import { getOrderStatus } from "./colum-utils";
import { List, ListPerson } from "../schema/list";

export const renderLanguageColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "prospect.language";

	return {
		title: ts("language"),
		width: "fit-content",
		orderTitle: "prospect.language",
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "language",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => <div>{row.prospect?.language}</div>,
	};
};

export const renderPersonCountryColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "prospect.country";

	return {
		title: ts("country"),
		width: "fit-content",
		orderTitle: "prospect.country",
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "personLocationCountry",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer"
					type="button"
					onClick={(event: MouseEvent) => {
						event.preventDefault();
						event.stopPropagation();
						row.prospect?.country && handleCopyClick(row.prospect?.country);
					}}
				>
					{row.prospect?.country}
				</button>
			</div>
		),
	};
};

export const renderPersonStateColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "prospect.state";

	return {
		title: ts("state"),
		width: "fit-content",
		orderTitle: "prospect.state",
		uid: "personLocationState",
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer"
					type="button"
					onClick={(e: MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();
						row.prospect?.state && handleCopyClick(row.prospect?.state);
					}}
				>
					{row.prospect?.state}
				</button>
			</div>
		),
	};
};

export const renderPersonCityColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "prospect.city";

	return {
		title: ts("city"),
		width: "fit-content",
		orderTitle: "prospect.city",
		uid: "personLocationCity",
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer"
					type="button"
					onClick={(e: MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();
						row.prospect?.city && handleCopyClick(row.prospect?.city);
					}}
				>
					{row.prospect?.city}
				</button>
			</div>
		),
	};
};

export const renderEstimatedNumEmployeesColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "organization.estimatedNumEmployees";

	return {
		title: ts("companyEmployees"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "companyEmployees",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer mr-2"
					onClick={(e: MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();
						handleCopyClick(row.prospect?.organization?.estimatedNumEmployees?.toString() || "");
					}}
				>
					{row.prospect?.organization?.estimatedNumEmployees}{" "}
					<Icon color="##374151" icon={faUserGroup} />
				</button>
			</div>
		),
	};
};

export const renderFollowers = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "prospect.followers";

	return {
		title: ts("followers"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "followers",
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				<button className="mr-2">{row.prospect?.followers}</button>
			</div>
		),
	};
};

export const renderFoundedYearColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "organization.foundedYear";

	return {
		title: ts("foundedYear"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "foundedYear",
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer mr-2"
					onClick={() =>
						row.prospect?.organization?.foundedYear &&
						handleCopyClick(row.prospect?.organization?.foundedYear?.toString() || "")
					}
				>
					{row.prospect?.organization?.foundedYear}{" "}
					{row.prospect?.organization?.foundedYear && (
						<Icon color="##374151" icon={faCakeCandles} />
					)}
				</button>
			</div>
		),
	};
};

export const renderOtherListsColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "list_other.name";

	return {
		title: ts("otherLists"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "otherLists",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div>{row.otherLists?.map((list: List) => list.name)?.join(",")}</div>
		),
	};
};

export const renderIndustryColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "organization.industry";

	return {
		title: ts("industry"),
		width: "fit-content",
		orderTitle: "organization.industry",
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "industry",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div>
				<button
					className="cursor-pointer"
					onClick={() =>
						handleCopyClick(
							row.prospect?.organization?.industry?.capitalizeString() ??
								row.mapBusiness?.type ??
								""
						)
					}
				>
					{row.prospect?.organization?.industry?.capitalizeString() ?? row.mapBusiness?.type}
				</button>
			</div>
		),
	};
};

export const renderJobCityColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "offer.jobCity";

	return {
		title: ts("jobCity"),
		orderTitle: orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "jobCity",
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => <div className="flex flex-row">{row.offer?.jobCity}</div>,
	};
};

export const renderCompanyPeopleCheckColumn = (ts: (key: string) => string): TableColumn => {
	return {
		title: ts("companyPeopleCheck"),
		uid: "companyPeopleCheck",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) =>
			row.companyPeopleCheck ? (
				<Tooltip
					content={
						<div className="w-full flex flex-col">
							<div>
								{row.companyPeopleCheck?.assignedAllTime} people have been assigned all-time
							</div>
							<div>
								{row.companyPeopleCheck?.assignedOnThisList} people have been assigned of this list
							</div>
							<div>
								{row.companyPeopleCheck?.notAssignedOnThisList} people are still on this list
							</div>
						</div>
					}
				>
					<div className="flex flex-row">
						{row.companyPeopleCheck?.assignedAllTime} | {row.companyPeopleCheck.assignedOnThisList}{" "}
						| {row.companyPeopleCheck.notAssignedOnThisList}
					</div>
				</Tooltip>
			) : (
				<></>
			),
	};
};

export const renderJobCountryColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "offer.jobCountry";

	return {
		title: ts("jobCountry"),
		orderTitle: orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "jobCountry",
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => <div className="flex flex-row">{row.offer?.jobCountry}</div>,
	};
};

export const renderConnectionsColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "connections";

	return {
		title: ts("connections"),
		orderTitle: "connections",
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "connections",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				{row.connections?.map((user: string, index: number) => (
					<div key={`connections-${index * 2}`} className="flex text-nowrap">
						<div className="flex flex-row w-fit items-center">
							<span className="text-xs mr-2 text-white rounded-full bg-brand-light p-1">
								{user.split(" ")[0][0]}
								{user.split(" ")[1][0]}
							</span>
						</div>
					</div>
				))}
			</div>
		),
	};
};

export const renderCompanyColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams,
	setShowEdit: ({ id, name }: { id: number; name: string }) => void,
	showEdit: { id: number; name: string } | undefined
): TableColumn => {
	const orderTitle = "organization.name";

	return {
		title: ts("company"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		minWidth: "220px",
		uid: "company",
		hideable: true,
		locked: "leftSixth",
		moveable: false,
		show: true,
		render: (row: ListPerson) => (
			<EditableCompanyColumn
				{...{
					row,
					handleCopyClick,
					orderTitle,
					setShowEdit,
					showEdit,
					sortingInbox,
				}}
			/>
		),
	};
};

export const renderJobsCompanyColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams,
	setShowEdit: ({ id, name }: { id: number; name: string }) => void,
	showEdit: { id: number; name: string } | undefined,
	nameColumnShown: boolean
): TableColumn => {
	const orderTitle = "offer.companyName";

	return {
		title: ts("company"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		minWidth: "220px",
		uid: "company",
		hideable: true,
		locked: nameColumnShown ? "leftSixth" : "leftFifth",
		moveable: false,
		show: true,
		render: (row: ListPerson) => (
			<EditableJobsCompanyColumn
				{...{
					row,
					handleCopyClick,
					orderTitle,
					setShowEdit,
					showEdit,
					sortingInbox,
				}}
			/>
		),
	};
};

export const renderMapCompanyColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams,
	lockedClassName?: string
): TableColumn => {
	const orderTitle = "map_business.name";

	return {
		title: ts("company"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		minWidth: "220px",
		uid: "companyName",
		hideable: true,
		locked: lockedClassName || "leftFifth",
		moveable: false,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex w-full max-w-[220px] items-center">
				<div className="ml-2 w-full flex flex-row justify-between">
					<Tooltip content={row.mapBusiness?.companyName}>
						<button
							className="cursor-pointer mr-1  max-w-[100px] text-ellipsis overflow-hidden"
							onClick={() =>
								row.mapBusiness?.companyName && handleCopyClick(row.mapBusiness?.companyName)
							}
						>
							{row.mapBusiness?.companyName}
						</button>
					</Tooltip>
					<div className="flex items-center">
						{row.mapBusiness?.companyEmail ? (
							<Tooltip content={row.mapBusiness?.companyEmail}>
								<button
									className="cursor-pointer"
									type="button"
									onClick={() =>
										row.mapBusiness?.companyEmail && handleCopyClick(row.mapBusiness?.companyEmail)
									}
								>
									<EmailIcon status="checked" />
								</button>
							</Tooltip>
						) : (
							<EmailIcon status="failed" />
						)}
						{row.mapBusiness?.phoneNumber ? (
							<Tooltip content={row.mapBusiness?.phoneNumber}>
								<button
									aria-label="copy phoneNumber"
									className="mr-1 cursor-pointer"
									type="button"
									onClick={(e: MouseEvent) => {
										e.preventDefault();
										e.stopPropagation();
										handleCopyClick(row.mapBusiness?.phoneNumber || "");
									}}
								>
									<PhoneIcon status="checked" />
								</button>
							</Tooltip>
						) : (
							<PhoneIcon status="failed" />
						)}
						{row.mapBusiness?.companyLinkedinUrl ? (
							<Tooltip content={row.mapBusiness?.companyLinkedinUrl}>
								<button
									aria-label="copy phoneNumber"
									className="mr-1 cursor-pointer"
									type="button"
									onClick={(e: MouseEvent) => {
										e.preventDefault();
										e.stopPropagation();
										handleCopyClick(row.mapBusiness?.companyLinkedinUrl || "");
									}}
								>
									<LinkedinIcon status="checked" />
								</button>
							</Tooltip>
						) : (
							<LinkedinIcon status="failed" />
						)}
					</div>
				</div>
			</div>
		),
	};
};

export const renderGoogleMapsColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "map_business.placeLink";

	return {
		title: ts("googleMaps"),
		width: "fit-content",
		orderTitle,
		uid: "placeLink",
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div>
				{row.mapBusiness?.placeLink && (
					<Link href={row.mapBusiness?.placeLink} target="_blank">
						<Icon className="h-[16px] flex mr-2" icon={faMapLocation} />
					</Link>
				)}
			</div>
		),
	};
};

export const renderGoogleMapsReviewAmountColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "map_business.reviewCount";

	return {
		title: ts("reviewCount"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "reviewCount",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => <div>{row.mapBusiness?.reviewCount}</div>,
	};
};

export const renderGoogleMapsAverageRatingColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "map_business.rating";

	return {
		title: ts("rating"),
		width: "fit-content",
		orderTitle: "rating",
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "rating",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div>
				<button
					onClick={() =>
						row.mapBusiness?.rating && handleCopyClick(row.mapBusiness?.rating.toString())
					}
				>
					{row.mapBusiness?.rating}
				</button>
			</div>
		),
	};
};

export const renderGoogleMapsTypeColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderStatus = getOrderStatus(sortingInbox, "map_business.type");

	return {
		title: ts("type"),
		width: "fit-content",
		orderTitle: "map_business.type",
		orderStatus,
		uid: "type",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => <div>{row.mapBusiness?.type}</div>,
	};
};

export const renderGoogleMapsSocialMediaColumn = (ts: (key: string) => string): TableColumn => {
	return {
		title: ts("socialMedia"),
		width: "fit-content",
		uid: "socialMedia",
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => (
			<div className="flex">
				{row.mapBusiness?.facebookUrl && (
					<Link href={row.mapBusiness?.facebookUrl} target="_blank">
						<FacebookLogo size="20" />
					</Link>
				)}
				{row.mapBusiness?.yelpUrl && (
					<Link href={row.mapBusiness?.yelpUrl} target="_blank">
						<img alt="yelp" className="h-[20px]" src={yelp} />
					</Link>
				)}
				{row.mapBusiness?.tiktokUrl && (
					<Link href={row.mapBusiness?.tiktokUrl} target="_blank">
						<TiktokLogo size="20" />
					</Link>
				)}
				{row.mapBusiness?.instagramUrl && (
					<Link href={row.mapBusiness?.instagramUrl} target="_blank">
						<InstagramLogo size="20" />
					</Link>
				)}
				{row.mapBusiness?.snapchatUrl && (
					<Link href={row.mapBusiness?.snapchatUrl} target="_blank">
						<SnapchatLogo size="20" />
					</Link>
				)}
				{row.mapBusiness?.twitterUrl && (
					<Link href={row.mapBusiness?.twitterUrl} target="_blank">
						<TwitterLogo size="20" />
					</Link>
				)}
				{row.mapBusiness?.youtubeUrl && (
					<Link href={row.mapBusiness?.youtubeUrl} target="_blank">
						<YoutubeLogo size="20" />
					</Link>
				)}
				{row.mapBusiness?.githubUrl && (
					<Link href={row.mapBusiness?.githubUrl} target="_blank">
						<GithubLogo size="20" />
					</Link>
				)}
				{row.mapBusiness?.pinterestUrl && (
					<Link href={row.mapBusiness?.pinterestUrl} target="_blank">
						<PinterestLogo size="20" />
					</Link>
				)}
			</div>
		),
	};
};

export const renderGoogleMapsWebsiteColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "map_website.website";

	return {
		title: ts("website"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "website",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div>
				{row.mapBusiness?.website && (
					<Link href={row.mapBusiness?.website} target="_blank">
						{row.mapBusiness?.website}
					</Link>
				)}
			</div>
		),
	};
};

export const renderGoogleMapsCompanyCountryColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "map_business.companyCountry";

	return {
		title: ts("companyCountry"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "companyCountry",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex flex-row">{row.mapBusiness?.companyCountry}</div>
		),
	};
};

export const renderGoogleMapsCompanyStateColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "map_business.companyState";

	return {
		title: ts("companyState"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "companyState",
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => (
			<div className="flex flex-row">{row.mapBusiness?.companyState}</div>
		),
	};
};

export const renderGoogleMapsCompanyCityColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "map_business.companyCity";

	return {
		title: ts("companyCity"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "companyCity",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex flex-row">{row.mapBusiness?.companyCity}</div>
		),
	};
};

export const renderAssignToColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void
): TableColumn => {
	return {
		title: ts("assignTo"),
		minWidth: "150px",
		width: "fit-content",
		orderTitle: "lemlist.name",
		orderStatus: 0,
		locked: "leftThird",
		icon: faLock,
		uid: "lemlist",
		hideable: false,
		moveable: false,
		show: true,
		render: (row: ListPerson) => (
			<div className="relative flex">
				{row?.userAssigned?.firstName && (
					<span className="w-[26px] h-[26px] text-xs text-center text-white rounded-full bg-brand-light p-1 mr-2">
						{row?.userAssigned?.firstName[0]}
						{row?.userAssigned?.lastName[0]}
					</span>
				)}
				<Tooltip content={row?.lemlist?.name ?? row?.list.lemlists?.at(0)?.name}>
					<button
						className="text-sm text-ellipsis overflow-hidden max-w-[120px] cursor-pointer"
						onClick={() =>
							row?.list?.lemlists?.at(0)?.name &&
							handleCopyClick(row.list.lemlists.at(0)?.name ?? "")
						}
					>
						{row?.lemlist?.name ?? row?.list.lemlists?.at(0)?.name}
					</button>
				</Tooltip>
			</div>
		),
	};
};

export const renderNameColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams,
	setShowEdit: ({ id, name }: { id: number; name: string }) => void,
	showEdit: { id: number; name: string } | undefined
): TableColumn => {
	const orderTitle = "prospect.name";

	return {
		title: ts("name"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		locked: "leftFifth",
		icon: faLock,
		moveable: false,
		hideable: true,
		minWidth: "180px",
		uid: "name",
		show: true,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		render: (row: any) => (
			<EditableNameColumn
				{...{
					row,
					handleCopyClick,
					orderTitle,
					setShowEdit,
					showEdit,
					sortingInbox,
				}}
			/>
		),
	};
};

export const renderMapNameColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams,
	setShowEdit: ({ id, name }: { id: number; name: string }) => void,
	showEdit: { id: number; name: string } | undefined
): TableColumn => {
	const orderTitle = "map_business.name";

	return {
		title: ts("name"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		locked: "leftFifth",
		icon: faLock,
		moveable: false,
		hideable: true,
		minWidth: "180px",
		uid: "name",
		show: true,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		render: (row: any) => (
			<EditableMapNameColumn
				{...{
					row,
					handleCopyClick,
					orderTitle,
					setShowEdit,
					showEdit,
					sortingInbox,
				}}
			/>
		),
	};
};

export const EditableJobNameColumn: FunctionComponent<{
	row: ListPerson;
	handleCopyClick: (value: string) => void;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
	orderTitle: string;
}> = ({ row, handleCopyClick, orderTitle, setShowEdit, showEdit }) => {
	const [value, setValue] = useState<string | undefined>(row?.prospect?.name || "");

	const [updateProspect, { isLoading }] = useUpdateProspectMutation();

	if (isLoading) {
		return <CircularProgress />;
	}

	return (
		<div className="relative flex w-full max-w-[180px] flew-row justify-between items-center">
			{showEdit && showEdit?.id === row.id && showEdit?.name === orderTitle ? (
				<div className="min-w-[180px] absolute z-10">
					<InputField
						autoFocus
						handleBlur={() => {
							setShowEdit({ id: 0, name: "" });
						}}
						handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
							setValue(event.target.value);
						}}
						name={orderTitle}
						value={value || ""}
						onKeyDown={async (e) => {
							if (e.key === "Enter" && value) {
								await updateProspect({
									id: row.id,
									...{ name: value },
								}).unwrap();
								setShowEdit({ id: 0, name: "" });
							}
						}}
					/>
				</div>
			) : (
				<Tooltip content={row.prospect?.name || row.offer?.name}>
					<button
						className="cursor-pointer mr-1 max-w-[100px] text-ellipsis overflow-hidden"
						onClick={() =>
							(row.prospect?.name || row.offer?.name) &&
							handleCopyClick(row.prospect?.name || row.offer?.name || "")
						}
					>
						{row.prospect?.image && (
							<img
								alt={row.prospect?.name ?? ""}
								className="w-[24px] h-[24px] rounded-full mr-1"
								referrerPolicy="no-referrer"
								src={row.prospect?.image}
								onError={(obj) => obj?.currentTarget?.parentNode?.removeChild(obj?.currentTarget)}
							/>
						)}
						<span
							onContextMenu={(e) => {
								e.preventDefault(); // prevent the default behaviour when right clicked
								e.stopPropagation();
								console.log("Right Click");
								setShowEdit({ id: row.id, name: orderTitle });
							}}
						>
							{row.prospect?.name || row.offer?.name}
						</span>
					</button>
				</Tooltip>
			)}
			<div className="flex flex-row">
				{row.prospect?.linkedinUrl || row.offer?.linkedinUrl ? (
					<Link href={row.prospect?.linkedinUrl || row.offer?.linkedinUrl || ""} target="_blank">
						<LinkedinIcon status="checked" />
					</Link>
				) : (
					(row.prospect?.name || row.offer?.name) && <LinkedinIcon status="failed" />
				)}

				{row.prospect?.email && row.prospect?.emailStatus === "VALID" && (
					<button
						aria-label="copy email"
						className="mr-1 cursor-pointer"
						type="button"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							handleCopyClick(row.prospect?.email ?? "");
						}}
					>
						<EmailIcon status="checked" />
					</button>
				)}
				{row.prospect?.email && row.prospect?.emailStatus === "INVALID" && (
					<button
						aria-label="copy email"
						className="mr-1 cursor-pointer"
						type="button"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							handleCopyClick(row.prospect?.email ?? "");
						}}
					>
						<EmailIcon status="failed" />
					</button>
				)}
				{((row.prospect?.email && row.prospect?.emailStatus === "RETRY") ||
					!row.prospect?.emailStatus) && (
					<button
						aria-label="copy email"
						className="mr-1 cursor-pointer"
						type="button"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							handleCopyClick(row.prospect?.email ?? "");
						}}
					>
						<EmailIcon status="refresh" />
					</button>
				)}
				{row.offer?.phoneNumber || row.prospect?.phone ? (
					<Tooltip content={row.offer?.phoneNumber || row.prospect?.phone || ""}>
						<button
							aria-label="copy phoneNumber"
							className="mr-1 cursor-pointer"
							type="button"
							onClick={() =>
								handleCopyClick(row.offer?.phoneNumber || row.prospect?.organization?.phone || "")
							}
						>
							<PhoneIcon status="checked" />
						</button>
					</Tooltip>
				) : (
					<PhoneIcon status="failed" />
				)}
			</div>
		</div>
	);
};

export const EditableNameColumn: FunctionComponent<{
	row: ListPerson;
	handleCopyClick: (value: string) => void;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
	orderTitle: string;
}> = ({ row, handleCopyClick, orderTitle, setShowEdit, showEdit }) => {
	const [value, setValue] = useState<string | undefined>(row?.prospect?.name || "");

	const [updateProspect, { isLoading }] = useUpdateProspectMutation();
	const [searchForEmails, { isLoading: searchForEmailsIsLoading }] = useSearchForEmailsMutation();

	const handleSearchForEmail = async (id: number) => {
		await searchForEmails({ ids: [id] }).unwrap();
	};

	if (isLoading || searchForEmailsIsLoading) {
		return <CircularProgress />;
	}

	return (
		<div className="relative flex w-full max-w-[180px] flew-row justify-between items-center">
			{showEdit && showEdit?.id === row.id && showEdit?.name === orderTitle ? (
				<div className="min-w-[180px] absolute z-10">
					<InputField
						autoFocus
						handleBlur={() => {
							setShowEdit({ id: 0, name: "" });
						}}
						handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
							setValue(event.target.value);
						}}
						name={orderTitle}
						value={value || ""}
						onKeyDown={async (e) => {
							if (e.key === "Enter" && value) {
								await updateProspect({
									id: row.id,
									...{ name: value },
								}).unwrap();
								setShowEdit({ id: 0, name: "" });
							}
						}}
					/>
				</div>
			) : (
				<Tooltip content={row.prospect?.name}>
					<button
						className="cursor-pointer mr-1 max-w-[100px] text-ellipsis overflow-hidden"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							row.prospect?.name && handleCopyClick(row.prospect?.name || "");
						}}
					>
						{row.prospect?.image && (
							<img
								alt={row.prospect?.name ?? ""}
								className="w-[24px] h-[24px] float-left rounded-full mr-1"
								referrerPolicy="no-referrer"
								src={row.prospect?.image}
								onError={(obj) => obj?.currentTarget?.parentNode?.removeChild(obj?.currentTarget)}
							/>
						)}
						<span
							onContextMenu={(e) => {
								e.stopPropagation();
								e.preventDefault(); // prevent the default behaviour when right clicked
								console.log("Right Click");
								setShowEdit({ id: row.id, name: orderTitle });
							}}
						>
							{row.prospect?.name}
						</span>
					</button>
				</Tooltip>
			)}
			<div className="flex flex-row">
				{row.prospect?.linkedinUrl ? (
					<Link href={row.prospect?.linkedinUrl || ""} target="_blank">
						<LinkedinIcon status="checked" />
					</Link>
				) : (
					row.prospect?.name && <LinkedinIcon status="failed" />
				)}

				{row.prospect?.email && row.prospect?.emailStatus === "VALID" && (
					<button
						aria-label="copy email"
						className="mr-1 cursor-pointer"
						type="button"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							handleCopyClick(row.prospect?.email ?? "");
						}}
					>
						<EmailIcon status="checked" />
					</button>
				)}
				{row.prospect?.emailStatus === "INVALID" && (
					<button
						aria-label="copy email"
						className="mr-1 cursor-pointer"
						type="button"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							handleCopyClick(row.prospect?.email ?? "");
						}}
					>
						<EmailIcon status="failed" />
					</button>
				)}
				{row.prospect?.emailStatus === "SEARCHING" && (
					<button aria-label="copy email" className="mr-1 cursor-not-allowed	" type="button">
						<EmailIcon status="searching" />
					</button>
				)}
				{((row.prospect?.email && row.prospect?.emailStatus === "RETRY") ||
					!row.prospect?.emailStatus) && (
					<button
						aria-label="copy email"
						className="mr-1 cursor-pointer"
						type="button"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							handleSearchForEmail(row.id);
						}}
					>
						<EmailIcon status="refresh" />
					</button>
				)}
				{row.prospect?.phone ? (
					<Tooltip content={row.prospect?.phone || ""}>
						<button
							aria-label="copy phoneNumber"
							className="mr-1 cursor-pointer"
							type="button"
							onClick={(e: MouseEvent) => {
								e.preventDefault();
								e.stopPropagation();
								handleCopyClick(row.prospect?.phone || "");
							}}
						>
							<PhoneIcon status="checked" />
						</button>
					</Tooltip>
				) : (
					<PhoneIcon status="failed" />
				)}
			</div>
		</div>
	);
};

export const EditableMapNameColumn: FunctionComponent<{
	row: ListPerson;
	handleCopyClick: (value: string) => void;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
	orderTitle: string;
}> = ({ row, handleCopyClick, orderTitle, setShowEdit, showEdit }) => {
	const [value, setValue] = useState<string | undefined>(row?.mapBusiness?.name || "");

	const [updateMapBusiness, { isLoading }] = useUpdateMapBusinessMutation();

	if (isLoading) {
		return <CircularProgress />;
	}

	return (
		<div className="relative flex w-full max-w-[180px] flew-row justify-between items-center">
			{showEdit && showEdit?.id === row.id && showEdit?.name === orderTitle ? (
				<div className="min-w-[180px] absolute z-10">
					<InputField
						autoFocus
						handleBlur={() => {
							setShowEdit({ id: 0, name: "" });
						}}
						handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
							setValue(event.target.value);
						}}
						name={orderTitle}
						value={value || ""}
						onKeyDown={async (e) => {
							if (e.key === "Enter" && value) {
								await updateMapBusiness({
									id: row.id,
									...{ name: value },
								}).unwrap();
								setShowEdit({ id: 0, name: "" });
							}
						}}
					/>
				</div>
			) : (
				<Tooltip content={row.mapBusiness?.name}>
					<button
						className="cursor-pointer mr-1 max-w-[100px] text-ellipsis overflow-hidden"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							row.prospect?.name && handleCopyClick(row.mapBusiness?.name || "");
						}}
					>
						<span
							onContextMenu={(e) => {
								e.preventDefault(); // prevent the default behaviour when right clicked
								e.stopPropagation();
								console.log("Right Click");
								setShowEdit({ id: row.id, name: orderTitle });
							}}
						>
							{row.mapBusiness?.name}
						</span>
					</button>
				</Tooltip>
			)}
			<div className="flex flex-row">
				{row.mapBusiness?.linkedinUrl ? (
					<Link href={row.mapBusiness?.linkedinUrl || ""} target="_blank">
						<LinkedinIcon status="checked" />
					</Link>
				) : (
					row.mapBusiness?.name && <LinkedinIcon status="failed" />
				)}

				{row.mapBusiness?.email && (
					<button
						aria-label="copy email"
						className="mr-1 cursor-pointer"
						type="button"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							handleCopyClick(row.mapBusiness?.email ?? "");
						}}
					>
						<EmailIcon status="checked" />
					</button>
				)}
				{!row.mapBusiness?.email && (
					<button aria-label="copy email" className="mr-1 cursor-pointer" type="button">
						<EmailIcon status="failed" />
					</button>
				)}
				{row.mapBusiness?.phoneNumber ? (
					<Tooltip content={row.mapBusiness?.phoneNumber || ""}>
						<button
							aria-label="copy phoneNumber"
							className="mr-1 cursor-pointer"
							type="button"
							onClick={(e: MouseEvent) => {
								e.preventDefault();
								e.stopPropagation();
								handleCopyClick(row.mapBusiness?.phoneNumber || "");
							}}
						>
							<PhoneIcon status="checked" />
						</button>
					</Tooltip>
				) : (
					<PhoneIcon status="failed" />
				)}
			</div>
		</div>
	);
};

export const EditableCompanyColumn: FunctionComponent<{
	row: ListPerson;
	handleCopyClick: (value: string) => void;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
	orderTitle: string;
}> = ({ row, handleCopyClick, orderTitle, setShowEdit, showEdit }) => {
	function truncate(str: string, n: number) {
		return str.length > n ? str.slice(0, n - 1) + "..." : str;
	}

	const [value, setValue] = useState<string | undefined>(row?.prospect?.organization?.name || "");

	const [updateOrganization, { isLoading }] = useUpdateOrganizationMutation();

	if (isLoading) {
		return <CircularProgress />;
	}

	return (
		<div className="flex w-full max-w-[220px] items-center">
			{row.prospect?.organization?.logoUrl && (
				<img
					alt="logo"
					className="h-full w-[24px] rounded mr-1"
					referrerPolicy="no-referrer"
					src={row.prospect?.organization?.logoUrl}
					onError={(obj) => obj?.currentTarget?.parentNode?.removeChild(obj?.currentTarget)}
				/>
			)}
			<div className="ml-2 w-full flex flex-row justify-between">
				{showEdit && showEdit?.id === row.id && showEdit?.name === orderTitle ? (
					<div className="min-w-[180px] absolute left-0 top-0 z-10">
						<InputField
							autoFocus
							handleBlur={() => {
								setShowEdit({ id: 0, name: "" });
							}}
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								setValue(event.target.value);
							}}
							name={orderTitle}
							value={value || ""}
							onKeyDown={async (e) => {
								if (e.key === "Enter" && value) {
									await updateOrganization({
										id: row.id,
										...{ name: value },
									}).unwrap();
									setShowEdit({ id: 0, name: "" });
								}
							}}
						/>
					</div>
				) : (
					<Tooltip
						content={
							<div className="flex flex-col min-w-[300px] text-sm">
								<div>
									<span className="font-bold">Name:</span>
									{row.prospect?.organization?.name || ""}
								</div>
								<div>
									<span className="font-bold">Followers:</span>
									{row.prospect?.organization?.followerCount || 0}
								</div>
								<div>
									<span className="font-bold">Short Description:</span>
									<span
										className="leading-6 truncate ..."
										style={{
											textWrap: "wrap",
										}}
									>
										{truncate(row.prospect?.organization?.shortDescription ?? "", 180)}
									</span>
								</div>
							</div>
						}
					>
						<button
							className="cursor-pointer mr-1  max-w-[100px] text-ellipsis overflow-hidden"
							onClick={(e: MouseEvent) => {
								e.preventDefault();
								e.stopPropagation();
								handleCopyClick(row.prospect?.organization?.name || "");
							}}
						>
							<span
								onContextMenu={(e) => {
									e.stopPropagation();
									e.preventDefault(); // prevent the default behaviour when right clicked
									console.log("Right Click");
									setShowEdit({ id: row.id, name: orderTitle });
								}}
							>
								{row.prospect?.organization?.name || ""}
							</span>
						</button>
					</Tooltip>
				)}

				<div className="flex items-center">
					{row.prospect?.organization?.linkedinUrl && (
						<Link href={row.prospect?.organization?.linkedinUrl} target="_blank">
							<LinkedinIcon status="checked" />
						</Link>
					)}
					{row.prospect?.organization?.websiteUrl && (
						<Link href={row.prospect?.organization?.websiteUrl} target="_blank">
							<ExternalLink />
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export const EditableJobsCompanyColumn: FunctionComponent<{
	row: ListPerson;
	handleCopyClick: (value: string) => void;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
	orderTitle: string;
}> = ({ row, handleCopyClick, orderTitle, setShowEdit, showEdit }) => {
	const [value, setValue] = useState<string | undefined>(row?.prospect?.organization?.name || "");

	const [updateOrganization, { isLoading }] = useUpdateOrganizationMutation();

	if (isLoading) {
		return <CircularProgress />;
	}

	return (
		<div className="flex w-full max-w-[220px] items-center">
			<div className="ml-2 w-full flex flex-row justify-between">
				{showEdit && showEdit?.id === row.id && showEdit?.name === orderTitle ? (
					<div className="min-w-[180px] absolute left-0 top-0 z-10">
						<InputField
							autoFocus
							handleBlur={() => {
								setShowEdit({ id: 0, name: "" });
							}}
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								setValue(event.target.value);
							}}
							name={orderTitle}
							value={value || ""}
							onKeyDown={async (e) => {
								if (e.key === "Enter" && value) {
									await updateOrganization({
										id: row.id,
										...{ name: value },
									}).unwrap();
									setShowEdit({ id: 0, name: "" });
								}
							}}
						/>
					</div>
				) : (
					<Tooltip
						content={
							<div className="flex flex-col min-w-[300px]">
								<div>
									<span className="font-bold">Name:</span>
									{row.offer?.companyName}
								</div>
							</div>
						}
					>
						<button
							className="cursor-pointer mr-1  max-w-[100px] text-ellipsis overflow-hidden"
							onClick={(e: MouseEvent) => {
								e.preventDefault();
								e.stopPropagation();
								handleCopyClick(row.offer?.name || "");
							}}
						>
							<span
								onContextMenu={(e) => {
									e.stopPropagation();
									e.preventDefault(); // prevent the default behaviour when right clicked
									console.log("Right Click");
									setShowEdit({ id: row.id, name: orderTitle });
								}}
							>
								{row.offer?.companyName || ""}
							</span>
						</button>
					</Tooltip>
				)}

				<div className="flex items-center">
					{row.offer?.companyEmail ? (
						<Link href={`mailto: ${row.offer?.companyEmail || ""}`} target="_blank">
							<EmailIcon status="checked" />
						</Link>
					) : (
						<EmailIcon status="failed" />
					)}
					{row.offer?.companyLinkedinUrl ? (
						<Link href={row.offer?.companyLinkedinUrl || ""} target="_blank">
							<LinkedinIcon status="checked" />
						</Link>
					) : (
						<LinkedinIcon status="failed" />
					)}
					{row.offer?.companyDomain && (
						<Link href={row.offer.companyDomain} target="_blank">
							<ExternalLink />
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export const renderJobsNameColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams,
	setShowEdit: ({ id, name }: { id: number; name: string }) => void,
	showEdit: { id: number; name: string } | undefined
): TableColumn => {
	const orderTitle = "offer.name";

	return {
		title: ts("name"),
		width: "fit-content",
		orderTitle,
		locked: "leftFifth",
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		icon: faLock,
		moveable: false,
		hideable: true,
		minWidth: "180px",
		uid: "name",
		show: true,
		render: (row: ListPerson) => (
			<EditableJobNameColumn
				{...{
					row,
					handleCopyClick,
					orderTitle,
					setShowEdit,
					showEdit,
					sortingInbox,
				}}
			/>
		),
	};
};

export const renderGenderColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "prospect.gender";

	return {
		title: ts("gender"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "gender",
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => (
			<div>
				<button
					className="text-ellipsis overflow-hidden max-w-[250px] cursor-pointer"
					onClick={(e: MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();
						handleCopyClick(row.prospect?.gender ?? "");
					}}
				>
					{row.prospect?.gender}
				</button>
			</div>
		),
	};
};

export const renderMaybeColumn = (): TableColumn => {
	return {
		title: "",
		icon: faWarning,
		width: "fit-content",
		allowExclude: false,
		uid: "blacklist",
		locked: "leftSecond",
		hideable: false,
		moveable: false,
		show: true,
		render: (row: ListPerson) => {
			return (
				<div className="flex items-center">
					{row.blacklistType && <Icon className="h-[20px]" color={"#FF9900"} icon={faWarning} />}
					{row.inclusionStatus === InclusionStatus.MAYBE && (
						<Icon className="h-[6px]" color={"#FF9900"} icon={faCircle} />
					)}
					{row.inclusionStatus === InclusionStatus.ADDED && (
						<Icon className="h-[6px] opacity-[0.5]" color={"#22AD5C"} icon={faCircle} />
					)}
					{(row.inclusionStatus === InclusionStatus.EXCLUDED ||
						row.inclusionStatus === InclusionStatus.MANUALLY_EXCLUDED) && (
						<Icon className="h-[6px] opacity-[0.5]" color={"#F23030"} icon={faCircle} />
					)}
				</div>
			);
		},
	};
};

export const renderSelectColumn = (
	toggleSelectAll: () => void,
	selectAll: boolean,
	selectedItems: number[],
	handleSelectItem: (id: number) => void
): TableColumn => {
	return {
		title: "",
		uid: "select",
		icon: selectAll ? faSquareCheck : faSquare,
		allowExclude: false,
		locked: "leftFirst",
		hideable: false,
		moveable: false,
		show: true,
		onHeaderClick: () => {
			toggleSelectAll();
		},
		render: (row: ListPerson, index?: number) => (
			<div className="group flex items-center">
				<div
					className={classNames(
						"w-[16px] text-center font-medium text-table-header",
						selectedItems?.find((lf) => lf === row.id) ? "hidden" : "group-hover:hidden"
					)}
				>
					{typeof index !== "undefined" ? index + 1 : ""}
				</div>
				<Checkbox
					className={classNames(
						selectedItems?.find((lf) => lf === row.id) ? "block" : "hidden group-hover:block"
					)}
					isChecked={!!selectedItems?.find((lf) => lf === row.id)}
					name={"selectedProspects"}
					onChange={(e: MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();

						handleSelectItem(row.id);
					}}
				/>
			</div>
		),
	};
};

export const renderTitleColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "prospect.title";

	return {
		title: ts("title"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "title",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div>
				<Tooltip content={row.prospect?.title}>
					<button
						className="text-ellipsis overflow-hidden max-w-[250px] cursor-pointer"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							handleCopyClick(row.prospect?.title || "");
						}}
					>
						{row.prospect?.title}
					</button>
				</Tooltip>
			</div>
		),
	};
};

export const renderSourceNameColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "list_person.sourceName";

	return {
		title: ts("sourceName"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "sourceName",
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => (
			<div>
				<Tooltip content={row.sourceName}>
					<button
						className="text-ellipsis overflow-hidden max-w-[250px] cursor-pointer"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							handleCopyClick(row.sourceName || "");
						}}
					>
						{row.sourceName}
					</button>
				</Tooltip>
			</div>
		),
	};
};

export const renderSeniorityColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "prospect.seniority";

	return {
		title: ts("level"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "seniority",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div>
				<button
					className="text-ellipsis overflow-hidden max-w-[250px] cursor-pointer"
					onClick={(e: MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();
						handleCopyClick(row.prospect?.seniority || "");
					}}
				>
					{row.prospect?.seniority?.capitalizeString()}
				</button>
			</div>
		),
	};
};

export const renderContentTypeColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_profile_entry_result.type";

	return {
		title: ts("type"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "type",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex w-full max-w-[220px] items-center">
				{row.liProfileEntryResult?.type && ts(row.liProfileEntryResult.type)}
			</div>
		),
	};
};

export const renderLiPostContentTypeColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_profile_entry_result.type";

	return {
		title: ts("type"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "type",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex w-full max-w-[220px] items-center">
				{row.liPostResult?.type && ts(row.liPostResult?.type)}
			</div>
		),
	};
};

export const renderLiPostContentColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_post_result.content";

	function truncate(str: string, n: number) {
		return str.length > n ? str.slice(0, n - 1) + "..." : str;
	}

	return {
		title: ts("content"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "content",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex w-full max-w-[200px] items-center">
				{row.liPostResult?.contentUrl && (
					<Link href={row.liPostResult?.contentUrl} target="_blank">
						<ExternalLink />
					</Link>
				)}
				<Tooltip
					content={
						<div className="flex flex-col min-w-[300px] text-sm">
							<div>
								<span
									className="leading-6 truncate ..."
									style={{
										textWrap: "wrap",
									}}
								>
									{truncate(row.liPostResult?.content ?? "", 180)}
								</span>
							</div>
						</div>
					}
				>
					<button
						className="text-ellipsis overflow-hidden max-w-[180px] cursor-pointer"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							handleCopyClick(row.liPostResult?.content ?? "");
						}}
					>
						{row.liPostResult?.content}
					</button>
				</Tooltip>
			</div>
		),
	};
};

export const renderLiProfileContentColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_profile_entry_result.content";

	return {
		title: ts("content"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "content",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex w-full max-w-[220px] items-center">
				{row.liProfileEntryResult?.contentUrl && (
					<Link href={row.liProfileEntryResult?.contentUrl} target="_blank">
						<ExternalLink />
					</Link>
				)}
				<button
					className="text-ellipsis overflow-hidden max-w-[250px] cursor-pointer"
					onClick={(e: MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();
						handleCopyClick(row.liProfileEntryResult?.content ?? "");
					}}
				>
					{row.liProfileEntryResult?.content}
				</button>
			</div>
		),
	};
};

export const renderLiPostLikesColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_post_result.likes";

	return {
		title: ts("likes"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "likes",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex w-full items-center">{row.liPostResult?.likes}</div>
		),
	};
};

export const renderLiPostCommentsColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_post_result.comments";

	return {
		title: ts("comments"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "comments",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex w-full items-center">{row.liPostResult?.comments}</div>
		),
	};
};

export const renderLiPostSharesColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_post_result.shares";

	return {
		title: ts("shares"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "shares",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex w-full items-center">{row.liPostResult?.shares}</div>
		),
	};
};

export const renderCompanyCityColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "organization.city";

	return {
		title: ts("companyCity"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "city",
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer"
					type="button"
					onClick={() =>
						row.prospect?.organization?.city && handleCopyClick(row.prospect?.organization?.city)
					}
				>
					{row.prospect?.organization?.city}
				</button>
			</div>
		),
	};
};

export const renderCompanyStateColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "organization.state";

	return {
		title: ts("companyState"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "state",
		hideable: true,
		moveable: true,
		show: false,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer"
					type="button"
					onClick={() =>
						row.prospect?.organization?.state && handleCopyClick(row.prospect?.organization?.state)
					}
				>
					{row.prospect?.organization?.state}
				</button>
			</div>
		),
	};
};

export const renderCompanyCountryColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "organization.country";

	return {
		title: ts("companyCountry"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "country",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex flex-row">
				<button
					className="cursor-pointer"
					type="button"
					onClick={() =>
						row.prospect?.organization?.country &&
						handleCopyClick(row.prospect?.organization?.country)
					}
				>
					{row.prospect?.organization?.country}
				</button>
			</div>
		),
	};
};

export const renderEventColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_event_entry.name";

	return {
		title: ts("event"),
		width: "fit-content",
		uid: "event",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div>
				<Tooltip content={<>{row.liEventEntry?.name}</>}>
					<Link href={row.liEventEntry?.url} target="_blank">
						<ExternalLink />
					</Link>
					<button
						className="text-ellipsis overflow-hidden max-w-[250px] cursor-pointer"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							handleCopyClick(row.liEventEntry?.name || "");
						}}
					>
						{row.liEventEntry?.name}
					</button>
				</Tooltip>
			</div>
		),
	};
};

export const renderLiEventAttendeesColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_event_entry.attendees";

	return {
		title: ts("eventAttendees"),
		minWidth: "100px",
		uid: "eventAttendees",
		hideable: true,
		moveable: true,
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		show: true,
		render: (row: ListPerson) => (
			<div>
				<button
					className="cursor-pointer"
					onClick={(e: MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();
						handleCopyClick(row.liEventEntry?.attendees?.toString() || "");
					}}
				>
					{row.liEventEntry?.attendees}
				</button>
			</div>
		),
	};
};

export const renderLiEventDateColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_event_entry.eventAt";

	return {
		title: ts("eventAt"),
		width: "fit-content",
		uid: "eventAt",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div>
				<button
					className="cursor-pointer"
					onClick={(e: MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();
						handleCopyClick(row.liEventEntry?.eventAt || "");
					}}
				>
					{row.liEventEntry?.eventAt
						? format(new Date(row.liEventEntry.eventAt as string), "dd.MM.yyyy")
						: ""}
				</button>
			</div>
		),
	};
};

export const renderLiProfileContentResponseColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_profile_entry_result.contentResponse";

	return {
		title: ts("contentResponse"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "contentResponse",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex w-full max-w-[220px] items-center">
				{row.liProfileEntryResult?.contentResponseUrl && (
					<Link href={row.liProfileEntryResult?.contentResponseUrl} target="_blank">
						<ExternalLink />
					</Link>
				)}
				<button
					className="text-ellipsis overflow-hidden max-w-[250px] cursor-pointer"
					onClick={(e: MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();
						handleCopyClick(row.liProfileEntryResult?.contentResponse ?? "");
					}}
				>
					{row.liProfileEntryResult?.contentResponse}
				</button>
			</div>
		),
	};
};

export const renderLiCompanyActivityContentColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_company_activity_entry_result.content";

	function truncate(str: string, n: number) {
		return str.length > n ? str.slice(0, n - 1) + "..." : str;
	}

	return {
		title: ts("content"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "content",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex w-full max-w-[220px] items-center">
				{row.liCompanyActivityEntryResult?.contentUrl && (
					<Link href={row.liCompanyActivityEntryResult?.contentUrl} target="_blank">
						<ExternalLink />
					</Link>
				)}
				<Tooltip
					content={
						<div className="flex flex-col min-w-[300px] text-sm">
							<div>
								<span
									className="leading-6 truncate ..."
									style={{
										textWrap: "wrap",
									}}
								>
									{truncate(row.liCompanyActivityEntryResult?.content ?? "", 180)}
								</span>
							</div>
						</div>
					}
				>
					<button
						className="text-ellipsis overflow-hidden max-w-[196px] cursor-pointer"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							handleCopyClick(row.liCompanyActivityEntryResult?.content ?? "");
						}}
					>
						{row.liCompanyActivityEntryResult?.content}
					</button>
				</Tooltip>
			</div>
		),
	};
};

export const renderLiCompanyActivityContentResponseColumn = (
	ts: (key: string) => string,
	handleCopyClick: (value: string) => void,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_company_activity_entry_result.contentResponse";

	return {
		title: ts("contentResponse"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "contentResponse",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex w-full max-w-[220px] items-center">
				{row.liCompanyActivityEntryResult?.contentResponseUrl && (
					<Link href={row.liCompanyActivityEntryResult?.contentResponseUrl} target="_blank">
						<ExternalLink />
					</Link>
				)}
				<button
					className="text-ellipsis overflow-hidden max-w-[250px] cursor-pointer"
					onClick={(e: MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();
						handleCopyClick(row.liCompanyActivityEntryResult?.contentResponse ?? "");
					}}
				>
					{row.liCompanyActivityEntryResult?.contentResponse}
				</button>
			</div>
		),
	};
};

export const renderLiCompanyActivityTypeColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_company_activity_entry_result.type";

	return {
		title: ts("type"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "type",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => (
			<div className="flex items-center">{row.liCompanyActivityEntryResult?.type}</div>
		),
	};
};

export const renderLiCompanyActivityPostedAtColumn = (
	ts: (key: string) => string,
	sortingInbox: SortParams
): TableColumn => {
	const orderTitle = "li_company_activity_entry_result.contentDate";

	return {
		title: ts("contentDate"),
		width: "fit-content",
		orderTitle,
		orderStatus: getOrderStatus(sortingInbox, orderTitle),
		uid: "contentDate",
		hideable: true,
		moveable: true,
		show: true,
		render: (row: ListPerson) => {
			return (
				<div className="flex items-center">
					{row.liCompanyActivityEntryResult?.contentDate
						? format(
								new Date(row.liCompanyActivityEntryResult?.contentDate as string),
								"dd.MM.yyyy"
						  )
						: ""}
				</div>
			);
		},
	};
};
