import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { ProfileTrackerTable } from "../../components/ProfileTrackerTable";

export const TrackerOverview: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`list.${key}`);

	return (
		<div className="sm:block flex flex-col items-start justify-between">
			<div className="flex items-end justify-between w-full mb-[20px]">
				<div className="flex-grow">
					<h3 className="mb-3 text-lg font-semibold leading-7">{ts("title")}</h3>
				</div>
			</div>

			{<ProfileTrackerTable />}
		</div>
	);
};
