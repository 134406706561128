export type IFilterType =
	| "dropdownCustom"
	| "dropdownCustomExclude"
	| "dropdown"
	| "dropdownExclude"
	| "isEmpty"
	| "isNotEmpty"
	| "isIn"
	| "isNotIn"
	| "textContains"
	| "textDoesNotContain"
	| "textStartsWith"
	| "textEndsWith"
	| "textIsExactly"
	| "greaterEqual"
	| "lessEqual"
	| "between";

export const IFilterTypeAsValues = [
	"dropdownCustom",
	"dropdownCustomExclude",
	"dropdown",
	"dropdownExclude",
	"isEmpty",
	"isNotEmpty",
	"isIn",
	"isNotIn",
	"textContains",
	"textDoesNotContain",
	"textStartsWith",
	"textEndsWith",
	"textIsExactly",
	"greaterEqual",
	"lessEqual",
	"between",
];

export const ITextFilterType = [
	"textContains",
	"textDoesNotContain",
	"textStartsWith",
	"textEndsWith",
	"textIsExactly",
];

export const IBooleanFilterType = ["isEmpty", "isNotEmpty", "isIn", "isNotIn"];

export const INumericFilterType = ["greaterEqual", "lessEqual", "between"];

export interface IFilter {
	name: string;
	filterParam: string;
	subSelectFilterParam?: string;
	filterByType: IFilterType[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	filterByValue: any[];
	customColumnId?: number;
}

const assignedTo: IFilter = {
	name: "assignedTo",
	filterParam: "list.name",
	filterByType: [
		"dropdown",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const name: IFilter = {
	name: "name",
	filterParam: "prospect.name",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const email: IFilter = {
	name: "email",
	filterParam: "prospect.email",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const linkedinUrl: IFilter = {
	name: "linkedinUrl",
	filterParam: "prospect.linkedinUr",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const country: IFilter = {
	name: "country",
	filterParam: "prospect.country",
	filterByType: [
		"dropdown",
		"dropdownExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const city: IFilter = {
	name: "city",
	filterParam: "prospect.city",
	filterByType: [
		"dropdownCustom",
		"dropdownCustomExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const state: IFilter = {
	name: "state",
	filterParam: "prospect.state",
	filterByType: [
		"dropdown",
		"dropdownExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const company: IFilter = {
	name: "company",
	filterParam: "organization.name",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const cityBranch: IFilter = {
	name: "cityBranch",
	filterParam: "company_location.city",
	filterByType: [
		"dropdownCustom",
		"dropdownCustomExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const stateBranch: IFilter = {
	name: "stateBranch",
	filterParam: "company_location.state",
	filterByType: [
		"dropdown",
		"dropdownExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const countryBranch: IFilter = {
	name: "countryBranch",
	filterParam: "company_location.country",
	filterByType: [
		"dropdown",
		"dropdownExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const companyCountry: IFilter = {
	name: "companyCountry",
	filterParam: "organization.country",
	filterByType: [
		"dropdown",
		"dropdownExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const companyState: IFilter = {
	name: "companyState",
	filterParam: "organization.state",
	filterByType: [
		"dropdown",
		"dropdownExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const companyCity: IFilter = {
	name: "companyCity",
	filterParam: "organization.city",
	filterByType: [
		"dropdownCustom",
		"dropdownCustomExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const companyLinkedinUrl: IFilter = {
	name: "companyLinkedinUrl",
	filterParam: "organization.linkedinUrl",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const foundedYear: IFilter = {
	name: "foundedYear",
	filterParam: "organization.foundedYear",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

export const title: IFilter = {
	name: "title",
	filterParam: "prospect.title",
	filterByType: [
		"dropdownCustom",
		"dropdownCustomExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const seniority: IFilter = {
	name: "seniority",
	filterParam: "prospect.seniority",
	filterByType: [
		"dropdown",
		"dropdownExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const website: IFilter = {
	name: "website",
	filterParam: "organization.websiteUrl",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const industry: IFilter = {
	name: "industry",
	filterParam: "organization.industry",
	filterByType: [
		"dropdown",
		"dropdownExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const estimatedNumEmployees: IFilter = {
	name: "estimatedNumEmployees",
	filterParam: "organization.estimatedNumEmployees",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

const event: IFilter = {
	name: "event",
	filterParam: "li_event_entry.name",
	filterByType: ["dropdown", "textContains", "textDoesNotContain"],
	filterByValue: [],
};

export const list: IFilter = {
	name: "list",
	filterParam: "list_other.name",
	subSelectFilterParam: "list_person.prospectId",
	filterByType: ["dropdown", "isIn", "isNotIn"],
	filterByValue: [],
};

const attendees: IFilter = {
	name: "attendees",
	filterParam: "li_event_entry.attendees",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

export const GeneralFilter: IFilter[] = [
	company,
	// industry,
	companyCountry,
	companyState,
	companyCity,
	countryBranch,
	stateBranch,
	cityBranch,
	companyLinkedinUrl,
];

export const FrequentlyUsedFiltersConfig: IFilter[] = [
	title,
	seniority,
	country,
	industry,
	estimatedNumEmployees,
];

export const AdditionalColumnsFiltersConfig: IFilter[] = [
	city,
	state,
	company,
	companyCountry,
	companyState,
	companyCity,
	companyLinkedinUrl,
	foundedYear,
	countryBranch,
	stateBranch,
	cityBranch,
];

export const LiEventFilter: IFilter[] = [attendees, event];

export const CampaignRuleFieldsGeneral: IFilter[] = [
	assignedTo,
	name,
	title,
	seniority,
	email,
	linkedinUrl,
	country,
	city,
	state,
	company,
	companyCountry,
	companyState,
	companyCity,
	companyLinkedinUrl,
	foundedYear,
	website,
	estimatedNumEmployees,
	list,
];
