import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

import { LiPostResultStatus } from "@/enum/li-post.enum";

import { LiProfileEntryType } from "@/enum/liprofile.enum";

import { PeopleSchema } from "../../Apollo/schema/get-people";

export const LiPostResultSchema = z.object({
	id: z.number(),
	name: z.string().optional(),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	linkedinUrl: z.string().optional(),
	content: z.string().optional(),
	contentUrl: z.string().optional(),
	likes: z.number().optional(),
	comments: z.number().optional(),
	shares: z.number().optional(),
	companyDomain: z.string().optional(),
	companyName: z.string().optional(),
	title: z.string().optional(),
	headline: z.string().optional(),
	country: z.string().optional(),
	state: z.string().optional(),
	city: z.string().optional(),
	companyLinkedinUrl: z.string().optional(),
	companyCountry: z.string().optional(),
	companyState: z.string().optional(),
	companyCity: z.string().optional(),
	location: z.string().optional(),
	postedAt: z.date().optional(),
	status: z.nativeEnum(LiPostResultStatus),
	prospect: PeopleSchema,
	type: z.nativeEnum(LiProfileEntryType),
});

export const LiPostResultResponseSchema = z.object({
	data: z.array(LiPostResultSchema),
	meta: MetaDataSchema,
});

export type LiPostResultResponse = z.infer<typeof LiPostResultResponseSchema>;

export type LiPostResult = z.infer<typeof LiPostResultSchema>;
