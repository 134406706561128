import { FunctionComponent, useEffect, useMemo, useRef } from "react";

import ReactDOM from "react-dom";

import { format, parse } from "date-fns";

import { Link } from "react-router-dom";

import { ReactComponent as ChevronDoubleRight } from "@assets/icons/chevronDoubleRight.svg";
import { ReactComponent as PhoneIcon } from "@assets/icons/phone.svg";
import { ReactComponent as ExternalLinkIcon } from "@assets/icons/launch.svg";
import { ReactComponent as CvIcon } from "@assets/icons/inbox/cvIcon.svg";
import { ReactComponent as BusinessIcon } from "@assets/icons/inbox/business.svg";

import { SvgIcon } from "@/components/Icon/SvgIcon";

import { CustomColumnTypes } from "@/enum/list.enum";

import { getSvgIcons } from "@/pages/Private/pages/ProfileTracker/utils/getIconsByType";

import { EmailIcon } from "@/components/EmailIcon/EmailIcon";

import { Chip } from "@/components";

import { ListPerson } from "../../../schema/list";

interface DetailViewProps {
	listPerson?: ListPerson;
	setShowDetailView: (value: boolean) => void;
}

export const DetailView: FunctionComponent<DetailViewProps> = ({
	listPerson,
	setShowDetailView,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(e: MouseEvent) {
			e.stopPropagation();
			e.preventDefault();

			if (e.target && ref.current && !ref.current.contains(e.target as Node)) {
				setTimeout(() => {
					setShowDetailView(false);
				}, 100);
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, setShowDetailView]);

	const organization = useMemo(() => {
		return listPerson?.prospect?.organization;
	}, [listPerson]);

	const prospect = useMemo(() => {
		return listPerson?.prospect;
	}, [listPerson]);

	const prospectCv = useMemo(() => {
		// eslint-disable-next-line prefer-const
		let cCV = [...(prospect?.prospectEmployments || [])];

		cCV?.sort((a, b) => {
			try {
				const aDate = parse(a.startedAt, "yyyy-MM-dd", new Date());
				const bDate = parse(b.startedAt, "yyyy-MM-dd", new Date());

				return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
			} catch (e) {
				console.error(e);

				return 0;
			}
		});

		return cCV;
	}, [prospect]);

	const currentEmploymentDateRange = useMemo(() => {
		if (prospectCv?.[0]?.startedAt) {
			const startDate = format(
				parse(prospectCv?.[0]?.startedAt, "yyyy-MM-dd", new Date()),
				"MMM yyyy"
			);
			const endDate = prospectCv?.[0]?.endedAt
				? format(parse(prospectCv?.[0]?.endedAt, "yyyy-MM-dd", new Date()), "MMM yyyy")
				: "Present";

			const employmentDateRange = `${startDate} - ${endDate}`;

			return employmentDateRange;
		}

		return "";
	}, [prospectCv]);

	return ReactDOM.createPortal(
		<div
			ref={ref}
			className="absolute overflow-y-auto right-0 top-0 w-1/3 w-[800px] max-w-[80vw] h-full bg-white rounded-tl-[32px] shadow-lg z-[150] transition-opacity text-sm"
		>
			<div
				className="flex justify-between items-center h-[48px] px-8 border-b border-divider mb-8"
				onClick={(e: React.MouseEvent) => {
					e.stopPropagation();

					setShowDetailView(false);
				}}
			>
				<SvgIcon className="w-[24px] h-[24px]" svgIcon={ChevronDoubleRight} />
			</div>
			<div className="mb-4 pb-4 flex flex-row border-b border-divider">
				<div className="ml-8 mr-8 flex-row flex ">
					<div className="h-[56px] w-auto mr-2">
						{prospect?.image && (
							<img
								alt={prospect?.name || ""}
								className="w-auto h-[56px] rounded-full"
								src={prospect?.image}
								onError={(obj) => obj?.currentTarget?.parentNode?.removeChild(obj?.currentTarget)}
							/>
						)}
					</div>
					<div>
						<div className="text-md font-semibold">{prospect?.name}</div>
						<div className="text-ssm font-medium mb-3">
							{prospect?.title} | {currentEmploymentDateRange}
						</div>
						<div className="flex items-center mb-3">
							{organization?.logoUrl && (
								<img
									alt={organization?.logoUrl || ""}
									className="w-auto h-[20px] mr-2"
									src={organization?.logoUrl}
									onError={(obj) => obj?.currentTarget?.parentNode?.removeChild(obj?.currentTarget)}
								/>
							)}
							<div className="text-ssm font-medium">{organization?.name}</div>
						</div>
						{(prospect?.city || prospect?.country) && (
							<div className="text-ssm font-medium mb-3">
								{prospect?.city && prospect?.country ? `${prospect?.city}, ` : prospect?.city || ""}
								{prospect?.country}
							</div>
						)}
						{prospect?.linkedinUrl && (
							<a
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2"
								href={prospect.linkedinUrl}
								referrerPolicy="no-referrer"
								rel="noreferrer"
								target="_blank"
							>
								<img
									className="w-auto h-[20px] m-2"
									src={getSvgIcons(CustomColumnTypes.LINKEDIN_POST)}
								/>
							</a>
						)}
						{prospect?.email && prospect?.emailStatus === "VALID" && (
							<button
								aria-label="copy email"
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2 cursor-pointer p-2"
								type="button"
								onClick={(e: React.MouseEvent) => {
									e.preventDefault();
									e.stopPropagation();
								}}
							>
								<EmailIcon status="checked" />
							</button>
						)}
						{prospect?.emailStatus === "INVALID" && (
							<button
								aria-label="copy email"
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2 cursor-pointer p-2"
								type="button"
								onClick={(e: React.MouseEvent) => {
									e.preventDefault();
									e.stopPropagation();
								}}
							>
								<EmailIcon status="failed" />
							</button>
						)}
						{prospect?.emailStatus === "SEARCHING" && (
							<button
								aria-label="copy email"
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2 cursor-not-allowed p-2	"
								type="button"
							>
								<EmailIcon status="searching" />
							</button>
						)}
						{((prospect?.email && prospect?.emailStatus === "RETRY") || !prospect?.emailStatus) && (
							<button
								aria-label="copy email"
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2 cursor-pointer p-2"
								type="button"
								onClick={(e: React.MouseEvent) => {
									e.preventDefault();
									e.stopPropagation();
									// handleSearchForEmail(row.id);
								}}
							>
								<EmailIcon status="refresh" />
							</button>
						)}
						{organization?.phone && (
							<a
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2"
								href={`tel:${organization.phone}` || ""}
								referrerPolicy="no-referrer"
								rel="noreferrer"
								target="_blank"
							>
								<SvgIcon className="w-auto h-[20px] m-2" svgIcon={PhoneIcon} />
							</a>
						)}
						{organization?.websiteUrl && (
							<a
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2"
								href={organization.websiteUrl || ""}
								referrerPolicy="no-referrer"
								rel="noreferrer"
								target="_blank"
							>
								<SvgIcon className="w-auto h-[20px] m-2" svgIcon={ExternalLinkIcon} />
							</a>
						)}
					</div>
				</div>
			</div>

			<div className="ml-8 mr-8 mb-8 flex flex-col">
				<div className="mb-8 flex flex-row gap-5">
					{prospectCv && prospectCv?.length > 0 && (
						<div className="flex w-full lg:w-1/2 flex-col text-gray-700 items-center mb-4 border border-gray-200 rounded-lg p-4">
							<div className="mb-4 flex flex-row items-center w-full">
								<SvgIcon className="w-auto h-[24px] mr-2" svgIcon={CvIcon} />
								<div className="text-smd font-semibold text-black-100">Personal Profile</div>
							</div>
							<div className="mb-4 flex flex-col items-start w-full max-h-[600px] overflow-y-auto">
								<div className="text-sm text-black-100 flex flex-row mb-2.5 ">Work history</div>
								{prospectCv?.map((employment, index) => {
									const startDate = format(
										parse(employment?.startedAt, "yyyy-MM-dd", new Date()),
										"MMM yyyy"
									);
									const endDate = employment?.endedAt
										? format(parse(employment?.endedAt, "yyyy-MM-dd", new Date()), "MMM yyyy")
										: "Present";

									const employmentDateRange = `${startDate} - ${endDate}`;

									return (
										<div key={index} className="flex flex-col w-full mb-2 py-2">
											<div className="text-sm font-semibold">
												{employment.linkedinUrl ? (
													<Link target="_blank" to={employment.linkedinUrl}>
														{employment?.organizationName}
													</Link>
												) : (
													employment?.organizationName
												)}
											</div>
											<div className="text-sm font-medium">{employment?.title}</div>
											<div className="text-xs font-semibold">{employmentDateRange}</div>
										</div>
									);
								})}
							</div>
						</div>
					)}
					<div className="flex w-full md:w-1/2 flex-col text-gray-700 items-center mb-4 border border-gray-200 rounded-lg p-4">
						<div className="mb-4 flex flex-row items-center w-full">
							<SvgIcon className="w-auto h-[24px] mr-2" svgIcon={BusinessIcon} />
							<div className="text-smd font-semibold text-black-100">Current Company Profile</div>
						</div>
						<div className="mb-4 flex flex-col items-start w-full max-h-[600px] overflow-y-auto">
							<div className="flex flex-col items-start mb-4">
								<div className="text-ssm text-black-100 font-medium flex flex-row mb-2.5 ">
									{organization?.name}
									{organization?.seoDescription ? ` - ${organization?.seoDescription}` : ""}
								</div>
								<div className="text-sm text-black-100 flex flex-row ">
									{organization?.shortDescription}
								</div>
							</div>
							<div className="flex flex-col items-start mb-4">
								<div className="text-ssm text-black-100 font-medium flex flex-row mb-2.5 ">
									Employees
								</div>
								<div className="text-sm text-black-100 flex flex-row mb-4 ">
									{organization?.estimatedNumEmployees}
								</div>
							</div>
							<div className="flex flex-col items-start mb-4">
								<div className="text-ssm text-black-100 font-medium flex flex-row mb-2.5 ">
									Follower
								</div>
								<div className="text-sm text-black-100 flex flex-row mb-4 ">
									{organization?.followerCount} followers
								</div>
							</div>
							<div className="flex flex-col items-start mb-4">
								<div className="text-ssm text-black-100 font-medium flex flex-row mb-2.5 ">
									Industry
								</div>
								<div className="text-sm text-black-100 flex flex-row mb-4 ">
									{organization?.industry}
								</div>
							</div>
							{organization?.foundedYear && (
								<div className="flex flex-col items-start mb-4">
									<div className="text-ssm text-black-100 font-medium flex flex-row mb-2.5 ">
										Founded
									</div>
									<div className="text-sm text-black-100 flex flex-row mb-4 ">
										{organization?.foundedYear}
									</div>
								</div>
							)}
							{organization?.keywords && organization?.keywords?.length > 0 && (
								<div className="flex flex-col items-start mb-4">
									<div className="text-ssm text-black-100 font-medium flex flex-row mb-2.5 ">
										Keywords
									</div>
									<div className="text-sm text-black-100 mb-4 w-full">
										{organization?.keywords?.map((keyword, index) => {
											return (
												<div key={`key-subtype-${index}`} className="mr-2 mb-2 inline-flex">
													<Chip color={"primary"} label={keyword} />
												</div>
											);
										})}
									</div>
								</div>
							)}
							{organization?.companyLocations && organization?.companyLocations?.length > 0 && (
								<div className="flex flex-col items-start mb-4">
									<div className="text-ssm text-black-100 font-medium flex flex-row mb-2.5 ">
										Locations
									</div>
									<div className="text-sm text-black-100 mb-4 w-full">
										{organization?.companyLocations?.map((location, index) => {
											return (
												<div key={`key-subtype-${index}`} className="mr-2 mb-2 inline-flex">
													<Chip
														color={"primary"}
														label={`${`${location.city ? `${location.city}, ` : ""}${
															location.state ? `${location.state}, ` : ""
														}${location.country}`}`}
													/>
												</div>
											);
										})}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>,
		document.body
	);
};
