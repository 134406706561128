import { TableColumn } from "@/components/Table/Table";

import { SortParams } from "@/types/types";

import {
	renderCompanyCityColumn,
	renderCompanyColumn,
	renderCompanyCountryColumn,
	renderCompanyHoldingColumn,
	renderScoreColumn,
	// renderCompanyStateColumn,
	renderSelectColumn,
} from "../utils/allColumns";

export interface GeneralTableColumnsProps {
	selectAll: boolean;
	toggleSelectAll: () => void;
	selectedItems: number[];
	handleSelectItem: (id: number) => void;
	handleCopyClick: (value: string) => void;
	// handleContextMenu: (value: string, row: ListPerson) => void;
	ts: (key: string) => string;
	sortingInbox: SortParams;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
	setShowDelete: ({ id, name }: { id: number; name: string }) => void;
}

export const GeneralTableColumns = ({
	selectAll,
	toggleSelectAll,
	selectedItems,
	handleSelectItem,
	handleCopyClick,
	// handleContextMenu,
	ts,
	sortingInbox,
	setShowEdit,
	showEdit,
	setShowDelete,
}: GeneralTableColumnsProps): TableColumn[] => {
	return [
		renderSelectColumn(toggleSelectAll, selectAll, selectedItems, handleSelectItem),
		// // { ...getContextMenuItem(handleContextMenu, ts) },
		renderCompanyColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit),
		renderCompanyHoldingColumn(
			ts,
			handleCopyClick,
			sortingInbox,
			setShowEdit,
			setShowDelete,
			showEdit
		),
		renderCompanyCityColumn(ts, handleCopyClick, sortingInbox),
		renderCompanyCountryColumn(ts, handleCopyClick, sortingInbox),
		renderScoreColumn(ts, handleCopyClick, sortingInbox),
	];
};
