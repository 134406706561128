import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { LiPostContentType, LiPostResultStatus, LiPostSort, TimeType } from "@/enum/li-post.enum";
import { ListStatus } from "@/enum/list.enum";

import { LiProfileEntryType } from "@/enum/liprofile.enum";

import { SuggestionSchema } from "../../LiPeople/schema/liPeople";
import { LiPostResultSchema } from "./liPostResult";

const { REQUIRED } = ERROR_TYPE;

export const LiPostSchema = z.object({
	id: z.number(),
	name: z.string().min(3, { message: REQUIRED }).trim(),
	query: z.string().optional(),
	postUrl: z.string().optional(),
	sortBy: z.nativeEnum(LiPostSort),
	authorJobTitle: z.string().trim().nullable(),
	contentType: z.nativeEnum(LiPostContentType).nullable(),
	fromMember: SuggestionSchema.optional().nullable(),
	fromOrganization: SuggestionSchema.optional().nullable(),
	authorCompany: SuggestionSchema.optional().nullable(),
	authorIndustry: SuggestionSchema.optional().nullable(),
	mentionsMember: SuggestionSchema.optional().nullable(),
	mentionsOrganization: SuggestionSchema.optional().nullable(),
	status: z.nativeEnum(LiPostResultStatus),
	activeStatus: z.nativeEnum(ListStatus),
	liPostResults: z.array(LiPostResultSchema),
	repeatDaily: z.boolean(),
	timeType: z.nativeEnum(TimeType),
	timeFrame: z.number().optional(),
	timeStart: z.date().optional(),
	timeEnd: z.date().optional(),
	listId: z.number().optional(),
	type: z.nativeEnum(LiProfileEntryType),
});

export const CreateLiPostSchema = z
	.object({
		id: z.number().optional(),
		name: z.string().min(3, { message: REQUIRED }).trim(),
		query: z.string().optional(),
		postUrl: z.string().optional(),
		sortBy: z.nativeEnum(LiPostSort),
		authorJobTitle: z.string().trim().optional(),
		contentType: z.nativeEnum(LiPostContentType).nullable().optional(),
		fromMember: SuggestionSchema.optional().nullable(),
		fromOrganization: SuggestionSchema.optional().nullable(),
		authorCompany: SuggestionSchema.optional().nullable(),
		authorIndustry: SuggestionSchema.optional().nullable(),
		mentionsMember: SuggestionSchema.optional().nullable(),
		mentionsOrganization: SuggestionSchema.optional().nullable(),
		repeatDaily: z.boolean(),
		timeType: z.nativeEnum(TimeType),
		timeFrame: z.number().optional(), // num of days or 'period'
		timeStart: z.date().optional(),
		timeEnd: z.date().optional(),
		listId: z.number().optional(),
		type: z.nativeEnum(LiProfileEntryType),
	})
	.refine(
		(data) => {
			if (!!data.postUrl) {
				return true;
			}

			if (data.timeFrame && data.timeFrame > 0) {
				return true;
			}
		},
		{
			message: "REQUIRED",
			path: ["timeFrame"],
		}
	);

export const LiPostResponseSchema = z.object({
	data: z.array(LiPostSchema),
	meta: MetaDataSchema,
});

export type LiPost = z.infer<typeof LiPostSchema>;

export type CreateLiPost = z.infer<typeof CreateLiPostSchema>;

export type LiPostResponse = z.infer<typeof LiPostResponseSchema>;
