import { TableColumn } from "@/components/Table/Table";

import { SortParams } from "@/types/types";

import { ListPerson } from "../schema/list";
import { getContextMenuItem } from "../utils/contextMenu";
import {
	renderSelectColumn,
	renderMaybeColumn,
	renderAssignToColumn,
	renderConnectionsColumn,
	renderEstimatedNumEmployeesColumn,
	renderGenderColumn,
	renderIndustryColumn,
	renderLanguageColumn,
	renderNameColumn,
	renderPersonCityColumn,
	renderPersonCountryColumn,
	renderPersonStateColumn,
	renderTitleColumn,
	renderSeniorityColumn,
	renderCompanyColumn,
	renderLiPostContentColumn,
	renderLiPostLikesColumn,
	renderLiPostCommentsColumn,
	renderLiPostSharesColumn,
	renderFoundedYearColumn,
	renderCompanyCityColumn,
	renderCompanyStateColumn,
	renderCompanyCountryColumn,
	renderOtherListsColumn,
	renderFollowers,
	renderSourceNameColumn,
	renderLiPostContentTypeColumn,
	renderCompanyPeopleCheckColumn,
} from "../utils/allColumns";

export interface LiPostTableColumnsProps {
	selectAll: boolean;
	toggleSelectAll: () => void;
	selectedItems: number[];
	handleSelectItem: (id: number) => void;
	handleCopyClick: (value: string) => void;
	handleContextMenu: (value: string, row: ListPerson) => void;
	ts: (key: string) => string;
	sortingInbox: SortParams;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
}

export const LiPostTableColumns = ({
	selectAll,
	toggleSelectAll,
	selectedItems,
	handleSelectItem,
	handleCopyClick,
	handleContextMenu,
	ts,
	sortingInbox,
	setShowEdit,
	showEdit,
}: LiPostTableColumnsProps): TableColumn[] => {
	const columns: TableColumn[] = [
		renderSelectColumn(toggleSelectAll, selectAll, selectedItems, handleSelectItem),
		renderMaybeColumn(),
		renderAssignToColumn(ts, handleCopyClick),
		{ ...getContextMenuItem(handleContextMenu, ts) },
		renderNameColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit),
		renderCompanyColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit),
		renderCompanyPeopleCheckColumn(ts),
		renderLiPostContentTypeColumn(ts, sortingInbox),
		renderLiPostContentColumn(ts, handleCopyClick, sortingInbox),
		renderLiPostLikesColumn(ts, sortingInbox),
		renderLiPostCommentsColumn(ts, sortingInbox),
		renderLiPostSharesColumn(ts, sortingInbox),
	];

	columns.push(renderGenderColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderTitleColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderSeniorityColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderLanguageColumn(ts, sortingInbox));
	columns.push(renderPersonCityColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderPersonStateColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderPersonCountryColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderCompanyCityColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderCompanyStateColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderCompanyCountryColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderEstimatedNumEmployeesColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderFoundedYearColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderIndustryColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderConnectionsColumn(ts, sortingInbox));
	columns.push(renderFollowers(ts, sortingInbox));
	columns.push(renderOtherListsColumn(ts, sortingInbox));
	columns.push(renderSourceNameColumn(ts, handleCopyClick, sortingInbox));

	return columns;
};
