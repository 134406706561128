import { Dispatch, SetStateAction, FunctionComponent, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Modal } from "@/components";

import { useGetTrackerQuery } from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { GeneralFilter, IFilter, IFilterType } from "../constans/general-filter";

import { useFilters } from "../utils/useFilters";
import { TrackerOrganization } from "../schema/trackerOrganizations";

export interface IFilterWithValues {
	[key: string]: {
		value?:
			| string
			| number
			| {
					name: string;
					id: number;
			  }[]
			| {
					title: string;
					id: string;
			  }[]
			| {
					name: string;
					id: number;
			  };
		filterParam: string;
		type: IFilterType;
		customColumnId?: number;
	};
}

interface FilterModalProps {
	inboxData: TrackerOrganization[];
	trackerId: number;
	showFilterModal: boolean;
	setShowFilterModal: Dispatch<SetStateAction<boolean>>;
	filter?: IFilterWithValues;
	setFilter: Dispatch<SetStateAction<IFilterWithValues | undefined>>;
}

export const FilterModal: FunctionComponent<FilterModalProps> = ({
	inboxData,
	trackerId,
	showFilterModal,
	setShowFilterModal,
	filter,
	setFilter,
}) => {
	const { t } = useTranslation();

	const { renderFilters, filterValue, setFilterValue, setFilterType } = useFilters({ filter });

	const { data: tracker } = useGetTrackerQuery(trackerId);

	const onSubmit = () => {
		filterValue && setFilter(filterValue);
	};

	const resetFilter = () => {
		setFilterType({});
		setFilterValue({});
	};

	const FilterDataType = useMemo(() => {
		if (!tracker) {
			return [];
		}

		let FilterDataType: IFilter[] = [];

		const foundPerson = inboxData.find(
			(trackerOrganization) => trackerOrganization.organization?.id
		);

		if (foundPerson) {
			FilterDataType = [...GeneralFilter, ...FilterDataType];
		}

		if (FilterDataType.length === 0) {
			FilterDataType = GeneralFilter;
		}

		return FilterDataType;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tracker]);

	return (
		<Modal
			handleClear={() => resetFilter()}
			handleClose={() => setShowFilterModal(false)}
			handleSave={() => {
				onSubmit();
				setShowFilterModal(false);
			}}
			isOpened={showFilterModal}
			size="lg"
			submitButtonText={t("basics.confirm")}
			title={t("basics.filter")}
		>
			<div className="flex flex-col">{renderFilters(FilterDataType)}</div>
		</Modal>
	);
};
