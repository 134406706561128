/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useCallback, useRef, useEffect, useState, FunctionComponent } from "react";
import { Editor, Transforms, Range, createEditor, Descendant, BaseEditor } from "slate";
import { withHistory } from "slate-history";
import { Slate, Editable, ReactEditor, withReact, useSelected, useFocused } from "slate-react";

import { MentionElement } from "./custom-types.d";

export interface PromptEditorProps {
	handleChange: (value: BaseEditor) => void;
	handleValueChange?: (value: Descendant[]) => void;
	value?: Descendant[];
	suggestions: string[];
	getSuggestionLabel?: (suggestion: string) => string;
}

const PromptEditor: FunctionComponent<PromptEditorProps> = ({
	handleChange,
	value,
	suggestions,
	handleValueChange,
	getSuggestionLabel,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [target, setTarget] = useState<Range | undefined | null>();
	const [index, setIndex] = useState(0);
	const [search, setSearch] = useState("");
	const renderElement = useCallback((props: any) => <Element {...props} />, []);
	const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
	const editor = useMemo(() => withMentions(withReact(withHistory(createEditor()))), []);

	handleChange(editor);

	const initialValue: Descendant[] = value || [
		{
			type: "paragraph",
			children: [{ text: "" }],
		},
	];

	useEffect(() => {
		handleValueChange?.(editor.children);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const processedSuggestions = useMemo(() => {
		return suggestions
			.map((key) => ({
				name: getSuggestionLabel ? getSuggestionLabel(key) : key,
				id: key,
			}))
			.filter((item) => item.name !== undefined);
	}, [suggestions, getSuggestionLabel]);

	const chars = useMemo(
		() => processedSuggestions.filter((c) => c.name.toLowerCase().includes(search.toLowerCase())),
		[processedSuggestions, search]
	);

	const onKeyDown = useCallback(
		(event: any) => {
			if (target && chars.length > 0) {
				switch (event.key) {
					case "ArrowDown":
						event.preventDefault();
						const prevIndex = index >= chars.length - 1 ? 0 : index + 1;

						setIndex(prevIndex);
						break;
					case "ArrowUp":
						event.preventDefault();
						const nextIndex = index <= 0 ? chars.length - 1 : index - 1;

						setIndex(nextIndex);
						break;
					case "Tab":
					case "Enter":
						event.preventDefault();
						Transforms.select(editor, target);
						insertMention(editor, chars[index].id);
						setTarget(null);
						break;
					case "Escape":
						event.preventDefault();
						setTarget(null);
						break;
				}
			}
		},
		[chars, editor, index, target]
	);

	useEffect(() => {
		if (target && chars.length > 0) {
			const el = ref.current;
			const domRange = ReactEditor.toDOMRange(editor, target);

			const rect = domRange.getBoundingClientRect();

			if (el) {
				el.style.top = `${rect.top + rect.height}px`;
				el.style.left = `${rect.left + window.pageXOffset}px`;
			}
		}
	}, [chars.length, editor, index, search, target]);

	return (
		<Slate
			editor={editor}
			initialValue={initialValue}
			onChange={() => {
				const { selection } = editor;

				if (selection && Range.isCollapsed(selection)) {
					const [start] = Range.edges(selection);
					const wordBefore = Editor.before(editor, start, { unit: "word" });
					const before = wordBefore && Editor.before(editor, wordBefore);
					const beforeRange = before && Editor.range(editor, before, start);
					const beforeText = beforeRange && Editor.string(editor, beforeRange);
					const beforeMatch = beforeText && beforeText.match(/^{(\w+)$/);
					const after = Editor.after(editor, start);
					const afterRange = Editor.range(editor, start, after);
					const afterText = Editor.string(editor, afterRange);
					const afterMatch = afterText.match(/^(\s|$)/);

					if (beforeMatch && afterMatch) {
						setTarget(beforeRange);
						setSearch(beforeMatch[1]);
						setIndex(0);

						return;
					}
				}

				setTarget(null);
			}}
			onValueChange={(value) => {
				if (value) {
					handleValueChange?.(value);
				}
			}}
		>
			<Editable
				className="w-full min-h-[120px] px-4 py-2 rounded-t-xl"
				renderElement={renderElement}
				renderLeaf={renderLeaf}
				onKeyDown={onKeyDown}
			/>
			{target && chars.length > 0 && (
				<div
					ref={ref}
					data-cy="mentions-portal"
					style={{
						top: "-9999px",
						left: "-9999px",
						position: "fixed",
						zIndex: 1,
						padding: "3px",
						background: "white",
						borderRadius: "4px",
						boxShadow: "0 1px 5px rgba(0,0,0,.2)",
					}}
				>
					{chars.map((char, i) => (
						<div
							key={char.id}
							style={{
								padding: "1px 3px",
								borderRadius: "3px",
								cursor: "pointer",
								background: i === index ? "#B4D5FF" : "transparent",
							}}
							onClick={() => {
								Transforms.select(editor, target);
								insertMention(editor, char.id);
								setTarget(null);
							}}
						>
							{char.name}
						</div>
					))}
				</div>
			)}
		</Slate>
	);
};

const withMentions = (editor: any) => {
	const { isInline, isVoid, markableVoid } = editor;

	editor.isInline = (element: any) => {
		return element.type === "mention" ? true : isInline(element);
	};

	editor.isVoid = (element: any) => {
		return element.type === "mention" ? true : isVoid(element);
	};

	editor.markableVoid = (element: any) => {
		return element.type === "mention" || markableVoid(element);
	};

	return editor;
};

const insertMention = (editor: any, character: any) => {
	const mention: MentionElement = {
		type: "mention",
		character,
		children: [{ text: "" }],
	};

	Transforms.insertNodes(editor, mention);
	Transforms.move(editor);
};

// Borrow Leaf renderer from the Rich Text example.
// In a real project you would get this via `withRichText(editor)` or similar.
const Leaf = ({ attributes, children, leaf }: any) => {
	if (leaf.bold) {
		children = <strong>{children}</strong>;
	}

	if (leaf.code) {
		children = <code>{children}</code>;
	}

	if (leaf.italic) {
		children = <em>{children}</em>;
	}

	if (leaf.underline) {
		children = <u>{children}</u>;
	}

	return <span {...attributes}>{children}</span>;
};

const Element = (props: any) => {
	const { attributes, children, element } = props;

	switch (element.type) {
		case "mention":
			return <Mention {...props} />;
		default:
			return <p {...attributes}>{children}</p>;
	}
};

const Mention = ({ attributes, children, element }: any) => {
	const selected = useSelected();
	const focused = useFocused();
	const style: React.CSSProperties = {
		padding: "3px 3px 2px",
		margin: "0 1px",
		verticalAlign: "baseline",
		display: "inline-block",
		borderRadius: "4px",
		backgroundColor: "#eee",
		fontSize: "0.9em",
		boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none",
	};

	// See if our empty text child has any styling marks applied and apply those
	if (element.children[0].bold) {
		style.fontWeight = "bold";
	}

	if (element.children[0].italic) {
		style.fontStyle = "italic";
	}

	return (
		<span
			{...attributes}
			contentEditable={false}
			data-cy={`mention-${element.character.replace(" ", "-")}`}
			style={style}
		>
			<>
				{children}
				{"{"}
				{element.character}
				{"}"}
			</>
		</span>
	);
};

export default PromptEditor;
