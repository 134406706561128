import { ChangeEvent, FunctionComponent } from "react";

import { CircularProgress } from "@mui/material";

import { Link } from "react-router-dom";

import { faLink } from "@fortawesome/pro-regular-svg-icons";

import { InputField } from "@/components/InputField/InputField";
import { CustomColumnFrequency, CustomColumnTypes } from "@/enum/list.enum";
import { classNames } from "@/utils/classNames";

import { Icon } from "@/components";

import { useUpdateColumnValueMutation } from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { TrackerOrganization } from "../../schema/trackerOrganizations";
import { ColumnValue } from "../../../List/schema/columnValue";
import { ColumnConfig } from "../../../List/schema/columnConfig";

export interface CustomTextColumnProps {
	showEdit?: { id: number; name: string };
	columnValue?: ColumnValue;
	columnConfig: ColumnConfig;
	row: TrackerOrganization;
	customColumnTextValue?: string;
	setCustomColumnTextValue: (value: string) => void;
	setShowEdit: (value: { id: number; name: string }) => void;
	type: CustomColumnTypes;
}

export const CustomLinkColumn: FunctionComponent<CustomTextColumnProps> = ({
	showEdit,
	columnValue,
	columnConfig,
	row,
	customColumnTextValue,
	setCustomColumnTextValue,
	setShowEdit,
	type,
}) => {
	const [updatePerson, { isLoading }] = useUpdateColumnValueMutation();

	const testForUrl = (url: string) => {
		try {
			const correctUrl = new URL(url);

			if (correctUrl.protocol === "http:" || correctUrl.protocol === "https:") {
				return true;
			}

			return false;
		} catch {
			return false;
		}
	};

	if (isLoading) {
		return <CircularProgress />;
	}

	const currentTextValue: string = columnValue?.value || "";

	if (showEdit?.id === row.id && showEdit?.name === columnConfig.name) {
		return (
			<InputField
				autoFocus
				handleBlur={() => {
					setShowEdit({ id: 0, name: "" });
				}}
				handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
					setCustomColumnTextValue(event.target.value);
				}}
				name={columnConfig.name}
				value={customColumnTextValue || ""}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						updatePerson({
							id: row.id,
							columnId: columnConfig.id,
							customColumnName: columnConfig.name,
							value: customColumnTextValue || "",
							customColumnType: type,
							frequency: CustomColumnFrequency.ONCE,
						});
						setCustomColumnTextValue("");
						setShowEdit({ id: 0, name: "" });
					}
				}}
			/>
		);
	} else {
		return (
			<div className="flex">
				{testForUrl(currentTextValue) && (
					<Link className="mr-1" target="_blank" to={currentTextValue}>
						<Icon icon={faLink} />
					</Link>
				)}
				<button
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();

						setCustomColumnTextValue(columnValue?.value || "");

						setShowEdit({ id: row.id, name: columnConfig.name });
					}}
				>
					{
						<div
							className={classNames(
								"h-[26px] min-w-[100px] cursor-pointer hover:bg-brand-light hover:opacity-[0.7] leading-tight hover:text-white rounded p-1",
								"text-left"
							)}
						>
							{isLoading ? customColumnTextValue : columnValue?.value || ""}
						</div>
					}
				</button>
			</div>
		);
	}
};
