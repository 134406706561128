import { FunctionComponent, useMemo } from "react";

import { LoadingOverlay } from "@/components";

import { useGetTrackerColumnByIdQuery } from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { EditCustomColumnModal } from "./EditCustomColumnModal";

export interface EditCustomColumnModalWrapperProps {
	setOpen: (value: boolean) => void;
	open: boolean;
	trackerId: number;
	columnId: number;
}

export const EditCustomColumnModalWrapper: FunctionComponent<EditCustomColumnModalWrapperProps> = ({
	open,
	setOpen,
	trackerId,
	columnId,
}) => {
	const { data } = useGetTrackerColumnByIdQuery(columnId);

	const datWithoutId = useMemo(() => {
		if (data) {
			const { id, ...rest } = data;

			return rest;
		}
	}, [data]);

	return !datWithoutId ? (
		<LoadingOverlay />
	) : datWithoutId ? (
		<EditCustomColumnModal
			column={datWithoutId}
			columnId={columnId}
			open={open}
			setOpen={setOpen}
			trackerId={trackerId}
		/>
	) : (
		<></>
	);
};
