import { FunctionComponent, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";

import { Modal } from "@/components";

import {
	useLazyGetTrackerQuery,
	useDeleteTrackerFileMutation,
} from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { TrackerSourceType } from "@/enum/tracker.enum";

import { Tracker, TrackerFile } from "../schema/profileTracker";

import { getCustomTableColumns } from "../utils/addedSearchTableConfigs";

export interface AddedSourceTrackerProps {
	tracker: Tracker;
	handleSelect: (map: TrackerFile) => void;
	handleDuplicate?: (map: TrackerFile) => void;
}

export const AddedSourceTracker: FunctionComponent<AddedSourceTrackerProps> = ({
	tracker,
	handleSelect,
	handleDuplicate,
}) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`tracker.sources.${key}`), [t]);

	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

	const [selectedMap, setSelectedMap] = useState<TrackerFile | null>(null);

	const [deleteTrackerFile] = useDeleteTrackerFileMutation();

	const [refreshTracker] = useLazyGetTrackerQuery();

	const handleRemove = async (row: TrackerFile) => {
		await deleteTrackerFile(row.id).unwrap();
	};

	const handleDeleteModal = (row: TrackerFile) => {
		setShowDeleteModal(true);
		setSelectedMap(row);
	};

	const handleDeleteMap = async () => {
		try {
			if (tracker.sourceType === TrackerSourceType.XLSX) {
				// await deleteMap({ id: selectedMap?.id ?? 0, trackerId: tracker.id }).unwrap();
			}

			setShowDeleteModal(false);
			refreshTracker(tracker.id);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<div className="w-full mt-8 bg-brand-white p-4 rounded-xl border border-gray-200">
			<div className="text-md mb-8">Added searches</div>
			<div className="w-full bg-white border border-divider rounded-xl">
				<table className="w-full">
					<thead className="border-b border-b-divider">
						{getCustomTableColumns({
							type: tracker.sourceType,
							handleSelect,
							handleDuplicate,
							handleDeleteModal,
							t,
						})?.map((column) => {
							return (
								<th
									key={column.uid}
									className="text-left text-table-header text-xs px-4 items-center h-[44px]"
								>
									{column.title}
								</th>
							);
						})}
					</thead>
					<tbody>
						{[...(tracker.trackerFiles || [])].map((item, index) => {
							return (
								<tr key={index} className="border-b border-b-divider">
									{getCustomTableColumns({
										type: tracker.sourceType,
										handleSelect,
										handleDuplicate,
										handleDeleteModal,
										handleRemove,
										t,
									})?.map((column) => {
										return (
											<td
												key={column.uid}
												className="px-4 items-center h-[44px] text-sm font-medium"
											>
												{column.render(item)}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteMap}
					// isLoading={}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedMap?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}
		</div>
	);
};
