import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Stack, TextField } from "@mui/material";

import { format } from "date-fns";

import { useNavigate, useSearchParams } from "react-router-dom";

import SquareCheck from "@assets/icons/list-builder/square-check.svg";
import Square from "@assets/icons/list-builder/square.svg";
import { ReactComponent as MagnifyingGlass } from "@assets/icons/list-builder/magnifying-glass.svg";

import { ReactComponent as Plus } from "@assets/icons/plus.svg";

import { ReactComponent as ThreeDots } from "@assets/icons/three-dots.svg";

import { LoadingOverlay, Modal, PermissionCheck } from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import { Toggle } from "@/components/Toggle/Toggle";

import { useStickyParams } from "@/utils/useStickyParams";

import { Button } from "@/components/Button/Button";

import { ButtonColor, ButtonSize } from "@/components/Button/types";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { Table, TableColumn } from "@/components/Table/Table";
import {
	profileTrackerSelector,
	setProfileTrackerSorting,
} from "@/pages/Private/redux/profileTracker/profileTracker.slice";

import { Order, SortParams } from "@/types/types";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";

import { classNames } from "@/utils/classNames";
import { ContextMenu, MenuItem } from "@/components/ContextMenu/ContextMenu";

import { SvgIcon } from "@/components/Icon/SvgIcon";

import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";

import {
	useDeleteTrackerMutation,
	useGetProfileTrackersQuery,
	useUpdateTrackerMutation,
} from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { ListRunningStatus, ListStatus } from "@/enum/list.enum";

import { Tracker, UpdateTracker } from "../schema/profileTracker";
import { DownloadFile } from "../../List/schema/list";

export const ProfileTrackerTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`profileTracker.${key}`), [t]);

	const [searchParams] = useSearchParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

	const [selectedProfileTracker, setSelectedProfileTracker] = useState<Tracker | null>(null);

	const { searchValue, setSearchValue, query } = useSearch();

	const handleSorting = (sortParams: SortParams) => dispatch(setProfileTrackerSorting(sortParams));
	const {
		sortingProfileTracker,
		profileTracker: data,
		profileTrackerMeta,
	} = useAppSelector(profileTrackerSelector);

	const [selectAll, setSelectAll] = useState<boolean>(false);

	const [selectedFiles, setSelectedFiles] = useState<DownloadFile[]>([]);

	const [showOnlyRunning, setShowOnlyRunning] = useState<boolean | undefined>(undefined);

	const [deleteTracker, { isLoading: deleteIsLoading }] = useDeleteTrackerMutation();
	const [updateTracker] = useUpdateTrackerMutation();

	const signalsIn: AutoCompleteItem[] = [
		{
			id: "LAST_24H",
			title: `${ts("LAST_24H")}`,
		},
		{
			id: "LAST_7D",
			title: `${ts("LAST_7D")}`,
		},
		{
			id: "LAST_30D",
			title: `${ts("LAST_30D")}`,
		},
		{
			id: "LAST_90D",
			title: `${ts("LAST_90D")}`,
		},
		{
			id: "LAST_180D",
			title: `${ts("LAST_180D")}`,
		},
		{
			id: "LAST_365D",
			title: `${ts("LAST_365D")}`,
		},
	];

	const [selectedSignalsIn, setSelectedSignalsIn] = useState<AutoCompleteItem>(signalsIn[1]);

	const [localLoading, setLocalLoading] = useState(false);
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (searchParams.has("profileTracker")) {
			const cProfileTrackerName = searchParams.get("profileTracker");

			if (cProfileTrackerName) {
				setSearchValue(cProfileTrackerName);
			}
		}
	}, [searchParams, setSearchValue]);

	useStickyParams("profileTracker", setSearchValue, searchValue);

	const [sortState, setSortState] = useState({ sortBy: "", sortOrder: 0 });

	useEffect(() => {
		if (sortingProfileTracker.sortBy && sortingProfileTracker.sortOrder) {
			let cSortOrder = 0;

			if (sortingProfileTracker.sortOrder === Order.ASC) {
				cSortOrder = 1;
			}

			if (sortingProfileTracker.sortOrder === Order.DESC) {
				cSortOrder = 2;
			}

			setSortState({
				sortBy: sortingProfileTracker.sortBy,
				sortOrder: cSortOrder,
			});
		}
	}, [sortingProfileTracker]);

	const { isLoading, isFetching } = useGetProfileTrackersQuery(
		{
			page,
			limit: 15,
			searchValue: query,
			active: showOnlyRunning,
			sorting: sortingProfileTracker,
			signalsIn: selectedSignalsIn?.id as string,
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const [prevParams, setPrevParams] = useState({
		query,
		showOnlyRunning,
	});

	useEffect(() => {
		if (localLoading) {
			setLocalLoading(false);
		}

		if (data) {
			if (showOnlyRunning !== prevParams.showOnlyRunning || query !== prevParams.query) {
				setPage(1);
			}
		}

		setPrevParams({ query, showOnlyRunning });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, query]);

	useEffect(() => {
		if (selectAll) {
			setSelectedFiles(data?.map((item) => ({ id: item.id, name: item.name })) || []);
		} else {
			setSelectedFiles([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAll, data]);

	const toggleSelectAll = () => {
		setSelectAll((prevSelectAll) => {
			if (prevSelectAll) {
				setSelectAll(false);

				return false;
			} else {
				setSelectAll(true);

				return true;
			}
		});
	};

	const handleToggle = useCallback(async (id: number, status: ListStatus) => {
		const updater: Partial<UpdateTracker> = {
			id: id,
		};

		if (status === ListStatus.ACTIVE) {
			updater.activeStatus = ListStatus.PAUSED;
		} else if (status === ListStatus.PAUSED) {
			updater.activeStatus = ListStatus.ACTIVE;
		}

		await updateTracker({ ...updater }).unwrap();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tableColumns: TableColumn[] = useMemo(() => {
		const columns: TableColumn[] = [
			{
				title: "",
				uid: "select",
				width: "52px",
				svgIcon: selectAll ? SquareCheck : Square,
				show: true,
				onHeaderClick: () => {
					toggleSelectAll();
				},
				render: (row: Tracker, index?: number) => (
					<div className="group flex items-center">
						<div
							className={classNames(
								"w-[16px] text-center font-medium text-table-header",
								selectedFiles?.find((lf) => lf.id === row.id) ? "hidden" : "group-hover:hidden"
							)}
						>
							{typeof index !== "undefined" ? index + 1 : ""}
						</div>
						<Checkbox
							className={classNames(
								selectedFiles?.find((lf) => lf.id === row.id) ? "block" : "hidden group-hover:block"
							)}
							isChecked={!!selectedFiles?.find((lf) => lf.id === row.id)}
							name={"selected"}
							onChange={() => {
								// handleSelectFile({ id: row.id, name: row.name });
							}}
						/>
					</div>
				),
			},
			{
				title: ts("status.title"),
				width: "100px",
				orderTitle: "status",
				orderStatus: sortState.sortBy === "status" ? sortState.sortOrder : 0,
				show: true,
				render: (row: Tracker) => (
					<div>
						<Toggle
							isChecked={row?.activeStatus === ListStatus.ACTIVE}
							onChange={() => {
								if (row.id && row.activeStatus !== ListStatus.ERROR) {
									handleToggle(row.id, row.activeStatus);
								}
							}}
						/>
					</div>
				),
			},
			{
				title: ts("name"),
				maxWidth: "330px",
				orderTitle: "name",
				orderStatus: sortState.sortBy === "name" ? sortState.sortOrder : 0,
				uid: "name",
				render: (row: Tracker) => (
					<div className="flex">
						<Link href={`/app/profile-tracker/detail/${row?.id}`}>{row?.name}</Link>
					</div>
				),
			},
			{
				title: ts("signals"),
				maxWidth: "330px",
				orderTitle: "signals",
				orderStatus: sortState.sortBy === "signals" ? sortState.sortOrder : 0,
				uid: "signals",
				render: (row: Tracker) => <div className="flex">{row.signals}</div>,
			},

			{
				title: ts("lastModifiedAt"),
				width: "140px",
				orderTitle: "updatedAt",
				orderStatus: sortState.sortBy === "updatedAt" ? sortState.sortOrder : 0,
				uid: "lastModifiedAt",
				render: (row: Tracker) => (
					<div className="flex">
						<div className="text-sm">{format(new Date(row?.updatedAt), "dd.MM.yyyy HH:ii")}</div>
					</div>
				),
			},

			{
				title: "",
				minWidth: 56,
				render: (row: Tracker) => {
					const options: MenuItem[] = [
						{
							title: ts("editProfileTracker"),
							onClick: () => {
								navigate(`/app/profile-tracker/edit/${row.id}`);
							},
						},
						// {
						// 	title: ts("resetList"),
						// 	permissions: [PermissionRoles.GLOBAL_ADMIN],
						// 	onClick: () => {
						// 		handleResetModal(row);
						// 	},
						// },
						{
							title: ts("deleteProfileTracker"),
							permissions: [PermissionRoles.TRACKER],
							onClick: () => {
								handleDeleteModal(row);
							},
						},
					];

					return (
						<Stack direction="row" justifyContent="flex-end" spacing={2}>
							<ContextMenu data={[options]} position="bottom-right" width="150">
								<SvgIcon
									className="w-[20px] h-[20px] text-gray-700 cursor-pointer"
									svgIcon={ThreeDots}
								/>
							</ContextMenu>
						</Stack>
					);
				},
			},
		];

		return columns;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAll, ts, t, toggleSelectAll]);

	const handleSelectStatus = (value: AutoCompleteItem) => {
		if (value.id === ListRunningStatus.ALL) {
			setShowOnlyRunning(undefined);
		} else if (value.id === ListRunningStatus.ACTIVE) {
			setShowOnlyRunning(true);
		} else if (value.id === ListRunningStatus.PAUSED) {
			setShowOnlyRunning(false);
		}
	};

	function getActiveStatus(): string | number | null {
		if (showOnlyRunning) {
			return ListRunningStatus.ACTIVE;
		}

		if (showOnlyRunning === false) {
			return ListRunningStatus.PAUSED;
		}

		return ListRunningStatus.ALL;
	}

	const getSignalsIn = (): AutoCompleteItem => {
		if (selectedSignalsIn) {
			return selectedSignalsIn;
		}

		return signalsIn[0];
	};

	function getActivityStatusText(): string {
		if (showOnlyRunning) {
			return ts("activity.running");
		}

		if (showOnlyRunning === false) {
			return ts("activity.stopped");
		}

		return ts("activity.all");
	}

	const handleDeleteModal = (row: Tracker) => {
		setShowDeleteModal(true);
		setSelectedProfileTracker(row);
	};

	const handleDeleteTracker = async () => {
		try {
			await deleteTracker(selectedProfileTracker?.id ?? 0).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	const handleFetchMore = () => {
		if (selectAll) {
			toggleSelectAll();
		}

		setPage(page + 1);
	};

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between  mb-8">
				<div className="flex items-end align-baseline justify-between w-1/2">
					<div className="flex-grow mr-4 ">
						<InputField
							handleChange={(e: ChangeEvent<HTMLInputElement>) => {
								setLocalLoading(true);
								setSearchValue(e.target.value);
							}}
							iconInside={<SvgIcon className="text-gray-500" svgIcon={MagnifyingGlass} />}
							name={"search"}
							placeholder={ts("search")}
							value={searchValue}
						/>
					</div>
				</div>

				<div className="flex items-center justify-end gap-2 mb-3">
					<Dropdown
						data={signalsIn}
						defaultValue={signalsIn[0]}
						floating={true}
						handleSelect={(value: AutoCompleteItem) => {
							if (value?.id !== selectedSignalsIn?.id) {
								setSelectedSignalsIn(value);
							}
						}}
						title={ts("signalsIn")}
						value={{
							title: getSignalsIn().title,
							id: getSignalsIn().id,
						}}
					/>
					<Dropdown
						data={[
							{
								title: ts("activity.all"),
								id: ListRunningStatus.ALL,
							},
							{
								title: ts("activity.running"),
								id: ListRunningStatus.ACTIVE,
							},
							{
								title: ts("activity.stopped"),
								id: ListRunningStatus.PAUSED,
							},
						]}
						defaultValue={{
							title: ts("activity.all"),
							id: ListRunningStatus.ALL,
						}}
						floating={true}
						handleSelect={(value: AutoCompleteItem) => {
							handleSelectStatus(value);
						}}
						title={ts("status.title")}
						value={{
							title: getActivityStatusText(),
							id: getActiveStatus(),
						}}
					/>
					<PermissionCheck requiredPermissions={[PermissionRoles.TRACKER]}>
						<Button
							color={ButtonColor.ACTION}
							image={<SvgIcon className="w-[16px] h-[16px] mr-2" svgIcon={Plus} />}
							size={ButtonSize.ML}
							testId="new-user-button"
							title={`New ProfileTracker`}
							onClick={() => navigate("/app/profile-tracker/create")}
						/>
					</PermissionCheck>
				</div>
			</div>

			{isLoading ? <LoadingOverlay /> : ""}
			<div className="relative w-full">
				<div className="pr-4 pb-[20px]">
					<Table
						columns={tableColumns}
						data={data || []}
						handleExport={() => {
							// handleDownloadSelected(); //for export
						}}
						handleFetchMore={() => {
							if (
								profileTrackerMeta?.itemCount &&
								data.length < profileTrackerMeta?.itemCount &&
								!isFetching &&
								page < profileTrackerMeta.pageCount
							) {
								handleFetchMore();
							}
						}}
						handleSorting={(sortParams: SortParams) => {
							setLocalLoading(true);
							setPage(1);
							handleSorting(sortParams);
						}}
						hideColumnsCount={true}
						hidePagination={true}
						isFetching={isFetching}
						isLoading={isLoading || localLoading}
						overallCount={profileTrackerMeta.itemCount}
						page={page}
						totalPages={profileTrackerMeta.pageCount}
					/>
				</div>
			</div>

			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteTracker}
					isLoading={deleteIsLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<div className="text-sm text-gray-700 mb-4">{ts("delete.confirmation")}</div>
					<TextField
						fullWidth
						defaultValue={selectedProfileTracker?.name}
						inputProps={{ readOnly: true }}
						label={ts("name")}
					/>
				</Modal>
			)}
		</div>
	);
};
