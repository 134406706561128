export default {
	suggestions: {
		// prospect
		person_name: "Prospect Name",
		person_email: "Prospect Email",
		person_firstName: "Prospect First Name",
		person_lastName: "Prospect Last Name",
		person_title: "Prospect Title",
		person_seniority: "Prospect Seniority",
		person_linkedinUrl: "Prospect Linkedin Url",
		person_gender: "Prospect Gender",
		person_city: "Prospect City",
		person_country: "Prospect Country",
		person_state: "Prospect State",
		person_headline: "Prospect Headline",
		person_language: "Prospect Language",
		person_enrichments: "Prospect Enrichments",
		person_followers: "Prospect Followers",
		person_connections: "Prospect Connections",
		person_phone: "Prospect Phone",

		organization_name: "Organization Name",
		organization_city: "Organization City",
		organization_state: "Organization State",
		organization_country: "Organization Country",
		organization_industry: "Organization Industry",
		organization_postalCode: "Organization Postal Code",
		organization_foundedYear: "Organization Founded Year",
		organization_annualRevenue: "Organization Annual Revenue",
		organization_estimatedNumEmployees: "Organization Estimated Num Employees",
		organization_primaryDomain: "Organization Primary Domain",
		organization_phone: "Organization Phone",
		organization_seoDescription: "Organization Seo Description",
		organization_shortDescription: "Organization Short Description",
		organization_followerCount: "Organization Follower Count",
		organization_websiteUrl: "Organization Website",
	},
};
