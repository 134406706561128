import { z } from "zod";

import { ListStatus } from "@/enum/list.enum";
import { JobsScrapeStatus } from "@/enum/scrape-enum";
import { TrackerSourceType } from "@/enum/tracker.enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { SortParams } from "@/types/types";
import { ERROR_TYPE } from "@/utils";

import { FilterUnassignedTable } from "../../List/components/UnassignedTable";
import { ColumnConfigSchema } from "../../List/schema/columnConfig";

const { REQUIRED } = ERROR_TYPE;

export const TrackerFileSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	trackerId: z.number(),
	status: z.nativeEnum(JobsScrapeStatus),
	configuration: z.string().transform((arg) => JSON.parse(arg)),
	amount: z.number(),
});

export const TrackerSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	activeStatus: z.nativeEnum(ListStatus),
	sourceType: z.nativeEnum(TrackerSourceType),
	updatedAt: z.string().transform((arg) => new Date(arg)),
	trackerFiles: z.array(TrackerFileSchema),
	columns: z.array(ColumnConfigSchema).optional(),
	signals: z.number().optional().nullable(),
});

export const UpdateTrackerSchema = z.object({
	id: z.number(),
	name: z.string().trim().optional(),
	activeStatus: z.nativeEnum(ListStatus).optional(),
});

export const CreateTrackerFileSchema = z
	.object({
		id: z.number().optional(),
		name: z.string().min(4, { message: REQUIRED }).trim(),
		file: z.string().optional(),
		trackerId: z.number().optional(),
		configuration: z
			.array(
				z.object({
					foundField: z.string(),
					assignedField: z.string(),
				})
			)
			.optional(),
	})
	.refine((input) => {
		if (
			(input.id && input.file === undefined) ||
			(input.id === undefined && input.file !== undefined)
		) {
			return true;
		}

		return false;
	});

export const CreateTrackerSchema = z.object({
	id: z.number().optional(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	sourceType: z.nativeEnum(TrackerSourceType),
});

export const TrackerResponseSchema = z.object({
	data: z.array(TrackerSchema),
	meta: MetaDataSchema,
});

export type Tracker = z.infer<typeof TrackerSchema>;

export type CreateTrackerFile = z.infer<typeof CreateTrackerFileSchema>;

export type TrackerFile = z.infer<typeof TrackerFileSchema>;

export type CreateTracker = z.infer<typeof CreateTrackerSchema>;

export type TrackerResponse = z.infer<typeof TrackerResponseSchema>;

export type UpdateTracker = z.infer<typeof UpdateTrackerSchema>;

export interface TrackerRequestPaginationParams {
	page: number;
	limit: number;
	searchValue?: string;
	companySearch?: string;
	sorting?: SortParams;
	trackerId?: number;
	filter?: FilterUnassignedTable;
}
