import { faBuilding, faCalendarCircleUser } from "@fortawesome/pro-regular-svg-icons";

import i18n from "@/i18n";
import { ROUTE_CONFIG } from "@/routes/config";

import { Icon } from "@/components";
import { PermissionRoles } from "@/enum";

import { ReactComponent as Gear } from "@assets/icons/sidebar/gear.svg";
import { ReactComponent as ScrewDriverWrench } from "@assets/icons/sidebar/screw-driver-wrench.svg";
import { ReactComponent as ProfileTrackerIcon } from "@assets/icons/sidebar/profileTracker.svg";
import { ReactComponent as CrossedCircle } from "@assets/icons/inbox/crossed-circle.svg";

import { SvgIcon } from "@/components/Icon/SvgIcon";

export interface SidenavItem {
	title: string;
	path: string;
	onClick?: () => void;
	strictPath?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	icon?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	iconActive?: any;
	type?: string;
	permission?: PermissionRoles;
	companyIds?: number[];
}

interface NavItemInner {
	upper: SidenavItem[];
	middle?: SidenavItem[];
	secondMiddle?: SidenavItem[];
	lower: SidenavItem[];
}

interface NavItemConfig {
	ALL: NavItemInner;
}

export const useNavigationGetter = (): NavItemConfig => {
	return {
		ALL: {
			upper: [
				{
					title: i18n.t("sidebar.listBuilder"),
					path: ROUTE_CONFIG.LISTS,
					icon: (
						<SvgIcon className="w-[24px] h-[24px]  text-gray-700" svgIcon={ScrewDriverWrench} />
					),
					iconActive: (
						<SvgIcon className="w-[24px] h-[24px] text-brand-light" svgIcon={ScrewDriverWrench} />
					),
					type: "icon",
				},
				{
					title: i18n.t("sidebar.profileTracker"),
					path: ROUTE_CONFIG.PROFILE_TRACKER,
					icon: (
						<SvgIcon className="w-[24px] h-[24px]  text-gray-700" svgIcon={ProfileTrackerIcon} />
					),
					iconActive: (
						<SvgIcon className="w-[24px] h-[24px] text-brand-light" svgIcon={ProfileTrackerIcon} />
					),
					permission: PermissionRoles.GLOBAL_ADMIN,
					type: "icon",
				},
				{
					title: i18n.t("sidebar.customEvent"),
					path: ROUTE_CONFIG.CUSTOM_EVENTS,
					icon: <Icon className="w-[24px] h-[24px]" icon={faCalendarCircleUser} />,
					permission: PermissionRoles.GLOBAL_ADMIN,
					type: "icon",
					companyIds: [1],
				},
			],
			secondMiddle: [
				{
					title: i18n.t("sidebar.campaigns"),
					path: ROUTE_CONFIG.CAMPAIGNS,
					type: "bullet",
					strictPath: true,
				},
				{
					title: i18n.t("sidebar.replies"),
					path: ROUTE_CONFIG.REPLIES,
					type: "bullet",
					strictPath: true,
				},
				{
					title: i18n.t("sidebar.noReply"),
					path: ROUTE_CONFIG.NO_REPLY,
					type: "bullet",
					strictPath: true,
				},
				{
					title: i18n.t("sidebar.toBeNurtured"),
					path: ROUTE_CONFIG.TO_BE_NURTURED,
					type: "bullet",
					strictPath: true,
				},
				{
					title: i18n.t("sidebar.doNotContact"),
					path: ROUTE_CONFIG.DO_NOT_CONTACT,
					type: "bullet",
					strictPath: true,
				},
			],
			lower: [
				{
					title: i18n.t("sidebar.globalBlacklist"),
					path: ROUTE_CONFIG.BLACKLIST_GLOBAL,
					icon: (
						<SvgIcon
							active={false}
							className="w-[24px] h-[24px]  text-gray-700"
							svgIcon={CrossedCircle}
						/>
					),
					iconActive: (
						<SvgIcon
							active={true}
							className="w-[24px] h-[24px] text-brand-light"
							svgIcon={CrossedCircle}
						/>
					),
					permission: PermissionRoles.GLOBAL_ADMIN,
					type: "icon",
					companyIds: [1],
				},
				{
					title: i18n.t("sidebar.companies"),
					path: ROUTE_CONFIG.COMPANIES,
					icon: <Icon className="w-[24px] h-[24px]" icon={faBuilding} />,
					type: "icon",
					permission: PermissionRoles.GLOBAL_ADMIN,
					companyIds: [1],
				},
				{
					title: i18n.t("sidebar.accountSettings"),
					path: ROUTE_CONFIG.COMPANY,
					icon: <SvgIcon className="w-[24px] h-[24px]  text-gray-700" svgIcon={Gear} />,
					iconActive: <SvgIcon className="w-[24px] h-[24px] text-brand-light" svgIcon={Gear} />,
					type: "icon",
				},
			],
		},
	};
};
