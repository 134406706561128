/* eslint-disable @typescript-eslint/no-explicit-any */
import { flatten } from "flat";

import { Order, SortParams } from "@/types/types";

import { TrackerOrganization } from "../schema/trackerOrganizations";
import { TrackerPerson } from "../schema/trackerPerson";

export const replaceWithRealValues = ({
	value,
	inboxItem,
	suggestions,
	person,
}: {
	value: any | undefined;
	inboxItem: TrackerOrganization;
	suggestions: string[];
	person?: TrackerPerson;
}) => {
	console.log(value);

	if (!value) {
		return "";
	}

	const textValue = value.flatMap(
		(el: any) =>
			el.children?.map((n: any) => {
				const text = n.text || (n.character !== undefined ? `{${n.character}}` : "");

				return text;
			}) || []
	);

	let result = "";
	let isListItem = false;

	for (const text of textValue) {
		if (text === "") {
			if (result) {
				result += "\n";
			}
		} else if (text.startsWith("- ") || text.startsWith("* ")) {
			if (result) {
				result += "\n";
			}

			result += text;
			isListItem = true;
		} else if (text.startsWith("{")) {
			result += text;
		} else {
			if (result && !result.endsWith("}") && !isListItem) {
				result += "\n";
			}

			result += text;
		}
	}

	let resultTextValue = result.trim();

	for (const suggestion of suggestions) {
		const placeholder = `{${suggestion}}`;
		const objectPath = suggestion.split("_");
		let realValue: any = inboxItem;

		if (person) {
			realValue = {
				...realValue,
				person: person.prospect,
			};
		}

		if (realValue && realValue.holdingOrganization && !realValue.organization) {
			realValue = {
				...realValue,
				organization: {
					...realValue.holdingOrganization,
					name: realValue.name,
				},
			};
		}

		for (const path of objectPath) {
			if (realValue) {
				realValue = Array.isArray(realValue[path]) ? realValue[path][0] : realValue[path];
			}
		}

		if (resultTextValue.includes(placeholder)) {
			resultTextValue = resultTextValue.replaceAll(placeholder, String(realValue));
		}
	}

	return resultTextValue;
};

export const getSuggestions = (inboxItem: TrackerOrganization, person?: TrackerPerson) => {
	const mySuggestions: any = {
		organization: inboxItem.organization || inboxItem.holdingOrganization,
		person: person?.prospect,
	};

	if (inboxItem.columns) {
		inboxItem.columns.forEach((column) => {
			mySuggestions[column.columnConfig.name] = inboxItem.columns.find(
				(columnValue) => columnValue.columnConfigId === column.columnConfig.id
			)?.value;
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const res: any = flatten(mySuggestions, {
		delimiter: "_",
	});

	return Object.keys(res).filter((key) => !Array.isArray(res[key]));
};

export const getOrderStatus = (sortingInbox: SortParams, orderTitle: string) => {
	const hasCurrentSorting = sortingInbox.sortBy === orderTitle;

	let orderStatus = 0;

	if (hasCurrentSorting && sortingInbox.sortOrder === Order.ASC) {
		orderStatus = 1;
	}

	if (hasCurrentSorting && sortingInbox.sortOrder === Order.DESC) {
		orderStatus = 2;
	}

	return orderStatus;
};
