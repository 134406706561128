export default {
	suggestions: {
		// prospect
		prospect_name: "Prospect Name",
		prospect_email: "Prospect Email",
		prospect_firstName: "Prospect First Name",
		prospect_lastName: "Prospect Last Name",
		prospect_title: "Prospect Title",
		prospect_seniority: "Prospect Seniority",
		prospect_linkedinUrl: "Prospect Linkedin Url",
		prospect_gender: "Prospect Gender",
		prospect_city: "Prospect City",
		prospect_country: "Prospect Country",
		prospect_state: "Prospect State",
		prospect_headline: "Prospect Headline",
		prospect_language: "Prospect Language",
		prospect_enrichments: "Prospect Enrichments",
		prospect_followers: "Prospect Followers",
		prospect_connections: "Prospect Connections",
		prospect_phone: "Prospect Phone",

		//prospect_organization
		prospect_organization_name: "Prospect Organization Name",
		prospect_organization_city: "Prospect Organization City",
		prospect_organization_state: "Prospect Organization State",
		prospect_organization_country: "Prospect Organization Country",
		prospect_organization_industry: "Prospect Organization Industry",
		prospect_organization_postalCode: "Prospect Organization Postal Code",
		prospect_organization_foundedYear: "Prospect Organization Founded Year",
		prospect_organization_annualRevenue: "Prospect Organization Annual Revenue",
		prospect_organization_estimatedNumEmployees: "Prospect Organization Estimated Num Employees",
		prospect_organization_primaryDomain: "Prospect Organization Primary Domain",
		prospect_organization_phone: "Prospect Organization Phone",
		prospect_organization_seoDescription: "Prospect Organization Seo Description",
		prospect_organization_shortDescription: "Prospect Organization Short Description",
		prospect_organization_followerCount: "Prospect Organization Follower Count",
		prospect_organization_websiteUrl: "Prospect Organization Website",

		// liCompanyActivity
		liCompanyActivityEntryResult_content: "Company Activity Content",
		liCompanyActivityEntryResult_contentResponse: "Company Activity Content Response",
		liCompanyActivityEntryResult_contentDate: "Company Activity Content Date",

		//liProfile
		liProfileEntryResult_content: "Profile Content",
		liProfileEntryResult_contentResponse: "Profile Content Response",
		liProfileEntryResult_contentDate: "Profile Content Date",

		//liPost
		liPostResult_content: "Post Content",
		liPostResult_contentDate: "Post Content Date",
		liPostResult_likes: "Post Likes",
		liPostResult_comments: "Post Comments",
		liPostResult_shares: "Post Shares",

		//liEvent
		liEventEntry_name: "Event Name",
		liEventEntry_url: "Event Url",
		liEventEntry_description: "Event Description",
		liEventEntry_companyName: "Event Company Name",
		liEventEntry_attendees: "Event Attendees",
		liEventEntry_eventAt: "Event at",

		//offer
		offer_name: "Offer Name",
		offer_firstName: "Offer First Name",
		offer_lastName: "Offer Last Name",
		offer_linkedinUrl: "Offer linkedinUrl",
		offer_jobTitle: "Offer Job Title",
		offer_offerLink: "Offer Link",
		offer_description: "Offer Description",
		offer_employmentType: "Offer Employment Type",
		offer_jobLocation: "Offer Job Location",
		offer_jobCity: "Offer Job City",
		offer_jobState: "Offer Job State",
		offer_jobCountry: "Offer Job Country",
		offer_postedAt: "Offer Posted At",
		offer_email: "Offer Email",
		offer_companyEmail: "Offer Company Email",
		offer_companyLocation: "Offer Company Location",
		offer_companyCity: "Offer Company City",
		offer_companyState: "Offer Company State",
		offer_companyCountry: "Offer Company Country",
		offer_companyName: "Offer Company Name",
		offer_cleanedCompanyName: "Offer Cleaned Company Name",
		offer_companyDomain: "Offer Company Domain",
		offer_companyLinkedinUrl: "Offer Company Linkedin Url",
		offer_title: "Offer Title",
		offer_headline: "Offer Headline",
		offer_city: "Offer City",
		offer_state: "Offer State",
		offer_country: "Offer Country",
		offer_phoneNumber: "Offer Phone Number",

		//mapbusiness
		mapBusiness_name: "Map Business Name",
		mapBusiness_phoneNumber: "Map Business Phone Number",
		mapBusiness_lat: "Map Business Latitude",
		mapBusiness_lng: "Map Business Longitude",
		mapBusiness_fullAddress: "Map Business Full Address",
		mapBusiness_reviewCount: "Map Business Review Count",
		mapBusiness_rating: "Map Business Rating",
		mapBusiness_timezone: "Map Business Timezone",
		mapBusiness_verified: "Map Business Verified",
		mapBusiness_placeLink: "Map Business Place Link",
		mapBusiness_reviewsLink: "Map Business Reviews Link",
		mapBusiness_ownerLink: "Map Business Owner Link",
		mapBusiness_ownerName: "Map Business Owner Name",
		mapBusiness_bookingLink: "Map Business Booking Link",
		mapBusiness_reservationLink: "Map Business Reservation Link",
		mapBusiness_subtypes: "Map Business Subtypes",
		mapBusiness_summary: "Map Business Summary",
		mapBusiness_details: "Map Business Details",
		mapBusiness_mapBusinessLink: "Map Business Link",
		mapBusiness_firstName: "Map Business First Name",
		mapBusiness_lastName: "Map Business Last Name",
		mapBusiness_linkedinUrl: "Map Business Linkedin Url",
		mapBusiness_facebookUrl: "Map Business Facebook Url",
		mapBusiness_instagramUrl: "Map Business Instagram Url",
		mapBusiness_yelpUrl: "Map Business Yelp Url",
		mapBusiness_tiktokUrl: "Map Business Tiktok Url",
		mapBusiness_snapchatUrl: "Map Business Snapchat Url",
		mapBusiness_twitterUrl: "Map Business Twitter Url",
		mapBusiness_githubUrl: "Map Business Github Url",
		mapBusiness_youtubeUrl: "Map Business Youtube Url",
		mapBusiness_pinterestUrl: "Map Business Pinterest Url",
		mapBusiness_email: "Map Business Email",
		mapBusiness_website: "Map Business Website",
		mapBusiness_companyEmail: "Map Business Company Email",
		mapBusiness_companyCity: "Map Business Company City",
		mapBusiness_companyState: "Map Business Company State",
		mapBusiness_companyCountry: "Map Business Company Country",
		mapBusiness_companyName: "Map Business Company Name",
		mapBusiness_companyDomain: "Map Business Company Domain",
		mapBusiness_title: "Map Business Title",
		mapBusiness_headline: "Map Business Headline",
		mapBusiness_location: "Map Business Location",
		mapBusiness_city: "Map Business City",
		mapBusiness_state: "Map Business State",
		mapBusiness_country: "Map Business Country",
		mapBusiness_status: "Map Business Enrichment Status",
		mapBusiness_type: "Map Business Type",
		mapBusiness_companyLinkedinUrl: "Map Business Company Linkedin Url",
		mapBusiness_gender: "Map Business Gender",
		mapBusiness_genderProbability: "Map Business Gender Probability",

		//mapBusiness_map
		mapBusiness_map_name: "Map Name",
		mapBusiness_map_query: "Map Query",
		mapBusiness_map_limit: "Map Limit",
		mapBusiness_map_lat: "Map Latitude",
		mapBusiness_map_lng: "Map Longitude",
		mapBusiness_map_zoom: "Map Zoom",
		mapBusiness_map_language: "Map Language",
		mapBusiness_map_region: "Map Region",
		mapBusiness_map_subtypes: "Map Subtypes",
	},
};
