import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

import { PeopleSchema } from "../../Apollo/schema/get-people";

export const TrackerPersonSchema = z.object({
	id: z.number(),
	name: z.string(),
	prospectId: z.number(),
	trackerOrganizationId: z.number(),
	trackerId: z.number(),
	prospect: PeopleSchema,
});

export const TrackerPersonResponseSchema = z.object({
	data: z.array(TrackerPersonSchema),
	meta: MetaDataSchema,
});

export type TrackerPerson = z.infer<typeof TrackerPersonSchema>;

export type TrackerPersonResponse = z.infer<typeof TrackerPersonResponseSchema>;
