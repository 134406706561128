import { ChangeEvent, FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert } from "@mui/material";

import {
	useCreateLiCompanyActivityMutation,
	useUpdateLiCompanyActivityMutation,
} from "@/pages/Private/redux/liCompanyActivity/liCompanyActivity.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { Button } from "@/components/Button/Button";

import { ButtonColor } from "@/components/Button/types";

import { InputField } from "@/components/InputField/InputField";

import {
	CreateLiCompanyActivity,
	CreateLiCompanyActivitySchema,
	LiCompanyActivity,
} from "../schema/liCompanyActivity";
import { List } from "../../List/schema/list";

interface CreateLiCompanyActivityFormProps {
	company?: LiCompanyActivity | null;
	list: List;
	handleSelect?: (company: LiCompanyActivity | null) => void;
	handleDuplicate?: (company: LiCompanyActivity | null) => void;
}

export const CreateLiCompanyActivityForm: FunctionComponent<CreateLiCompanyActivityFormProps> = ({
	company,
	list,
	handleSelect,
	handleDuplicate,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liCompanyActivity.${key}`);
	const [createLiCompanyActivity, { isLoading, error }] = useCreateLiCompanyActivityMutation();
	const [updateLiCompanyActivity] = useUpdateLiCompanyActivityMutation();

	const {
		handleSubmit,
		reset,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<CreateLiCompanyActivity>({
		defaultValues: {
			name: "",
			url: "",
			scrapeFollowers: true,
		},
		resolver: zodResolver(CreateLiCompanyActivitySchema),
	});

	useEffect(() => {
		if (company) {
			const formState = {
				name: company.name,
				url: company.url,
				scrapeFollowers: true,
			};

			if (handleDuplicate) {
				reset({
					...formState,
				});
			} else {
				reset({
					...formState,
					id: company.id,
				});
			}
		} else {
			reset();
		}
	}, [list.scrapeLevel, company, reset, handleDuplicate]);

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: CreateLiCompanyActivity) => {
		try {
			if (company?.id && !handleDuplicate) {
				await updateLiCompanyActivity({
					...values,
					id: company.id,
					listId: list.id,
				}).unwrap();
			} else {
				await createLiCompanyActivity({
					...values,
					listId: list.id,
				}).unwrap();
			}

			handleSelect?.(null);
			handleDuplicate?.(null);
		} catch (err) {
			console.error(err);
		}
	};

	const buttonTitle =
		handleDuplicate && company?.id
			? t("basics.copyCriteria")
			: company?.id
			? t("basics.save")
			: t("basics.addCriteria");

	return (
		<div>
			<div className="text-md mb-5">Sources</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.name?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setValue("name", event.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					label={ts("name")}
					name={"name"}
					placeholder={ts("namePlaceholder")}
					value={getValues("name")}
				/>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.url?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setValue("url", event.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					label={ts("url")}
					name={"url"}
					placeholder={ts("urlPlaceholder")}
					value={getValues("url")}
				/>
			</div>

			<Alert severity="warning">{ts("scrapeFollowersWarning")}</Alert>

			{getAllErrors(error, formErrors).length
				? renderErrorMessages(getAllErrors(error, formErrors))
				: null}

			<div className="flex gap-4 w-full justify-end">
				{handleSelect && (
					<div className="flex max-w-[100px] w-full">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							title="cancel"
							onClick={() => {
								handleSelect(null);
								handleDuplicate?.(null);
								reset({
									name: "",
									url: "",
									scrapeFollowers: false,
								});
							}}
						/>
					</div>
				)}

				<div className="flex max-w-[150px] w-full">
					<Button isLoading={isLoading} title={buttonTitle} onClick={handleSubmit(onSubmit)} />
				</div>
			</div>
		</div>
	);
};
