import { CustomColumnTypes } from "@/enum/list.enum";

import AiColumn from "@assets/icons/aiColumn.svg";
import googleNewsIcon from "@/assets/icons/googleNewsIcon.svg";
import departmentIcon from "@/assets/icons/sidebar/site-map.svg";
import toolIcon from "@/assets/icons/sidebar/screw-driver-wrench.svg";
import linkedinIcon from "@/assets/icons/linkedin.svg";
import jobIcon from "@/assets/icons/job.svg";

export const getSvgIcons = (type: CustomColumnTypes) => {
	if (type === CustomColumnTypes.AI_PROMPT) {
		return AiColumn;
	}

	if (type === CustomColumnTypes.GOOGLE_NEWS) {
		return googleNewsIcon;
	}

	if (type === CustomColumnTypes.DEPARTMENT_SIZE) {
		return departmentIcon;
	}

	if (type === CustomColumnTypes.TECHNOLOGY) {
		return toolIcon;
	}

	if (type === CustomColumnTypes.LINKEDIN_POST) {
		return linkedinIcon;
	}

	if (type === CustomColumnTypes.JOB_OFFER) {
		return jobIcon;
	}

	return undefined;
};
