import { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";

import { Breadcrumb } from "@/components/Breadcrump/Breadcrump";

import { Button } from "@/components/Button/Button";

import { LoadingOverlay } from "@/components";

import { ButtonColor } from "@/components/Button/types";

import { useGetTrackerQuery } from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { TrackerSourceType } from "@/enum/tracker.enum";

import { getAvailableSteps, getDoneSteps } from "../../utils/create-tracker-steps";

import { Stepper } from "./components/Stepper";

import { Tracker, TrackerFile } from "../../schema/profileTracker";
import { CreateXlsxForm } from "../../components/CreateXlsxForm";
import { AddedSourceTracker } from "../../components/AddedSourceList";

export const TrackerEdit: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();

	const navigate = useNavigate();
	const ts = (key: string) => t(`profileTracker.${key}`);

	const { data, isFetching } = useGetTrackerQuery(+id);

	const [selected, setSelected] = useState<TrackerFile | null>(null);

	const amountOfSources = useMemo(() => {
		if (!data) {
			return 0;
		}
	}, [data]);

	const submit = async () => {
		navigate(`/app/profile-tracker/settings/${id}`);
	};

	return isFetching || !data ? (
		<LoadingOverlay />
	) : (
		<div className="sm:block flex flex-col items-start justify-between mb-6 pb-8">
			<Breadcrumb
				path={[
					{ appFunction: "profile-tracker", name: ts("title"), link: "/" },
					{ appFunction: "profile-tracker", name: ts("create.title"), link: "/create" },
					{ appFunction: "profile-tracker", name: data?.name || "", link: `/create/${id}/details` },
				]}
			/>
			<div className="text-lg leading-9 mb-5">Create {ts(data.sourceType)} List</div>
			<Stepper
				availableSteps={getAvailableSteps(data)}
				currentStep="source"
				doneSteps={getDoneSteps(data)}
				listId={data.id}
			/>
			<div className="w-full">
				{data.sourceType === TrackerSourceType.XLSX && (
					<div className="flex flex-row gap-12">
						<div className="grow relative">
							{selected ? (
								<CreateXlsxForm
									handleSelect={setSelected}
									tracker={data as Tracker}
									trackerFile={selected as TrackerFile}
								/>
							) : (
								<CreateXlsxForm tracker={data as Tracker} />
							)}
						</div>
						<div className="w-1/2">
							<AddedSourceTracker handleSelect={setSelected} tracker={data as Tracker} />
						</div>
					</div>
				)}
			</div>
			<div className="absolute mt-14 h-[72px] flex flex-row justify-between items-center w-auto border-t border-t-border px-8 py-[14px] rounded-t-16 bottom-0 right-0 z-20 bg-white w-full">
				<div></div>
				<div className="flex flex-row self-left items-center gap-x-4">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						title={t("basics.previous")}
						onClick={() => navigate(`/app/lists/create/details/${id}`)}
					/>
					<Button disabled={amountOfSources === 0} title={t("basics.next")} onClick={submit} />
				</div>
			</div>
		</div>
	);
};
