import { FunctionComponent, ReactElement } from "react";

import { SvgIcon } from "@/components/Icon/SvgIcon";
import { classNames } from "@/utils/classNames";
import { TrackerSourceType } from "@/enum/tracker.enum";

interface TileProps {
	title: string;
	description: string;
	note?: string;
	icon?: React.ElementType<React.SVGProps<SVGSVGElement>>;
	iconElement?: ReactElement;
	isSelected: boolean;
	listType: TrackerSourceType;
	setListType: (listType: TrackerSourceType) => void;
	disabled?: boolean;
}

export const Tile: FunctionComponent<TileProps> = ({
	title,
	description,
	icon,
	isSelected,
	setListType,
	listType,
	disabled,
	note,
	iconElement,
}) => {
	console.log(disabled);

	return (
		<button
			className={classNames(
				"border border-1 border-blue-action rounded-xl  mr-5 mb-4 self-stretch flex",
				isSelected ? "border-blue-action" : "border-inactive-item",
				disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
			)}
			onClick={() => !disabled && setListType(listType)}
		>
			<div className="px-5 py-3  self-stretch">
				<div className="w-full items-center flex py-1 mb-3">
					{icon && <SvgIcon className="w-[40px] h-[40px] mr-2" svgIcon={icon} />}
					{iconElement && iconElement}
					<div className="text-black-100">{title}</div>
				</div>
				<hr className="w-full border-divider mb-3" />
				<div className="text-text text-left text-ssm">{description}</div>
				{note && disabled && <div className="text-text text-left text-xs">{note}</div>}
			</div>
		</button>
	);
};
