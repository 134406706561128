import { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ROUTE_CONFIG } from "@/routes/config";
import { useAppSelector } from "@/redux/hooks";
import { ErrorPage, MainLayout } from "@/components";
import { ErrorPageType, PermissionRoles } from "@/enum";

import { useHasPermissions } from "@/utils/useHasPermissions";

import { authSelector } from "../Public/redux/auth.slice";
import { Account } from "./pages/Account/Account";
import { Admin } from "./routes/Admin";
import { Apollo } from "./pages/Apollo/Apollo";
import { Companies } from "./pages/Companies/Companies";
import { CsvEnrichment } from "./pages/CsvEnrichment/CsvEnrichment";
import { LiEvent } from "./pages/LiEvent/LiEvent";
import { OpenAi } from "./pages/OpenAi/OpenAi";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { List } from "./pages/List/List";
import { CustomEvent } from "./pages/CustomEvent/CustomEvent";
import { LiPeople } from "./pages/LiPeople/LiPeople";
import { Score } from "./pages/Score/Score";
import { Company } from "./pages/Company/Company";
import { LiCompanyActivity } from "./pages/LiCompanyActivity/LiCompanyActivity";
import { LiProfile } from "./pages/LiProfile/LiProfile";
import { Job } from "./pages/Job/Job";
import { Map } from "./pages/Map/Map";
import { Requests } from "./pages/Request/Requests";
import { Cron } from "./pages/Cron/Cron";
import { LiPost } from "./pages/LiPost/LiPost";
import { BlacklistOverview } from "./pages/Blacklist/pages/BlacklistOverview/BlacklistOverview";
import { ListView } from "./pages/ListView/ListView";
import { ProfileTracker } from "./pages/ProfileTracker/ProfileTracker";

const { LOGIN } = ROUTE_CONFIG;

export const Private: FunctionComponent = () => {
	const { user } = useAppSelector(authSelector);
	const { hasPermissions } = useHasPermissions();

	if (!user) {
		return <Navigate to={LOGIN} />;
	}

	return (
		<Routes>
			<Route element={<MainLayout />}>
				<Route element={<Account />} path="/account" />
				<Route element={<Company />} path="/company" />
				<Route element={<BlacklistOverview isGlobal={true} />} path="/blacklist/global" />
				<Route element={<Admin />} path="/users/*" />
				<Route element={<Apollo />} path="/prospect/*" />
				<Route element={<Requests />} path="/costs/*" />
				<Route element={<Job />} path="/jobs/*" />
				<Route element={<Map />} path="/maps/*" />
				<Route element={<Cron />} path="/cron" />
				<Route element={<Companies />} path="/companies/*" />
				<Route element={<CsvEnrichment />} path="/csvEnrichments/*" />
				<Route element={<LiEvent />} path="/linkedin-events/*" />
				<Route element={<LiPost />} path="/linkedin-posts/*" />
				<Route element={<LiPeople />} path="/linkedin-people-search/*" />
				<Route element={<LiCompanyActivity />} path="/linkedin-company-activity/*" />
				<Route element={<LiProfile />} path="/linkedin-profile/*" />
				<Route element={<OpenAi />} path="/openai/*" />
				<Route element={<Dashboard />} path="/dashboard/*" />
				<Route element={<List />} path="/lists/*" />
				<Route element={<ProfileTracker />} path="/profile-tracker/*" />
				{hasPermissions([PermissionRoles.GLOBAL_ADMIN]) && user?.company?.id === 1 && (
					<Route element={<CustomEvent />} path="/custom-event/*" />
				)}
				<Route element={<Score />} path="/score/*" />
				<Route element={<ListView />} path="/list-view/*" />
			</Route>
			<Route element={<ErrorPage type={ErrorPageType.NotFound} />} path="*" />
			<Route element={<ErrorPage type={ErrorPageType.Forbidden} />} path="/error-page" />
		</Routes>
	);
};
