/* eslint-disable @typescript-eslint/no-explicit-any */

import { TFunction } from "i18next";

import { SvgIcon } from "@/components/Icon/SvgIcon";
import { TableColumn } from "@/components/Table/Table";

import { ReactComponent as pencil } from "@assets/icons/create-list/pencil.svg";
import { ReactComponent as trash } from "@assets/icons/create-list/trash.svg";
import { ReactComponent as copy } from "@assets/icons/create-list/copy.svg";

import { TrackerSourceType } from "@/enum/tracker.enum";

export const getCustomTableColumns = ({
	type,
	handleSelect,
	handleDuplicate,
	handleDeleteModal,
}: {
	type: TrackerSourceType;
	handleSelect?: (row: any) => void;
	handleDuplicate?: (row: any) => void;
	handleDeleteModal?: (row: any) => void;
	handleRemove?: (row: any) => Promise<void>;
	handlePreviewModal?: (row: any) => Promise<void>;
	t: TFunction<"translation", undefined, "translation">;
}) => {
	const columns: TableColumn[] = [
		{
			title: "name",
			uid: "name",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => <div className="max-w-[250px] truncate">{row.name}</div>,
		},
		{
			title: "",
			uid: "action",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => (
				<div className="flex items-center grow">
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={copy}
						onClick={() => {
							handleDuplicate?.(row);
						}}
					/>
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={pencil}
						onClick={() => {
							handleSelect?.(row);
						}}
					/>
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={trash}
						onClick={() => {
							handleDeleteModal?.(row);
						}}
					/>
				</div>
			),
		},
	];

	if (type === TrackerSourceType.XLSX) {
		return columns;
	}
};
