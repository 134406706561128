/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, FunctionComponent, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";

import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import { Icon, Modal } from "@/components";
import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";
import { CustomColumnTypes, UpdateType } from "@/enum/list.enum";
import { InputField } from "@/components/InputField/InputField";
import { useUpdateListColumnsMutation } from "@/pages/Private/redux/list/list.api";

import textIcon from "@/assets/icons/textIcon.svg";
import numbersIcon from "@/assets/icons/numbersIcon.svg";
import linkIcon from "@/assets/icons/linkIcon.svg";
import aiIcon from "@/assets/icons/aiIcon.svg";
import checkboxIcon from "@/assets/icons/checkboxIcon.svg";
import diceIcon from "@/assets/icons/diceIcon.svg";
import trashIcon from "@/assets/icons/trashIcon.svg";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { Button } from "@/components/Button/Button";
import { ButtonColor, ButtonSize } from "@/components/Button/types";

import { CustomColumn, CustomColumnSchema } from "../schema/list";
import { AiModal } from "./Modals/AIModal";

export interface CreateCustomColumnModalProps {
	setOpen: (value: boolean) => void;
	open: boolean;
	listId: number;
	customColumnTypes?: CustomColumnTypes[];
}

export const CreateCustomColumnModal: FunctionComponent<CreateCustomColumnModalProps> = ({
	open,
	setOpen,
	listId,
	customColumnTypes,
}) => {
	const { t } = useTranslation();

	const ts = useCallback((key: string) => t(`inbox.${key}`), [t]);
	const [columnType, setColumnType] = useState<CustomColumnTypes>();

	const [update, { isLoading, error }] = useUpdateListColumnsMutation();

	const [randomValues, setRandomValues] = useState<string[] | JSON>([]);
	const [randomValue, setRandomValue] = useState<string>();

	const [showConfirm, setShowConfirm] = useState<boolean>(false);

	const {
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CustomColumn>({
		resolver: zodResolver(CustomColumnSchema),
	});

	const onSubmit = async (values: CustomColumn) => {
		try {
			if (columnType === CustomColumnTypes.RANDOM_VALUE) {
				values.value = randomValues;
			}

			await update({
				updateType: UpdateType.CUSTOM_COLUMN,
				id: listId,
				...values,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<Modal
			handleClose={() => {
				setShowConfirm(true);
			}}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={open}
			overflow={true}
			size="xs"
			submitButtonText={t("basics.confirm")}
			subtitle="Select the column type to proceed to the next step."
			title="Add Column"
		>
			<div className="flex flex-col space-y-4">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setValue("customColumnName", event.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					label="Column Name"
					name={"name"}
					placeholder="Enter column name"
					value={getValues("customColumnName")}
				/>

				<Dropdown
					data={[
						{
							title: ts(CustomColumnTypes.TEXT),
							id: CustomColumnTypes.TEXT,
							icon: <img alt="textIcon" className="mr-1" src={textIcon} />,
						},
						{
							title: ts(CustomColumnTypes.NUMBER),
							id: CustomColumnTypes.NUMBER,
							icon: <img alt="numbersIcon" className="mr-1" src={numbersIcon} />,
						},
						{
							title: ts(CustomColumnTypes.LINK),
							id: CustomColumnTypes.LINK,
							icon: <img alt="linkIcon" className="mr-1" src={linkIcon} />,
						},
						{
							title: ts(CustomColumnTypes.BOOLEAN),
							id: CustomColumnTypes.BOOLEAN,
							icon: <img alt="linkIcon" className="mr-1" src={checkboxIcon} />,
						},
						{
							title: ts(CustomColumnTypes.AI_PROMPT),
							id: CustomColumnTypes.AI_PROMPT,
							icon: <img alt="linkIcon" className="mr-1" src={aiIcon} />,
						},
						{
							title: ts(CustomColumnTypes.RANDOM_VALUE),
							id: CustomColumnTypes.RANDOM_VALUE,
							icon: <img alt="linkIcon" className="mr-1" src={diceIcon} />,
						},
					]?.filter((c) => customColumnTypes?.includes(c.id) || !customColumnTypes)}
					floating={true}
					handleSelect={function (value?: AutoCompleteItem) {
						if (value?.id) {
							setValue("customColumnType", value.id as CustomColumnTypes);
							setColumnType(value.id as CustomColumnTypes);
						}
					}}
					label="Custom Column Type"
				/>

				{columnType === CustomColumnTypes.RANDOM_VALUE && (
					<Modal
						handleClose={() => setOpen(false)}
						handleSave={handleSubmit(onSubmit)}
						isLoading={isLoading}
						isOpened={open}
						size="md"
						submitButtonText={t("basics.confirm")}
						title="Create Random Value Column"
					>
						<div className="text-ssm font-medium mb-4">
							<InputField
								handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
									setValue("customColumnName", event.target.value, {
										shouldValidate: true,
										shouldDirty: true,
									});
								}}
								label="Column Name"
								name={"name"}
								placeholder="Enter column name"
								value={getValues("customColumnName")}
							/>
						</div>

						<div className="flex flex-row items-start space-y-4">
							<InputField
								className="!mb-0 grow mr-4"
								handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
									setRandomValue(event.target.value);
								}}
								name={"randomValue"}
								placeholder="Enter value"
								value={randomValue ?? ""}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										if (randomValue) {
											setRandomValues([randomValue, ...(randomValues as string[])]);
										}

										setRandomValue("");
									}
								}}
							/>
							<div className="w-[140px] !mt-0 ml-4 flex items-start">
								<Button
									className="!mt-0"
									disabled={!randomValue}
									image={<Icon className="mr-2" icon={faPlus} />}
									size={ButtonSize.L}
									title="Add value"
									onClick={() => {
										setRandomValues([...(randomValues as string[]), randomValue ?? ""]);
										setRandomValue("");
									}}
								/>
							</div>
						</div>
						<div>
							<div className="text-ssm font-medium mt-4 mb-2">Values</div>
							{(randomValues as string[]).map((value, index) => (
								<div
									key={index}
									className="flex items-center justify-between h-[54px] px-2.5 py-4.5 rounded-xl border border-gray-200 mb-4"
								>
									<div>{value}</div>
									<button
										className="cursor-pointer"
										onClick={() => {
											const values = [...(randomValues as string[])];

											values.splice(index, 1);
											setRandomValues(values);
										}}
									>
										<img alt="trash" src={trashIcon} />
									</button>
								</div>
							))}
						</div>
					</Modal>
				)}

				{columnType === CustomColumnTypes.AI_PROMPT && (
					<AiModal listId={listId} open={true} setOpen={setOpen} setShowConfirm={setShowConfirm} />
				)}

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</div>
			{showConfirm && (
				<Modal
					handleClose={() => setShowConfirm(false)}
					handleSave={async () => {
						setShowConfirm(false);
						setOpen(false);
					}}
					isOpened={showConfirm}
					size="xs"
					submitButtonColor={ButtonColor.ACTION}
					submitButtonText={t("basics.confirm")}
					title={t("basics.confirm")}
				>
					<>{t("basics.confirmationClose")} </>
				</Modal>
			)}
		</Modal>
	);
};
