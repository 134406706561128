import { z } from "zod";

import { AiApplyType, CustomColumnFrequency, CustomColumnTypes } from "@/enum/list.enum";

export const CreateTrackerColumnSchema = z.object({
	id: z.number().optional(),
	customColumnName: z.string(),
	customColumnType: z.nativeEnum(CustomColumnTypes),
	value: z.string().or(z.number()).or(z.boolean()).or(z.array(z.string())).optional(),
	columnId: z.number().optional(),
	aiApplyType: z.nativeEnum(AiApplyType).optional(),
	trackerOrganizationIds: z.array(z.number()).optional(),
	updateOrOnce: z.boolean().optional(),
	frequency: z.nativeEnum(CustomColumnFrequency).optional(),
	score: z.number().optional().nullable(),
	sumUpScoreOverTime: z.boolean().optional(),
	online: z.boolean().optional(),
	single: z.boolean().optional(),
});

export type CreateTrackerColumn = z.infer<typeof CreateTrackerColumnSchema>;

export const CustomColumnSchema = z.object({
	id: z.number().optional(),
	columnId: z.number().optional(),
	customColumnName: z.string(),
	customColumnType: z.nativeEnum(CustomColumnTypes),
	prompt: z.string().optional(),
	frequency: z.nativeEnum(CustomColumnFrequency),
	updateOrOnce: z.boolean().optional(),
	score: z.number().optional().nullable(),
	sumUpScoreOverTime: z.boolean().optional(),
	value: z
		.string()
		.or(z.number().or(z.boolean().or(z.array(z.string()).or(z.any()))))
		.nullable()
		.optional(),
	sourceConfig: z
		.string()
		.or(z.number().or(z.boolean().or(z.array(z.string()).or(z.any()))))
		.nullable()
		.optional(),
	saveAsTemplate: z.boolean().optional(),
	online: z.boolean().optional(),
	single: z.boolean().optional(),
});

export type CustomColumn = z.infer<typeof CustomColumnSchema>;
