import { faFileXls } from "@fortawesome/pro-regular-svg-icons";

import { FunctionComponent } from "react";

import { ReactComponent as Empty } from "@assets/icons/create-list/empty.svg";
import { ReactComponent as ListIcon } from "@assets/icons/sidebar/screw-driver-wrench.svg";

import { SvgIcon } from "@/components/Icon/SvgIcon";
import { TrackerSourceType } from "@/enum/tracker.enum";
import { Icon } from "@/components";

export interface TrackerSourceTypeElemProps {
	sourceType: TrackerSourceType;
}
export const TrackerSourceTypeElem: FunctionComponent<TrackerSourceTypeElemProps> = ({
	sourceType,
}) => {
	return (
		<>
			{sourceType === TrackerSourceType.EMPTY ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<SvgIcon className="h-[18px] mr-1 rounded" svgIcon={Empty} />
					<span className="text-sm">Empty</span>
				</div>
			) : (
				<></>
			)}
			{sourceType === TrackerSourceType.XLSX ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<Icon className="h-[18px] mr-1 rounded" icon={faFileXls} />
					<span className="text-sm">Excel</span>
				</div>
			) : (
				<></>
			)}
			{sourceType === TrackerSourceType.LIST ? (
				<div className="flex items-center bg-inactive-item text-gray-700 rounded-[10px] p-2">
					<SvgIcon className="h-[18px] mr-1 rounded" svgIcon={ListIcon} />
					<span>List</span>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
