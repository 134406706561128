import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";

import { Modal } from "@/components";
import { Checkbox } from "@/components/Checkbox/Checkbox";
import { SearchField } from "@/components/SearchField/SearchField";
import { Suggestion, Suggestions } from "@/pages/Private/pages/LiPeople/schema/liPeople";
import { useLazyGetCompanySuggestionQuery } from "@/pages/Private/redux/liPeople/liPeople.api";
import { useSearch } from "@/utils";
import { useAddOrganizationToTrackerMutation } from "@/pages/Private/redux/profileTracker/profileTracker.api";

export interface SearchForCompanyModalProps {
	open: boolean;
	setOpen: (value: boolean) => void;
	trackerId: number;
}

export const SearchForCompanyModal: FunctionComponent<SearchForCompanyModalProps> = ({
	open,
	setOpen,
	trackerId,
}) => {
	const { searchValue, query, setSearchValue } = useSearch();

	const [companies, setCompanies] = useState<Suggestions>();

	const [selectedCompanies, setSelectedCompanies] = useState<Suggestion[]>([]);

	const [fetchCompany, { isFetching }] = useLazyGetCompanySuggestionQuery();

	const [addCompaniesToTracker, { isLoading: addCompaniesIsLoading }] =
		useAddOrganizationToTrackerMutation();

	useEffect(() => {
		const fetchCompaniesFromAPI = async (keyword: string) => {
			const people = await fetchCompany({
				keyword,
			}).unwrap();

			setCompanies(people);
		};

		if (query) {
			fetchCompaniesFromAPI(query);
		}
	}, [fetchCompany, query, setCompanies]);

	const savePeople = async () => {
		await addCompaniesToTracker({
			id: trackerId,
			companies: selectedCompanies,
		}).unwrap();

		setOpen(false);
	};

	return (
		<Modal
			handleClose={() => setOpen(false)}
			handleSave={() => savePeople()}
			isLoading={addCompaniesIsLoading}
			isOpened={open}
			submitButtonText="Save"
			title={"Search for a company to add"}
		>
			<SearchField
				className="mb-4"
				handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
					setSearchValue(event.target.value);
				}}
				name={"searchCompany"}
				placeholder="Search for a company"
				value={searchValue}
			/>

			{isFetching && (
				<div className="w-full flex h-[50px] mb-4 mt-4 justify-center items-center">
					<CircularProgress />
				</div>
			)}

			{companies?.suggestions
				?.filter((person) => !selectedCompanies.map((p) => p.urn).includes(person.urn))
				?.map((person, index) => {
					return (
						<div
							key={`person-search-${index}`}
							className="w-full border border-gray-200 rounded-xl mb-4 mt-2 flex flex-row items-center"
						>
							<div className="flex items-center w-full">
								<img className="w-[48px] h-[48px] flex mr-3 rounded-l-xl" src={person.image} />
								<div className="grow flex flex-col">
									<span className="text-sm font-semibold">{person.title}</span>
									<span className="text-xs">{person.subtitle}</span>
								</div>
								<Checkbox
									className="!w-[20px] ml-4 mr-4"
									isChecked={selectedCompanies.includes(person)}
									onChange={() => {
										if (selectedCompanies.includes(person)) {
											setSelectedCompanies(selectedCompanies.filter((p) => p !== person));
										} else {
											setSelectedCompanies([...selectedCompanies, person]);
										}
									}}
								/>
							</div>
						</div>
					);
				})}
			{selectedCompanies?.map((person, index) => {
				return (
					<div
						key={`person-search-${index}`}
						className="w-full border border-gray-200 rounded-xl mb-4 mt-2 flex flex-row items-center"
					>
						<div className="flex items-center w-full">
							<img className="w-[48px] h-[48px] flex mr-3 rounded-l-xl" src={person.image} />
							<div className="grow flex flex-col">
								<span className="text-sm font-semibold">{person.title}</span>
								<span className="text-xs">{person.subtitle}</span>
							</div>
							<Checkbox
								className="!w-[20px] ml-4 mr-4"
								isChecked={selectedCompanies.includes(person)}
								onChange={() => {
									if (selectedCompanies.includes(person)) {
										setSelectedCompanies(selectedCompanies.filter((p) => p !== person));
									} else {
										setSelectedCompanies([...selectedCompanies, person]);
									}
								}}
							/>
						</div>
					</div>
				);
			})}
		</Modal>
	);
};
