import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import { Button } from "@/components/Button/Button";
import { ButtonColor } from "@/components/Button/types";

import { InputField } from "@/components/InputField/InputField";

import { LiEventType } from "@/enum/lievent.enum";

import {
	useCreateLiEventMutation,
	useUpdateLiEventMutation,
} from "@/pages/Private/redux/liEvent/liEvent.api";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { Radio } from "@/components/Radio/Radio";

import { List } from "../../List/schema/list";

import {
	LinkedinParams,
	LinkedinTagAutocomplete,
} from "../../LiPeople/components/LinkedinTagAutocomplete";
import { CreateLiEvent, CreateLiEventSchema, LiEvent } from "../schema/liEvent";
import { Suggestion } from "../../LiPeople/schema/liPeople";

interface CreateLiEventFormProps {
	liEvent?: LiEvent | null;
	list: List;
	handleSelect?: (job: LiEvent | null) => void;
	handleDuplicate?: (job: LiEvent | null) => void;
}

export const CreateLiEventForm: FunctionComponent<CreateLiEventFormProps> = ({
	liEvent,
	list,
	handleSelect,
	handleDuplicate,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liEvent.${key}`);
	const [createLiEvent, { isLoading, error }] = useCreateLiEventMutation();
	const [updateLiEvent, { isLoading: updateIsLoading }] = useUpdateLiEventMutation();
	const [tempUrls, setTempUrls] = useState<string>();
	const [tempLocations, setTempLocations] = useState<LinkedinParams[]>();
	const [tempIndustry, setTempIndustry] = useState<string[]>();
	const [locations, setLocations] = useState<LinkedinParams[]>();
	const [industries, setIndustries] = useState<LinkedinParams[]>();

	const initialFormState = {
		name: "",
		type: LiEventType.SEARCH,
		eventUrls: [],
		profileLanguageEn: false,
		profileLanguageDe: false,
		profileLanguageFr: false,
		profileLanguageEs: false,
		profileLanguagePt: false,
		minDaysBeforeScrape: 7,
		searchTermInTitle: false,
		minAttendeesBeforeScrape: undefined,
		location: [],
		industry: [],
	};

	const [currentFormState, setCurrentFormState] = useState<CreateLiEvent>(initialFormState);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (
		value: CreateLiEvent[keyof CreateLiEvent],
		name: keyof CreateLiEvent
	) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const {
		handleSubmit,
		setValue,
		getValues,
		reset,
		formState: { errors },
	} = useForm<CreateLiEvent>({
		defaultValues: initialFormState,
		resolver: zodResolver(CreateLiEventSchema),
	});

	useEffect(() => {
		if (liEvent) {
			const formState = {
				name: liEvent.name,
				searchTerm: liEvent.searchTerm ?? undefined,
				type: liEvent.type,
				profileLanguageDe: liEvent.profileLanguageDe,
				profileLanguageEn: liEvent.profileLanguageEn,
				profileLanguageEs: liEvent.profileLanguageEs,
				profileLanguageFr: liEvent.profileLanguageFr,
				profileLanguagePt: liEvent.profileLanguagePt,
				minDaysBeforeScrape: liEvent.minDaysBeforeScrape,
				minAttendeesBeforeScrape: liEvent.minAttendeesBeforeScrape ?? undefined,
				searchTermInTitle: liEvent.searchTermInTitle,
				location: (liEvent.location as unknown as string).split(","),
				industry: (liEvent.industry as unknown as string).split(","),
			};

			setLocations(
				liEvent.locationList?.map((l) => {
					return { name: l.title, id: l.urn };
				}) as unknown as LinkedinParams[]
			);
			setIndustries(
				liEvent.industryList?.map((l) => {
					return { name: l.title, id: l.urn };
				}) as unknown as LinkedinParams[]
			);

			if (handleDuplicate) {
				reset({
					...formState,
				});
				setCurrentFormState({
					...formState,
				});
			} else {
				reset({
					id: liEvent.id,
					...formState,
				});
				setCurrentFormState({
					...formState,
					id: liEvent?.id,
				});
			}

			setValue("type", liEvent.type);

			if (liEvent.type === LiEventType.SPECIFIC) {
				setValue(
					"eventUrls",
					liEvent?.liEventEntries?.map((liEventEntry) => liEventEntry.url)
				);
			}

			setTempUrls(liEvent?.liEventEntries?.map((liEventEntry) => liEventEntry.url)?.join(","));
			liEvent?.location &&
				setTempLocations(
					(liEvent?.location as unknown as string).split(",").map((l) => {
						return { name: l, id: l };
					})
				);
			liEvent?.industry && setTempIndustry((liEvent?.industry as unknown as string).split(","));

			setTempUrls(liEvent?.liEventEntries?.map((liEventEntry) => liEventEntry.url)?.join(","));
			liEvent?.location &&
				setTempLocations(
					(liEvent?.location as unknown as string).split(",").map((l) => {
						return { name: l, id: l };
					})
				);
			liEvent?.industry && setTempIndustry((liEvent?.industry as unknown as string).split(","));
		} else {
			reset();
			setTempUrls(undefined);
		}
	}, [list.scrapeLevel, liEvent, reset, handleDuplicate, setValue]);

	const handleUrlsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setTempUrls(event.target.value);
	};

	const handleUrlsOnBlur = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setTempUrls(event.target.value);
		setValue("eventUrls", event.target.value?.split(","));
	};

	useEffect(() => {
		setValue("location", tempLocations?.map((l) => l.name) || []);
	}, [tempLocations, setValue]);

	useEffect(() => {
		setValue(
			"locationList",
			(locations?.map((val) => {
				return { title: val.name, urn: val.id };
			}) as Suggestion[]) || []
		);
	}, [locations, setValue]);

	useEffect(() => {
		setValue(
			"industryList",
			(industries?.map((val) => {
				return { title: val.name, urn: val.id };
			}) as Suggestion[]) || []
		);
	}, [industries, setValue]);

	useEffect(() => {
		setValue("industry", tempIndustry || []);
	}, [tempIndustry, setValue]);

	const onSubmit = async (values: CreateLiEvent) => {
		if (values.type === LiEventType.SEARCH) {
			values.eventUrls = [];
		} else {
			values.searchTerm = "";
		}

		try {
			if (liEvent?.id && !handleDuplicate) {
				await updateLiEvent({ id: liEvent.id, ...values, listId: list.id }).unwrap();
			} else {
				await createLiEvent({ ...values, listId: list.id }).unwrap();
			}

			reset({});

			handleSelect?.(null);
			handleDuplicate?.(null);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];
	const buttonTitle =
		handleDuplicate && liEvent?.id
			? t("basics.copyCriteria")
			: liEvent?.id
			? t("basics.save")
			: t("basics.addCriteria");

	return (
		<div className="">
			<div className="text-md mb-5">Sources</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.name?.message}
					handleChange={(e: ChangeEvent<HTMLInputElement>) => {
						handleSaveFormState(e.target.value, "name");
					}}
					label={ts("name")}
					name="name"
					placeholder={ts("name")}
					value={currentFormState?.name || ""}
				/>
			</div>
			<div className="flex flex-row gap-4 mb-4 py-4">
				<Radio
					isChecked={currentFormState.type === LiEventType.SEARCH}
					onChange={() => handleSaveFormState(LiEventType.SEARCH, "type")}
				>
					{ts("defaultSearch")}
				</Radio>

				<Radio
					isChecked={currentFormState.type === LiEventType.SPECIFIC}
					onChange={() => handleSaveFormState(LiEventType.SPECIFIC, "type")}
				>
					{ts("specific")}
				</Radio>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				{currentFormState.type === LiEventType.SEARCH ? (
					<InputField
						error={!!errors.searchTerm?.message}
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							handleSaveFormState(e.target.value, "searchTerm");
						}}
						label={ts("searchTerm")}
						name="searchTerm"
						placeholder={ts("searchTerm")}
						value={currentFormState?.searchTerm || ""}
					/>
				) : (
					<InputField
						multiline
						handleBlur={handleUrlsOnBlur}
						handleChange={handleUrlsChange}
						label={ts("url")}
						name="eventUrls"
						placeholder={ts("urls")}
						value={tempUrls || ""}
					/>
				)}
			</div>
			<div className="text-ssm mb-5">{ts("scrapeCriteria")}</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<div className="grow w-1/2">
					<InputField
						error={!!errors.minDaysBeforeScrape?.message}
						handleChange={(e) => {
							if (e.target.value === "") {
								handleSaveFormState(7, "minDaysBeforeScrape");
							} else {
								handleSaveFormState(+e.target.value, "minDaysBeforeScrape");
							}
						}}
						label={ts("minDaysBeforeScrape")}
						name="minDaysBeforeScrape"
						placeholder={"7"}
						type="number"
						value={currentFormState?.minDaysBeforeScrape || ""}
					/>
				</div>
				<div className="grow w-1/2">
					<InputField
						error={!!errors.minAttendeesBeforeScrape?.message}
						handleChange={(e) => {
							if (e.target.value === "") {
								handleSaveFormState(undefined, "minAttendeesBeforeScrape");
							} else {
								handleSaveFormState(+e.target.value, "minAttendeesBeforeScrape");
							}
						}}
						label={ts("minAttendeesBeforeScrape")}
						name="minAttendeesBeforeScrape"
						placeholder={"100"}
						type="number"
						value={currentFormState?.minAttendeesBeforeScrape || ""}
					/>{" "}
				</div>
			</div>
			{currentFormState.type === LiEventType.SEARCH && (
				<div className="flex flex-row gap-4 w-full mb-4">
					<Radio
						isChecked={currentFormState?.searchTermInTitle}
						onChange={() => handleSaveFormState(true, "searchTermInTitle")}
					>
						search term in the title only
					</Radio>

					<Radio
						isChecked={!currentFormState?.searchTermInTitle}
						onChange={() => handleSaveFormState(false, "searchTermInTitle")}
					>
						search term in title / description
					</Radio>
				</div>
			)}

			<div className="text-ssm mb-5">{ts("filterCriteria")}</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<LinkedinTagAutocomplete
					handleValueChange={(value: LinkedinParams[]) => {
						setLocations(value);
					}}
					type={"location"}
					value={locations}
				/>
			</div>

			<div className="flex flex-row gap-4 w-full mb-4">
				<LinkedinTagAutocomplete
					handleValueChange={(value: LinkedinParams[]) => {
						setIndustries(value);
					}}
					type={"industry"}
					value={industries}
				/>
			</div>

			<div className="text-ssm mb-5">{ts("profileLanguages")}</div>
			<div className="flex flex-row gap-4 w-full mb-8">
				<Checkbox
					isChecked={currentFormState?.profileLanguageEn}
					onChange={() => {
						handleSaveFormState(!currentFormState?.profileLanguageEn, "profileLanguageEn");
					}}
				>
					{ts("english")}
				</Checkbox>

				<Checkbox
					isChecked={getValues("profileLanguageDe")}
					onChange={() => {
						setValue("profileLanguageDe", !getValues("profileLanguageDe"), {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
				>
					{ts("german")}
				</Checkbox>

				<Checkbox
					isChecked={currentFormState?.profileLanguageFr}
					onChange={() => {
						handleSaveFormState(!currentFormState?.profileLanguageFr, "profileLanguageFr");
					}}
				>
					{ts("french")}
				</Checkbox>

				<Checkbox
					isChecked={currentFormState?.profileLanguageEs}
					onChange={() => {
						handleSaveFormState(!currentFormState?.profileLanguageEs, "profileLanguageEs");
					}}
				>
					{ts("spanish")}
				</Checkbox>

				<Checkbox
					isChecked={currentFormState?.profileLanguagePt}
					onChange={() => {
						handleSaveFormState(!currentFormState?.profileLanguagePt, "profileLanguagePt");
					}}
				>
					{ts("portuguese")}
				</Checkbox>
			</div>

			{getAllErrors(error, formErrors).length
				? renderErrorMessages(getAllErrors(error, formErrors))
				: null}
			<div className="flex gap-4 w-full justify-end">
				{handleSelect && (
					<div className="flex max-w-[100px] w-full">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							title="cancel"
							onClick={() => {
								handleSelect(null);
								handleDuplicate?.(null);
								reset(initialFormState);
								setCurrentFormState(initialFormState);
							}}
						/>
					</div>
				)}

				<div className="flex max-w-[150px] w-full">
					<Button
						disabled={false}
						isLoading={isLoading || updateIsLoading}
						title={buttonTitle}
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</div>
		</div>
	);
};
