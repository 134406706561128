import { createSlice } from "@reduxjs/toolkit";

import { ProfileTrackerState } from "@/types/profileTracker";

import { RootState } from "@/redux";

import { Order } from "@/types/types";

import { profileTrackerApi } from "./profileTracker.api";

const initialState: ProfileTrackerState = {
	profileTracker: [],
	profileTrackerMeta: {
		page: 1,
		limit: 15,
		itemCount: 15,
		pageCount: 1,
		hasPreviousPage: false,
		hasNextPage: false,
	},
	sortingProfileTracker: {
		sortBy: "updatedAt",
		sortOrder: Order.DESC,
	},
	records: [],
	recordsMeta: {
		page: 1,
		limit: 15,
		itemCount: 15,
		pageCount: 1,
		hasPreviousPage: false,
		hasNextPage: false,
	},
	sortingRecords: {
		sortBy: "updatedAt",
		sortOrder: Order.DESC,
	},
	resetColumns: false,
};

export const profileTrackerSlice = createSlice({
	name: "profileTracker",
	initialState,
	reducers: {
		setProfileTrackerSorting: (state, { payload }) => {
			state.sortingProfileTracker = payload;
		},
		setRecordsSorting: (state, { payload }) => {
			state.sortingRecords = payload;
		},
		clearProfileTracker: (state) => {
			state.profileTracker = [];
		},
		clearRecords: (state) => {
			state.records = [];
		},
		finalizeResetColumns: (state) => {
			state.resetColumns = false;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			profileTrackerApi.endpoints.getProfileTrackers.matchFulfilled,
			(state, { payload }) => {
				state.profileTracker = payload.data;
				state.profileTrackerMeta = payload.meta;
			}
		);
		builder.addMatcher(
			profileTrackerApi.endpoints.getTrackerOrganizations.matchFulfilled,
			(state, { payload }) => {
				state.records = payload.data;
				state.profileTrackerMeta = payload.meta;
			}
		);
		builder.addMatcher(
			profileTrackerApi.endpoints.updateTracker.matchFulfilled,
			(state, action) => {
				if (!action.payload.id) {
					return;
				}

				const index = state.profileTracker.findIndex((tracker) => tracker.id === action.payload.id);

				if (index === -1) {
					return;
				}

				if (action.payload.id) {
					state.profileTracker[index] = {
						...state.profileTracker[index],
						...action.payload,
					};
				}
			}
		);
		builder.addMatcher(
			profileTrackerApi.endpoints.updateColumnValue.matchFulfilled,
			(state, action) => {
				const index = state.records.findIndex(
					(trackerOrganization) => trackerOrganization.id === action.payload.id
				);

				const trackerOrganization = state.records[index];

				if (index === -1) {
					return;
				}

				const columnIndex = trackerOrganization.columns.findIndex(
					(column) => column.columnConfigId === action.payload.columnValue.columnConfigId
				);

				if (columnIndex > -1) {
					const column = trackerOrganization.columns[columnIndex];

					column.value = action.payload.columnValue.value;

					state.records[index].columns[columnIndex] = column;
				} else {
					state.records[index].columns.push(action.payload.columnValue);
				}
			}
		);
		builder.addMatcher(profileTrackerApi.endpoints.addColumnToTracker.matchFulfilled, (state) => {
			state.resetColumns = true;
		});
		builder.addMatcher(profileTrackerApi.endpoints.deleteColumn.matchFulfilled, (state) => {
			state.resetColumns = true;
		});
	},
});

export const {
	setProfileTrackerSorting,
	clearProfileTracker,
	clearRecords,
	setRecordsSorting,
	finalizeResetColumns,
} = profileTrackerSlice.actions;
export const profileTrackerReducer = profileTrackerSlice.reducer;
export const profileTrackerSelector = (state: RootState) => state[profileTrackerSlice.name];
