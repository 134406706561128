import { z } from "zod";

import { ColumnConfigSchema } from "./columnConfig";

export const ColumnValueSchema = z.object({
	id: z.number(),
	columnConfigId: z.number(),
	columnConfig: ColumnConfigSchema,
	listPersonId: z.number(),
	value: z.any(),
	createdAt: z.string(),
});

export type ColumnValue = z.infer<typeof ColumnValueSchema>;
